import { loanConstants, skipStep } from "./../constants/loanConstants";
import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKey, Url } from "../enum";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { ActiveListContext, ModalContext } from "../context";
import { CurrentStep, ILoanParams } from "../interfaces";
import { chatService } from "../services/chat.service";
import { IChat } from "../interfaces/chat.interface";
import { IChatParams } from "../interfaces/params/chatParams.interface";
import { useModal } from "./useModal";

export const useChats = (params?: IChatParams) => {
	const { pathname } = useLocation();
	const { FNMA, FHLMC, FHA } = Url;
	const isPathnameIsChats = [FNMA, FHLMC, FHA].some((path) => pathname.indexOf(path) !== -1);
	const { setStep, openModal } = useContext(ModalContext);
	const { limit } = loanConstants;
	const { setActiveList } = useContext(ActiveListContext);
	const { agencyId } = useParams();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const {
		data: chatsData,
		hasNextPage: hasChatsNextPage,
		fetchNextPage: fetchChatsNextPage,
		isFetchingNextPage: isChatsFetchingNextPage,
		isLoading: isChatsLoading,
		isPending: isChatsPending,
		isFetching: isChatsFetching,
		refetch: refetchChats,
	} = useInfiniteQuery<IChat[], Error>({
		queryKey: [QueryKey.CHATS, params],
		queryFn: ({ pageParam }) => chatService.getPage({ ...loanConstants, agency_id: agencyId, ...params, skip: (pageParam as number) * skipStep }),
		getNextPageParam: (lastPage: IChat[], allPages: IChat[][], lastPageParam: unknown) => {
			if (lastPage.length === limit) {
				return (lastPageParam as number) + 1;
			}
			return null;
		},
		initialPageParam: 0,
		enabled: isPathnameIsChats,
		staleTime: Infinity,
	});

	const chats = useMemo(() => chatsData?.pages.flatMap((page) => page) || [], [chatsData]);

	const createNewChatMutation = useMutation({
		mutationFn: ({ agencyId, guidelineId }: { agencyId: string; guidelineId: string }) =>
			chatService.createNewChat({ agency_id: agencyId, guideline_id: guidelineId, summary: "Summary will be generated shortly...", title: "New Chat" }),
		onSuccess: async (data, variables) => {
			setStep(CurrentStep.STEP3);
			openModal("create_chat");
			await Promise.all([queryClient.invalidateQueries({ queryKey: [QueryKey.AGENCY] }), queryClient.invalidateQueries({ queryKey: [QueryKey.CHATS] })]);
			setActiveList(data.id);
			navigate(`/chats/${variables.agencyId}/${data.id}`);
		},
		onError: (e) => {
			setStep(CurrentStep.STEP4);
			openModal("create_chat");
			console.error(e);
		},
	});
	return {
		sortedChats: chats,
		isPathnameIsChats,
		chatsData,
		hasChatsNextPage,
		fetchChatsNextPage,
		isChatsFetchingNextPage,
		isChatsLoading,
		isChatsPending,
		isChatsFetching,
		refetchChats,
		createNewChatMutation,
	};
};
