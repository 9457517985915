import { createContext, Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, useState } from "react";
import { IChatTimeline, ICitation } from "../interfaces";

interface ITriggeredCitation {
	citation: ICitation;
	index: number;
}

interface IChatContext {
	triggerNewMessage: boolean;
	setTriggerNewMessage: Dispatch<SetStateAction<boolean>>;
	sortedMessages: IChatTimeline[];
	setSortedMessages: Dispatch<SetStateAction<IChatTimeline[]>>;
	msgWrapper: MutableRefObject<HTMLDivElement>;
	setMsgWrapper: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
	msgWrapperMobile: MutableRefObject<HTMLDivElement>;
	setMsgWrapperMobile: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
	messageId: string;
	setMessageId: Dispatch<SetStateAction<string>>;
	speechTranslation: string;
	setSpeechTranslation: Dispatch<SetStateAction<string>>;
	isTypingEffectInProgress: boolean;
	setIsTypingEffectInProgress: Dispatch<SetStateAction<boolean>>;
	lastTriggeredMessageId: string;
	setLastTriggeredMessageId: Dispatch<SetStateAction<string>>;
	lastTriggeredCitation: ITriggeredCitation | null;
	setLastTriggeredCitation: Dispatch<SetStateAction<ITriggeredCitation | null>>;
	selectedGuidelineTitle: string;
	setSelectedGuidelineTitle: Dispatch<SetStateAction<string>>;
}

export const ChatContext = createContext<IChatContext>(null);

export const ChatProvider = ({ children }: PropsWithChildren) => {
	const [triggerNewMessage, setTriggerNewMessage] = useState<boolean>(false);
	const [sortedMessages, setSortedMessages] = useState<IChatTimeline[]>([]);
	const [msgWrapper, setMsgWrapper] = useState<MutableRefObject<HTMLDivElement>>(null);
	const [msgWrapperMobile, setMsgWrapperMobile] = useState<MutableRefObject<HTMLDivElement>>(null);
	const [messageId, setMessageId] = useState<string>(null);
	const [speechTranslation, setSpeechTranslation] = useState<string>("");
	const [isTypingEffectInProgress, setIsTypingEffectInProgress] = useState<boolean>(false);
	const [lastTriggeredMessageId, setLastTriggeredMessageId] = useState<string | null>(null);
	const [lastTriggeredCitation, setLastTriggeredCitation] = useState<ITriggeredCitation | null>(null);
	const [selectedGuidelineTitle, setSelectedGuidelineTitle] = useState<string>("");

	const value = {
		triggerNewMessage,
		setTriggerNewMessage,
		sortedMessages,
		setSortedMessages,
		msgWrapper,
		setMsgWrapper,
		msgWrapperMobile,
		setMsgWrapperMobile,
		messageId,
		setMessageId,
		speechTranslation,
		setSpeechTranslation,
		isTypingEffectInProgress,
		setIsTypingEffectInProgress,
		lastTriggeredMessageId,
		setLastTriggeredMessageId,
		lastTriggeredCitation,
		setLastTriggeredCitation,
		selectedGuidelineTitle,
		setSelectedGuidelineTitle,
	};

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
