import { FC, useContext } from "react";
import { ButtonWrapper, NewLoanBtnWrapper, NewLoanMobileText, PlusIcon } from "../../../layouts/innerSideBar/innerSideBar.style";
import { ModalContext } from "../../../context";
import { ModalWrapper } from "../../allModals/upload/ModalWrapper";

interface INewChatWrapperProps {
	src: string;
}

const NewChatWrapper: FC<INewChatWrapperProps> = ({ src }) => {
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext);

	return (
		<NewLoanBtnWrapper>
			<ButtonWrapper startIcon={<PlusIcon src={src} />} onClick={() => openModal("chat")}>
				<NewLoanMobileText>New Chat</NewLoanMobileText>
			</ButtonWrapper>
			<ModalWrapper open={isModalOpen && modalKey === "chat"} width="50%" />
		</NewLoanBtnWrapper>
	);
};

export { NewChatWrapper };
