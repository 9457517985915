import React, { useState, useEffect } from "react";
import "./VoiceWaves.css";

const VoiceWave = () => {
	return (
		<div className="voice-wave-container">
			<div className="waves">
				{[...Array(5)].map((_, index) => (
					<span key={index} className={`wave active`} style={{ animationDelay: `${index * 0.2}s` }}></span>
				))}
			</div>
		</div>
	);
};

export default VoiceWave;
