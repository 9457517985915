import { AxiosError } from "axios";
import { apiService } from "./api.service";

class AuthInterceptor {
	private getToken: (() => Promise<string>) | null = null;
	token: string = null;
	private isRefreshing = false;
	private refreshQueue: (() => void)[] = [];
	public logoutUser: () => void;

	public setAuthGetter(getToken: () => Promise<string>): void {
		this.getToken = getToken;
	}

	public setLogoutUserMethod(logoutUser: () => void): void {
		this.logoutUser = logoutUser;
	}

	public resetToken(): void {
		this.token = null;
		this.refreshQueue = [];
	}

	private isTokenExpired(token: string): boolean {
		try {
			const payload = JSON.parse(atob(token.split(".")[1]));
			return payload.exp * 1000 < Date.now() - 5 * 60 * 1000; // 5 minutes
		} catch (e) {
			console.error("Error parsing token:", e);
			return true;
		}
	}

	async refreshToken(): Promise<void> {
		if (this.isRefreshing) {
			return new Promise((resolve) => this.refreshQueue.push(resolve));
		}

		this.isRefreshing = true;

		try {
			this.token = await this.getToken();
			this.refreshQueue.forEach((resolve) => resolve());
			this.refreshQueue = [];
		} catch (e) {
			this.logoutUser();
		} finally {
			this.isRefreshing = false;
		}
	}

	public async intercept(config: any): Promise<any> {
		if (!this.getToken) {
			return config;
		}

		if (!this.token || this.isTokenExpired(this.token)) {
			try {
				await this.refreshToken();
			} catch (e) {
				this.logoutUser();
				throw e;
			}
		}

		config.headers["Authorization"] = `Bearer ${this.token}`;
		return config;
	}
}

export const authInterceptor = new AuthInterceptor();

apiService.interceptors.request.use(authInterceptor.intercept.bind(authInterceptor));

apiService.interceptors.response.use(
	(res) => res,
	async (error: AxiosError) => {
		const originalRequest = error.config;

		//@ts-ignore
		if ([401, 403].includes(error?.response?.status) && !originalRequest._retry) {
			//@ts-ignore
			originalRequest._retry = true;

			try {
				await authInterceptor.refreshToken();
				originalRequest.headers["Authorization"] = `Bearer ${authInterceptor.token}`;
				return apiService(originalRequest);
			} catch (e) {
				return Promise.reject(e);
			}
		}

		return Promise.reject(error);
	}
);
