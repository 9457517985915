import { MenuNavElementWrapper, SideNavComponent } from "./sidebar.style";
import { useLocation } from "react-router-dom";
import { INavItem, navItems } from "./navItems";
import { NavItem } from "./NavItem";
import { Box, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { breakpointConstants } from "../../constants";
import { drawerActions, getCollapseReducer, getDrawerReducer, getThemeReducer, useAppDispatch, useAppSelector } from "../../store";

const SideBar = () => {
	const { pathname } = useLocation();
	const { isLeftNavBarDrawerOpen } = useAppSelector(getDrawerReducer);
	const { collapsed } = useAppSelector(getCollapseReducer);
	const { themeMode } = useAppSelector(getThemeReducer);
	const dispatch = useAppDispatch();
	const { toggleIsLeftDrawerOpen } = drawerActions;

	const {
		autoCollapse: { leftNavbar },
	} = breakpointConstants;
	const [shouldDisplayDrawer, setShouldDisplayDrawer] = useState<boolean>(window.innerWidth < leftNavbar);

	useEffect(() => {
		const toggleLeftNavBarToDrawer = () => {
			setShouldDisplayDrawer(window.innerWidth < leftNavbar);
		};
		window.addEventListener("resize", toggleLeftNavBarToDrawer);
		return () => window.removeEventListener("resize", toggleLeftNavBarToDrawer);
	}, [leftNavbar]);

	const mappedContent = (item: INavItem, key: string) => (
		<NavItem
			key={key}
			collapsed={collapsed && !isLeftNavBarDrawerOpen}
			label={item?.label || item?.labelObj[themeMode]}
			src={pathname.indexOf(item?.link) !== -1 || item?.subLinks?.some((subLink) => pathname.indexOf(subLink) !== -1) ? item?.active[themeMode] : item?.src[themeMode]}
			link={item?.link}
			needDivider={item?.needDivider}
		/>
	);

	const sideNavContext = () => (
		<>
			<Box>{navItems && navItems.slice(0, -1).map((item) => mappedContent(item, item?.label))}</Box>
			<MenuNavElementWrapper>{navItems && navItems.slice(-1).map((item) => mappedContent(item, item?.label))}</MenuNavElementWrapper>
		</>
	);

	return (
		<>
			<SideNavComponent drawer={false} collapsed={collapsed}>
				{sideNavContext()}
			</SideNavComponent>

			<Drawer
				variant="temporary"
				open={isLeftNavBarDrawerOpen}
				onClose={() => dispatch(toggleIsLeftDrawerOpen())}
				anchor="left"
				PaperProps={{
					sx: {
						width: "161px",
					},
				}}
			>
				<SideNavComponent drawer={true} collapsed={false}>
					{sideNavContext()}
				</SideNavComponent>
			</Drawer>
		</>
	);
};
export default SideBar;
