import { MsgBoxWrapper } from "../../../pages/styles/home.style";
import { Fragment, memo, useContext, useEffect, useRef, useState } from "react";
import { ChatMessage } from "./Message/Message";
import { useEdit } from "../../../hook/useEdit";
import { ActionWrapper, InfinitiveScrollWrapper } from "./messages.style";
import { ActiveListContext, ChatContext, ModalContext } from "../../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../Loader/Loader";
import { Box } from "@mui/material";
import { ToolTip } from "../../../enum";
import { getThemeReducer, useAppSelector } from "../../../store";
import { useChatMessages } from "../../../hook/useChatMessages";
import { SourceModal } from "../../allModals/source/SourceModal";
import { useChats } from "../../../hook/useChats";
import { useParams } from "react-router-dom";

const ChatMessages = memo(() => {
	const { isMessagesPending, sortedMessages, shouldUseTypingEffect, fetchNextMessagesPage, hasMessagesNextPage, isMessagesFetchingNextPage } = useChatMessages();
	const { activeEntity } = useContext(ActiveListContext);
	const { insertLink } = useEdit();
	const msgBoxWrapper = useRef<HTMLDivElement>();
	const { setMsgWrapper } = useContext(ChatContext);
	const [shouldAutoFetch, setShouldAutoFetch] = useState<boolean>(false);
	const { themeMode } = useAppSelector(getThemeReducer);
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext);
	const { lastTriggeredCitation, lastTriggeredMessageId } = useContext(ChatContext);
	const { isChatsPending, sortedChats } = useChats();
	const { chatId } = useParams();

	useEffect(() => {
		if (msgBoxWrapper) {
			if (msgBoxWrapper?.current?.scrollHeight === msgBoxWrapper?.current?.clientHeight && hasMessagesNextPage) {
				setShouldAutoFetch(true);
				fetchNextMessagesPage();
			} else {
				setShouldAutoFetch(false);
			}
		}
	}, [msgBoxWrapper, sortedMessages, hasMessagesNextPage, fetchNextMessagesPage]);

	useEffect(() => {
		setMsgWrapper(msgBoxWrapper);
	}, [setMsgWrapper]);

	return (
		<>
			{isMessagesPending ? (
				(isChatsPending || sortedChats.length !== 0) && chatId ? (
					<Loader />
				) : (
					""
				)
			) : (
				<MsgBoxWrapper sx={{ themeMode }} id="scrollableTarget" ref={msgBoxWrapper}>
					{activeEntity?.tooltipTitle === ToolTip.REFRESH_CHAT && <Loader />}
					<InfinitiveScrollWrapper>
						<InfiniteScroll
							dataLength={sortedMessages.length}
							next={fetchNextMessagesPage}
							inverse={true}
							loader={<Loader />}
							style={{
								display: "flex",
								flexDirection: "column-reverse",
							}}
							hasMore={hasMessagesNextPage}
							scrollableTarget="scrollableTarget"
						>
							{sortedMessages &&
								sortedMessages.map((item, index) => (
									<Fragment key={item.id}>
										{item.type === "message" ? (
											<ChatMessage key={item.id} message={item} shouldUseTypingEffect={shouldUseTypingEffect(index)} />
										) : (
											<Box key={item.id} sx={{ width: "90%", margin: "0 auto" }}>
												<ActionWrapper className="actionMessageWrapper" sx={{ themeMode }}>
													{insertLink(item.description, item.details, String(item.id))}
												</ActionWrapper>
											</Box>
										)}
									</Fragment>
								))}
						</InfiniteScroll>
					</InfinitiveScrollWrapper>
					{shouldAutoFetch && isMessagesFetchingNextPage && <Loader />}
				</MsgBoxWrapper>
			)}
			{isModalOpen && lastTriggeredCitation && (
				<SourceModal
					open={isModalOpen && modalKey === "source"}
					triggeredCitation={lastTriggeredCitation.citation}
					sourceIndex={lastTriggeredCitation.index}
					messageId={lastTriggeredMessageId}
				/>
			)}
		</>
	);
});

export { ChatMessages };
