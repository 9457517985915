import { useLocation, useNavigate, useParams } from "react-router-dom";
import { headerButtons as originalHeaderButtons } from "../../../constants/headerButtons";
import { ButtonComponent, ButtonInnerComponent, HeaderButton, PlusBtn, PlusBtnIcon } from "../../../layouts/header/header.style";
import { ButtonWrapperMobile } from "../../../pages/styles/home.style";
import { memo, useContext, useEffect, useState } from "react";
import { getThemeReducer, useAppSelector } from "../../../store";
import { agencyService } from "../../../services/agency.service";
import { useAgency } from "../../../hook/useAgency";
import { IAgency } from "../../../interfaces/agency.interface";
import { IHeaderButton } from "../../../interfaces/headerButtons.interface";
import { ActiveChatList } from "../ActiveChatsList/ActiveList";
import { ActiveListContext, ModalContext } from "../../../context";

interface IHeaderAgencyButton extends IAgency {
	url: string;
}

const MobileHeaderNav = memo(() => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { themeMode } = useAppSelector(getThemeReducer);
	const [headerButtons, setHeaderButtons] = useState<(IHeaderAgencyButton | IHeaderButton)[]>([]);
	const { topAgenciesData, isTopAgenciesLoading } = useAgency();
	const { openModal } = useContext(ModalContext);
	const { agencyId } = useParams();

	useEffect(() => {
		const updatedButtons =
			topAgenciesData?.map((agency) => {
				return {
					...agency,
					url: `/chats/${agency.id}/`,
				};
			}) || [];

		setHeaderButtons([...originalHeaderButtons, ...updatedButtons]);
	}, [topAgenciesData]);

	function handleRedirect(button: IHeaderAgencyButton | IHeaderButton) {
		const id = button.url.split("/")[2];
		if (agencyId !== id) {
			navigate(button.url);
		}
	}

	return (
		<ButtonWrapperMobile>
			<ButtonComponent>
				<ButtonInnerComponent>
					{headerButtons.map((button) => {
						return (
							<HeaderButton key={button.name} themeMode={themeMode} active={pathname.includes(button.url)} onClick={() => handleRedirect(button)} disabled={isTopAgenciesLoading}>
								{"abbreviation" in button ? button.abbreviation : button.name}
							</HeaderButton>
						);
					})}
					<PlusBtnIcon onClick={() => openModal("guides")}>
						<PlusBtn src="/images/svgs/plus.svg" />
					</PlusBtnIcon>
				</ButtonInnerComponent>
			</ButtonComponent>
		</ButtonWrapperMobile>
	);
});

export { MobileHeaderNav };
