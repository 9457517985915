import { memo, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { InnerSideBarWrapper, ButtonWrapper, PlusIcon } from "../innerSideBar.style";
import { ActiveListContext, ModalContext } from "../../../context";
import { ModalWrapper } from "../../../components/allModals/upload/ModalWrapper";
import { getThemeReducer, useAppSelector } from "../../../store";
import { useChats } from "../../../hook/useChats";
import { ActiveChatList } from "../../../components/common/ActiveChatsList/ActiveList";

const InnerSideChatNav = () => {
	const location = useLocation();
	const { agencyId, chatId } = useParams();
	const { isPathnameIsChats, hasChatsNextPage, fetchChatsNextPage, sortedChats } = useChats({ agency_id: agencyId });
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext);
	const loanWrapper = useRef<HTMLDivElement>(null);
	const [shouldAutoFetchNextPage, setShouldAutoFetchNextPage] = useState<boolean>(false);
	const { themeMode } = useAppSelector(getThemeReducer);
	const { setActiveList } = useContext(ActiveListContext);

	useEffect(() => {
		if (loanWrapper) {
			if (loanWrapper?.current?.scrollHeight === loanWrapper?.current?.clientHeight && hasChatsNextPage) {
				setShouldAutoFetchNextPage(true);
				fetchChatsNextPage();
			} else {
				setShouldAutoFetchNextPage(false);
			}
		}
	}, [loanWrapper, sortedChats, hasChatsNextPage, fetchChatsNextPage]);

	useEffect(() => {
		if (!chatId) {
			setActiveList(sortedChats[0]?.id);
		}
	}, [location.pathname, sortedChats]);

	return (
		<>
			<InnerSideBarWrapper ref={loanWrapper} sx={{ themeMode }} is_pathname_is_loans={isPathnameIsChats} pathname={location.pathname} id="innerSideBarWrapper">
				{isPathnameIsChats && (
					<ButtonWrapper startIcon={<PlusIcon src="/images/svgs/add.svg" />} onClick={() => openModal("chat")}>
						New Chat
					</ButtonWrapper>
				)}
				<ActiveChatList shouldAutoFetchNextPage={shouldAutoFetchNextPage} />
			</InnerSideBarWrapper>
			<ModalWrapper open={isModalOpen && modalKey === "chat"} />
		</>
	);
};

export default InnerSideChatNav;
