import { Fragment, useContext, useEffect, useState } from "react";
import ModalBox from "../../common/modal";
import { ArrowIconModal, ModalBodyWrapper, GuideListItem, GuideItemBox, ItemText } from "../allModals.style";
import { Box, Checkbox, Divider, List, Typography, CircularProgress } from "@mui/material";
import { getThemeReducer, useAppSelector } from "../../../store";
import { IGuideline } from "../../../interfaces/guideline.interface";
import { IAgency } from "../../../interfaces/agency.interface";
import { ListItem } from "./listItem/ListItem";
import { SendBtn } from "../../../pages/styles/home.style";
import { useNavigate, useParams } from "react-router-dom";
import { ModalContext } from "../../../context";
import { agencyService } from "../../../services/agency.service";
import { useAgency } from "../../../hook/useAgency";
import { useQueryClient } from "@tanstack/react-query";

const GuidesModal = ({ open }: any) => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const [guidelines, setGuidelines] = useState<{ [key: string]: IGuideline[] }>({});
	const [activeAgency, setActiveAgency] = useState<string | null>(null);
	const [loadingGuidelines, setLoadingGuidelines] = useState<{ [key: string]: boolean }>({});
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const { closeModal } = useContext(ModalContext);
	const { refetchTopAgencies, getGuidelinesByAgencyId, agenciesData } = useAgency();

	const navigateToAgency = async (agencyId: string) => {
		await agencyService.setViewInteraction(agencyId);
		refetchTopAgencies();
		closeModal();
		navigate(`/chats/${agencyId}/`);
	};

	const handleAgencyClick = async (agencyId: string) => {
		setActiveAgency((prev) => (prev === agencyId ? null : agencyId));

		if (!guidelines[agencyId]) {
			setLoadingGuidelines((prev) => ({ ...prev, [agencyId]: true }));
			try {
				const guidelines = await getGuidelinesByAgencyId(agencyId);
				setGuidelines((prev) => ({ ...prev, [agencyId]: guidelines || [] }));
			} catch (error) {
				console.error("Failed to fetch guidelines:", error);
			} finally {
				setLoadingGuidelines((prev) => ({ ...prev, [agencyId]: false }));
			}
		}
	};

	return (
		<ModalBox width="512px" crossIcon="" headerText="Agency/Investor Guides" cancelButtonText="" actionButtonText="" open={open} handleClose={closeModal}>
			<ModalBodyWrapper header={true} footer={true} sx={{ themeMode }}>
				<Box>
					{isLoading ? (
						<Box sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
							<CircularProgress />
						</Box>
					) : (
						<List>
							{agenciesData?.map((agency: IAgency) => (
								<Fragment key={agency.id}>
									<GuideListItem themeMode={themeMode} onClick={() => handleAgencyClick(agency.id)}>
										<GuideItemBox>
											<ItemText>{agency.name}</ItemText>
											<Box sx={{ display: "flex", alignItems: "center" }}>
												<ArrowIconModal src={`/images/svgs/arrow-${themeMode}.svg`} style={{ transform: activeAgency === agency.id ? "rotate(0deg)" : "rotate(-90deg)" }} />
											</Box>
										</GuideItemBox>
										{activeAgency === agency.id && (
											<List sx={{ width: "100%", padding: "10px 20px" }}>
												{loadingGuidelines[agency.id] ? (
													<Box sx={{ display: "flex", justifyContent: "center", margin: "10px 0" }}>
														<CircularProgress size={24} />
													</Box>
												) : (
													<Fragment>
														<Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
															{guidelines[agency.id]?.map((guide: IGuideline) => (
																<Fragment key={guide.id}>
																	<ListItem guideline={guide} />
																</Fragment>
															))}
														</Box>
														<SendBtn
															sx={{
																themeMode,
																margin: "8px 10px 8px 0px",
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																gap: "6px",
															}}
															onClick={() => navigateToAgency(agency.id)}
														>
															View
															<img src={"/images/svgs/eyeball.svg"} alt="View Icon" style={{ width: "16px", height: "16px" }} />
														</SendBtn>
													</Fragment>
												)}
											</List>
										)}
									</GuideListItem>
								</Fragment>
							))}
						</List>
					)}
				</Box>
			</ModalBodyWrapper>
		</ModalBox>
	);
};

export default GuidesModal;
