import { DeleteModalBody, ModalBodyWrapper, DeleteModalHeading, SubheadingWrapper, SubheadingInner, IconModal, ModalSubHeading, ModalBody, IconModalWrapper } from "../allModals.style";
import { getThemeReducer, useAppSelector } from "../../../store";

const CreateChatDropdownModal = () => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<ModalBodyWrapper sx={{ themeMode, paddingBlock: "42px 24px" }}>
			{/* @ts-ignore */}
			<IconModalWrapper sx={{ themeMode }}>
				<IconModal alt="Modal Warning" src="/images/svgs/warning.svg" />
			</IconModalWrapper>
			<ModalBody>
				<DeleteModalHeading>This will create a new chat</DeleteModalHeading>
			</ModalBody>
			<SubheadingWrapper>
				<SubheadingInner>
					<ModalSubHeading sx={{ themeMode }}>Are you want to do that?</ModalSubHeading>
				</SubheadingInner>
			</SubheadingWrapper>
		</ModalBodyWrapper>
	);
};

export { CreateChatDropdownModal };
