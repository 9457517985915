import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

export const PowerUpFormItemWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "flex-start",
	gap: "10px",
	marginBottom: "25px",
	[theme.breakpoints.down("md")]: {
		gap: "6px",
	},
}));

export const PowerUpItemTitle = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
	fontSize: "18px",
	marginBottom: "7px",
	fontFamily: "Bai Jamjuree",
	[theme.breakpoints.down("md")]: {
		fontSize: "16px",
		marginBottom: "6px",
	},
}));

export const PowerUpItemSubTitle = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "15px",
	lineHeight: "25px",
	fontFamily: "Bai Jamjuree",
	[theme.breakpoints.down("md")]: {
		fontSize: "13px",
		lineHeight: "20px",
	},
}));
