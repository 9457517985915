import { chatConstants, urls } from "../constants";
import { IMessage, ICommonParams, IChatTimeline, ICreateMessage, ISpeechToText } from "../interfaces";
import { IFeedbackRequest, IFeedbackResponse } from "../interfaces/feedback.interface";
import { sortEntity } from "../utils/sortEntity";
import { apiService } from "./api.service";

export const messageService = {
	getByLoanId: async (loanId: string, params?: ICommonParams) => {
		const { data } = await apiService.get<IMessage[]>(urls.loanMessages(loanId), {
			params,
		});
		return sortEntity<IMessage>(data, "timestamp", "asc");
	},

	getLoanChatTimeline: async (loanId: string, pageParam: number, signal: AbortSignal) => {
		const params = {
			skip: pageParam * chatConstants.skip,
			limit: chatConstants.limit,
		};
		const { data } = await apiService.get<IChatTimeline[]>(urls.chatTimeline(loanId), { params, signal });
		return data.reverse();
	},

	sendLoanMessage: async (loanId: string, message: ICreateMessage) => {
		const { data } = await apiService.post<string>(urls.loanMessages(loanId), message);
		return data;
	},
	sendFeedback: async (messageId: string, feedback: IFeedbackRequest) => {
		const { data } = await apiService.post<IFeedbackResponse>(urls.feedback(messageId), feedback);
		return data;
	},
	speechToText: async (file: FormData) => {
		const { data } = await apiService.post<ISpeechToText>(urls.speechToText, file, { headers: { "Content-Type": "multipart/form-data" } });
		return data;
	},

	getCitationView: async (messageId: string, citationId: string, relativePage: number) => {
		const { data } = await apiService.get<any>(`${urls.messageById(messageId)}/citations/${citationId}/view`, { params: { relative_page: relativePage }, responseType: "blob" });
		return data;
	},

	getChatTimeline: async (chatId: string, pageParam: number, signal: AbortSignal) => {
		const params = {
			skip: pageParam * chatConstants.skip,
			limit: chatConstants.limit,
		};
		const { data } = await apiService.get<IChatTimeline[]>(`${urls.chatById(chatId)}/chat_timeline`, { params, signal });
		return data.reverse();
	},

	sendChatMessage: async (chatId: string, message: ICreateMessage) => {
		const { data } = await apiService.post<string>(`${urls.chatById(chatId)}/messages/`, message);
		return data;
	},
};
