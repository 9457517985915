import React, { useState, useEffect, useContext } from "react";
import ActionButton from "./components/ActionButton/actionButton";
import {
	ButtonBoxWrapper,
	RightNavComponent,
	ListItemBoxWrapper,
	ActionBtnBoxWrapper,
	DoucmentBtn,
	DoucmentBtnIcon,
	DoucmentCrossIcon,
	DocumentHeader,
	DocumentIcon,
	RightNavBarListWrapper,
} from "../rightNavBar.style";
import ListItems from "./components/listItem/listItems";
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { breakpointConstants } from "../../../constants";
import { drawerActions, getDrawerReducer, getThemeReducer, useAppDispatch, useAppSelector } from "../../../store";
import GuidelineDropdown from "./components/Dropdown/Dropdown";
import { ModalWrapper } from "../../../components/allModals/upload/ModalWrapper";
import { ModalContext } from "../../../context";

const RightNavChatBar = () => {
	const [isLargeScreen, setIsLargeScreen] = useState(() => window.innerWidth >= breakpointConstants.autoCollapse.rightNavbar);

	useEffect(() => {
		const handleResize = () => {
			setIsLargeScreen(window.innerWidth >= breakpointConstants.autoCollapse.rightNavbar);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const theme = useTheme();
	const downSm = useMediaQuery(theme.breakpoints.down("sm"));
	const { isRightNavBarDrawerOpen } = useAppSelector(getDrawerReducer);
	const { themeMode } = useAppSelector(getThemeReducer);
	const dispatch = useAppDispatch();
	const { toggleIsRightDrawerOpen: toggleIsDrawerOpen } = drawerActions;
	const { isModalOpen, modalKey } = useContext(ModalContext);

	return (
		<>
			{isLargeScreen ? (
				<RightNavComponent>
					<RightNavBarListWrapper sx={{ themeMode }}>
						<ButtonBoxWrapper sx={{ marginBottom: "18px" }}>
							<GuidelineDropdown />
						</ButtonBoxWrapper>
						<ListItemBoxWrapper>
							<ListItems />
						</ListItemBoxWrapper>
					</RightNavBarListWrapper>
					<ActionBtnBoxWrapper>
						<ActionButton />
					</ActionBtnBoxWrapper>
				</RightNavComponent>
			) : (
				<Drawer
					variant="temporary"
					open={isRightNavBarDrawerOpen}
					onClose={() => dispatch(toggleIsDrawerOpen())}
					anchor="right"
					PaperProps={{
						sx: {
							width: `${downSm ? "100vw" : "400px"}`,
						},
					}}
				>
					{/* Document Header */}
					<DocumentHeader>
						<Box>
							<DoucmentBtn startIcon={<DoucmentBtnIcon src={`${themeMode === "light" ? "/images/svgs/headerDoc.svg" : "/images/svgs/headerDoc-dark.svg"}`} />}> Documents </DoucmentBtn>
						</Box>
						<DocumentIcon>
							<DoucmentCrossIcon src={`${themeMode === "light" ? "/images/svgs/cross.svg" : "/images/svgs/cross-dark.svg"}`} onClick={() => dispatch(toggleIsDrawerOpen())} />
						</DocumentIcon>
					</DocumentHeader>
					<RightNavComponent>
						<Box
							sx={{
								height: "calc(100vh - 254px)",
								overflow: "auto",
							}}
						>
							<ButtonBoxWrapper sx={{ marginBottom: "18px" }}>
								<GuidelineDropdown />
							</ButtonBoxWrapper>
							<ListItemBoxWrapper>
								<ListItems />
							</ListItemBoxWrapper>
						</Box>
						<ActionBtnBoxWrapper>
							<ActionButton />
						</ActionBtnBoxWrapper>
					</RightNavComponent>
				</Drawer>
			)}
			<ModalWrapper open={isModalOpen && modalKey === "create_chat"} />
		</>
	);
};

export default RightNavChatBar;
