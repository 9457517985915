import { IHeaderButton } from "../interfaces/headerButtons.interface";

export const headerButtons: IHeaderButton[] = [
	{
		name: "LOANS",
		variant: "contained",
		url: "/loans",
		ref: true,
	},
];
