import { ToolTip } from "../enum/tooltip.enum";
import { ITooltip } from "../interfaces";

export const guidelineTooltips: ITooltip[] = [
	{
		title: ToolTip.FRAUD_CHECK,
		arrow: true,
		placement: "top-start",
		src: {
			light: "/images/svgs/verified.svg",
			dark: "/images/svgs/verified-dark.svg",
		},
	},
	{
		title: ToolTip.CREATE_CHAT,
		arrow: true,
		placement: "top",
		src: {
			light: "/images/svgs/chat-plus.svg",
			dark: "/images/svgs/chat-plus-dark.svg",
		},
	},
	{
		arrow: true,
		placement: "top-start",
		src: {
			light: "/images/svgs/stars.svg",
			dark: "/images/svgs/stars-dark.svg",
		},
	},
	{
		title: ToolTip.VIEW,
		arrow: true,
		placement: "top",
		src: {
			light: "/images/svgs/link2.svg",
			dark: "/images/svgs/link2-dark.svg",
		},
	},
];
