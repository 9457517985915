import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { IAgency } from "../interfaces/agency.interface";
import { QueryKey } from "../enum";
import { userService } from "../services";
import { agencyService } from "../services/agency.service";
import { IGuideline } from "../interfaces/guideline.interface";
import { useParams } from "react-router-dom";

export const useAgency = () => {
	const { agencyId } = useParams();
	const queryClient = useQueryClient();

	const {
		data: agenciesData,
		isLoading: isAgenciesLoading,
		isPending: isAgenciesPending,
		isFetching: isAgenciesFetching,
		refetch: refetchAgencies,
	} = useQuery<IAgency[], Error>({
		queryKey: [QueryKey.AGENCY],
		queryFn: () => agencyService.getAgencies(),
		staleTime: Infinity,
	});

	const {
		data: topAgenciesData,
		isLoading: isTopAgenciesLoading,
		isPending: isTopAgenciesPending,
		isFetching: isTopAgenciesFetching,
		refetch: refetchTopAgencies,
	} = useQuery<IAgency[], Error>({
		queryKey: [QueryKey.TOP_AGENCIES],
		queryFn: () => userService.getTopAgencies(),
		staleTime: Infinity,
	});

	const {
		data: guidelinesData,
		isLoading: isGuidelinesLoading,
		isPending: isGuidelinesPending,
		isFetching: isGuidelinesFetching,
		refetch: refetchGuidelines,
	} = useQuery<IGuideline[], Error>({
		queryKey: [QueryKey.GUIDELINE, { agencyId }],
		queryFn: () => agencyService.getGuidelines(agencyId),
		staleTime: Infinity,
		enabled: !!agencyId,
	});

	const getGuidelinesByAgencyId = async (agencyId: string): Promise<IGuideline[]> => {
		const queryKey = [QueryKey.GUIDELINE, agencyId];

		const cachedData = queryClient.getQueryData<IGuideline[]>(queryKey);
		if (cachedData) {
			return cachedData;
		}

		const data = await queryClient.fetchQuery({ queryKey, queryFn: () => agencyService.getGuidelines(agencyId) });
		return data;
	};

	return {
		topAgenciesData,
		isTopAgenciesLoading,
		isTopAgenciesPending,
		isTopAgenciesFetching,
		refetchTopAgencies,
		guidelinesData,
		isGuidelinesLoading,
		isGuidelinesPending,
		isGuidelinesFetching,
		refetchGuidelines,
		getGuidelinesByAgencyId,
		agenciesData,
		isAgenciesLoading,
		isAgenciesPending,
		isAgenciesFetching,
		refetchAgencies,
	};
};
