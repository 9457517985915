const baseURL = `${process.env.REACT_APP_API_URL}api/v1/`;

const loans = "loans/";
const chats = "chats/";
const agencies = "agencies/";
const chatTimeline = "chat_timeline";
const suggestions = "suggestions/";
const bulkUpdate = "bulk_update";
const newLoan = "new-loan/";
const newDocument = "new_documents/";
const analyses = "analyses/";
const feedback = "feedback/";
const messages = "messages/";
const documents = "documents/";
const users = "users/";
const me = "me";
const company = "companies/";
const speechToText = "speech-to-text/";
const billingAccount = "billing_accounts";
const view = "view";

const urls = {
	loans,
	chats,
	agencies,
	newLoan,
	messages,
	loanById: (loanId: string): string => `${loans}${loanId}`,
	chatById: (chatId: string): string => `${chats}${chatId}`,
	messageById: (messageId: string): string => `${messages}${messageId}`,

	loanMessages: (loanId: string): string => `${urls.loanById(loanId)}/${messages}`,
	chatTimeline: (loanId: string): string => `${urls.loanById(loanId)}/${chatTimeline}`,
	documents: (loanId: string): string => `${urls.loanById(loanId)}/${documents}`,
	suggestions: (loanId: string): string => `${urls.loanById(loanId)}/${suggestions}`,
	bulkUpdate: (loanId: string): string => `${urls.loanById(loanId)}/${bulkUpdate}`,
	uploadNewDocument: (loanId: string): string => `${urls.loanById(loanId)}/${newDocument}`,
	updateDocument: (loanId: string, document_id: string): string => `${urls.loanById(loanId)}/${documents}${document_id}`,
	analyses: (loanId: string): string => `${urls.loanById(loanId)}/${analyses}`,
	feedback: (messageId: string): string => `${messages}${messageId}/${feedback}`,

	me: `${users}${me}`,
	company: `${company}${me}`,
	speechToText: `${speechToText}`,
	billingAccount: `${billingAccount}/${me}`,
	docView: (loanId: string, document_id: string): string => `${urls.documents(loanId)}${document_id}/${view}`,
};

export { baseURL, urls };
