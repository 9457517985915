import { Box, List } from "@mui/material";
import { FC, useContext, useEffect, useRef } from "react";

import { useActiveList } from "../../../hook/useActiveList";
import { useLoans } from "../../../hook/useLoans";
import { ActiveListItem } from "./ActiveListItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../Loader/Loader";
import { ModalWrapper } from "../../allModals/upload/ModalWrapper";
import { ActiveListContext, ModalContext } from "../../../context";
import { useParams } from "react-router-dom";
import { getThemeReducer, useAppSelector } from "../../../store";

interface IActiveListProps {
	shouldAutoFetchNextPage: boolean;
}

const ActiveList: FC<IActiveListProps> = ({ shouldAutoFetchNextPage }) => {
	const { listItems } = useActiveList();
	const { sortedLoans, isLoansLoading, hasLoansNextPage, fetchLoansNextPage, isPathnameIsLoans, isLoansFetchingNextPage } = useLoans();
	const { updateActiveList } = useActiveList();
	const { loanId } = useParams();
	const { setActiveList } = useContext(ActiveListContext);
	const { themeMode } = useAppSelector(getThemeReducer);

	const listRef = useRef<HTMLDivElement>(null);
	const { isModalOpen, modalKey } = useContext(ModalContext);

	const mappedContent = listItems.map((item: any) => <ActiveListItem key={item.id} item={item} />);

	useEffect(() => {
		if (!isLoansLoading && isPathnameIsLoans) {
			const activeLoanByPathname = sortedLoans.find((loan) => loan.id === loanId);
			if (activeLoanByPathname) {
				setActiveList(loanId);
			} else {
				updateActiveList();
			}
		}
	}, [isLoansLoading]);

	return (
		<Box ref={listRef}>
			<List>
				{isLoansLoading && isPathnameIsLoans ? (
					<Loader />
				) : !isPathnameIsLoans ? (
					<>{mappedContent}</>
				) : mappedContent.length > 0 ? (
					<InfiniteScroll dataLength={listItems.length} hasMore={hasLoansNextPage} next={fetchLoansNextPage} loader={<Loader />} scrollableTarget="innerSideBarWrapper">
						{mappedContent}
					</InfiniteScroll>
				) : (
					<Box sx={{ color: themeMode === "dark" ? "#ffffffb0" : "#03363da2", marginTop: "12px", fontSize: "16px" }}>No Loans Found</Box>
				)}
				{shouldAutoFetchNextPage && isLoansFetchingNextPage && isPathnameIsLoans && <Loader />}
			</List>
			<ModalWrapper open={isModalOpen && ["archived", "delete", "delete_doc"].includes(modalKey)} />
		</Box>
	);
};

export { ActiveList };
