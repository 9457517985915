import { FC } from "react";
import { Box, Checkbox } from "@mui/material";
import UnCheckRight from "../../../../../../assets/unCheckRight";
import CheckRight from "../../../../../../assets/checkRight";
import { useTooltip } from "../../../../../../hook/useTooltip";
import { useActiveList } from "../../../../../../hook/useActiveList";
import { DocumentIconWrapper } from "../../../../../innerSideBar/innerSideBar.style";
import { getThemeReducer, useAppSelector } from "../../../../../../store";
import { chatDocumentTooltips } from "../../../../../../constants/chatDocumentTooltips";
import { CheckBoxText, Heading, ListWrapper, SubHeading } from "../../../../rightNavBar.style";
import { ISection } from "../../../../../../interfaces/section.interface";
import { useSections } from "../../../../../../hook/useSections";

interface IListItemProps {
	document: ISection;
}

const ListItem: FC<IListItemProps> = ({ document }) => {
	const { onActiveChange } = useSections();
	const { parseDocumentTooltips } = useTooltip();
	const { isHover, onHoverHandler, onMouseLeave } = useActiveList();
	const { themeMode } = useAppSelector(getThemeReducer);

	const clickCheckboxViaTitle = (): void => {
		onActiveChange(document.id, !document.active);
	};

	return (
		<Box onMouseEnter={onHoverHandler} onMouseLeave={onMouseLeave}>
			<ListWrapper>
				<CheckBoxText>
					{document.rag_searchable && (
						<Checkbox
							icon={<UnCheckRight color={themeMode === "light" ? "#03363D" : "#FFFFFF"} />}
							checkedIcon={<CheckRight />}
							onChange={() => onActiveChange(document.id, !document.active)}
							checked={document.active}
						/>
					)}
					<Box>
						<Box onClick={clickCheckboxViaTitle}>
							<Heading>
								<span style={{ marginRight: "5px" }}>{document.display_name}</span>
							</Heading>
						</Box>
						{document.subtitle && <SubHeading onClick={clickCheckboxViaTitle}>{document.subtitle}</SubHeading>}
					</Box>
				</CheckBoxText>
				{isHover && (
					<DocumentIconWrapper
						sx={{
							themeMode,
						}}
					>
						{parseDocumentTooltips(chatDocumentTooltips, document)}
					</DocumentIconWrapper>
				)}
			</ListWrapper>
		</Box>
	);
};

export { ListItem };
