import { FC } from "react";
import { CheckBoxText, Heading, ListWrapper, SubHeading } from "../../../../rightNavBar.style";
import { Box, Checkbox } from "@mui/material";
import UnCheckRight from "../../../../../../assets/unCheckRight";
import CheckRight from "../../../../../../assets/checkRight";
import { IDocument } from "../../../../../../interfaces";
import { PowerUp } from "../../../../../../components/common/PowerUp/PowerUp";
import { useDocuments } from "../../../../../../hook/useDocuments";
import { useTooltip } from "../../../../../../hook/useTooltip";
import { documentTooltips } from "../../../../../../constants/documentTooltips";
import { useActiveList } from "../../../../../../hook/useActiveList";
import { DocumentIconWrapper } from "../../../../../innerSideBar/innerSideBar.style";
import { getThemeReducer, useAppSelector } from "../../../../../../store";

interface IListItemProps {
	document: IDocument;
}

const ListItem: FC<IListItemProps> = ({ document }) => {
	const { onActiveChange } = useDocuments();
	const { parseDocumentTooltips } = useTooltip();
	const { isHover, onHoverHandler, onMouseLeave } = useActiveList();
	const { themeMode } = useAppSelector(getThemeReducer);

	const clickCheckboxViaTitle = (): void => {
		onActiveChange(document.id, !document.active);
	};

	return (
		<Box onMouseEnter={onHoverHandler} onMouseLeave={onMouseLeave}>
			<ListWrapper>
				<CheckBoxText>
					<Checkbox
						icon={<UnCheckRight color={themeMode === "light" ? "#03363D" : "#FFFFFF"} />}
						checkedIcon={<CheckRight />}
						onChange={() => onActiveChange(document.id, !document.active)}
						checked={document.active}
					/>
					<Box>
						<Box onClick={clickCheckboxViaTitle}>
							<Heading>
								<span style={{ marginRight: "5px" }}>{document.name}</span>
								{document.powerup && <PowerUp />}
							</Heading>
						</Box>
						{document.subtitle && <SubHeading onClick={clickCheckboxViaTitle}>{document.subtitle}</SubHeading>}
					</Box>
				</CheckBoxText>
				{isHover && (
					<DocumentIconWrapper
						sx={{
							themeMode,
						}}
					>
						{parseDocumentTooltips(documentTooltips, document)}
					</DocumentIconWrapper>
				)}
			</ListWrapper>
		</Box>
	);
};

export { ListItem };
