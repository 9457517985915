export enum ToolTip {
	FRAUD_CHECK = "Fraud Check",
	SUMMARY = "Summary",
	VIEW = "View",
	DELETE = "Delete",
	ARCHIVE = "Archive",
	REFRESH = "Refresh",
	CREATE_CHAT = "Create Chat",
	REFRESH_CHAT = "Refresh Chat",
	DELETE_CHAT = "Delete Chat",
	ARCHIVE_CHAT = "Archive Chat",
	VIEW_SECTION = "View Section",
	DELETE_DOC = "Delete Doc",
}
