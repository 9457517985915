import { Box } from "@mui/material";
import { useLogout } from "../../../../hook/useLogout";
import { FeedbackIconWrapperComponent } from "../../../../pages/styles/home.style";
import { getThemeReducer, useAppSelector } from "../../../../store";
import { ModalHeaderWrapper, HeaderText, FeedbackIcon, LikeIconWrapper, CrossIcon, CrossIconBox } from "./modal.header.style";

const ModalHeader = (props: any) => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const { showLikeIcon, crossIcon, headerText, handleClose, closingComponent } = props;
	const { logoutHandler } = useLogout();

	return (
		<>
			<ModalHeaderWrapper>
				<LikeIconWrapper>
					{showLikeIcon && (
						<FeedbackIconWrapperComponent sx={{ themeMode }}>
							<FeedbackIcon src={`${showLikeIcon}`} />
						</FeedbackIconWrapperComponent>
					)}
					{headerText && <HeaderText>{headerText}</HeaderText>}
				</LikeIconWrapper>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
					{closingComponent ? closingComponent : ""}
					{crossIcon && (
						// @ts-ignore
						<CrossIconBox themeMode={themeMode} iconBg={false}>
							<CrossIcon
								src={`${themeMode === "light" ? "/images/svgs/cross.svg" : "/images/svgs/cross-dark.svg"}`}
								onClick={() => handleClose()}
								sx={(theme) => ({
									[theme.breakpoints.down("md")]: {
										width: "16px",
										height: "16px",
									},
								})}
							/>
						</CrossIconBox>
					)}
					{props.headerRightCornerIcon && <CrossIcon onClick={logoutHandler} src={props.headerRightCornerIcon} />}
				</Box>
			</ModalHeaderWrapper>
		</>
	);
};

export default ModalHeader;
