import { memo } from "react";
import { NewChatWrapper } from "../../components/common/NewChatButton/NewChatWrapper";
import { useLoans } from "../../hook/useLoans";
import { NewLoanWrapper } from "../../components/common/NewLoanButton/NewLoanWrapper";
import { InnerSideLoanBar } from "../../components/common/InnerSideBar/innesSideLoanBar/InnerSideLoanBar";
import { InnerSideChatBar } from "../../components/common/InnerSideBar/innerSideChatBar/InnerSideChatBar";

const MobileNav = memo(() => {
	const { isPathnameIsLoans } = useLoans();

	return (
		<>
			{isPathnameIsLoans ? (
				<>
					<InnerSideLoanBar />
					<NewLoanWrapper src="/images/svgs/add.svg" />
				</>
			) : (
				<>
					<InnerSideChatBar />
					<NewChatWrapper src="/images/svgs/add.svg" />
				</>
			)}
		</>
	);
});

export default MobileNav;
