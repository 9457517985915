import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppRootState } from "../store";

interface InitialState {
	themeMode: "light" | "dark";
	skipFlag: boolean;
}

const initialState: InitialState = {
	themeMode: "dark",
	skipFlag: false,
};

const themeSlice = createSlice({
	name: "themeSlice",
	initialState,
	reducers: {
		toggleTheme: (state: InitialState) => {
			state.themeMode = state.themeMode === "dark" ? "light" : "dark";
		},
		setTheme: (state: InitialState, action: PayloadAction<"light" | "dark">) => {
			if (!state.skipFlag) {
				state.themeMode = action.payload;
			}
		},
		setSkipFlag: (state: InitialState, action: PayloadAction<boolean>) => {
			state.skipFlag = action.payload;
		},
	},
});

const getThemeReducer = (state: AppRootState) => state.themeReducer;

const { reducer: themeReducer, actions: themeActions } = themeSlice;

export { themeReducer, themeActions, getThemeReducer };
