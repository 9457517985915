import { ReactNode, useContext, useEffect } from "react";
import { CurrentStep, IDetails, IDocument, ILoan, ISetting, ITooltip, ModalKey } from "../interfaces";
import { CustomToolTip } from "../components/common/ToolTip/ToolTip";
import { ToolTip, DetailsType, QueryKey } from "../enum";
import { ActiveListContext, ModalContext } from "../context";
import { useLoans } from "./useLoans";
import { useMessages } from "./useMessages";
import { useDocuments } from "./useDocuments";
import { useSuggestions } from "./useSuggestions";
import { documentService } from "../services";
import { useNavigate, useParams } from "react-router-dom";
import { setFileExtension } from "../utils/fileHandlers";
import { getThemeReducer, useAppSelector } from "../store";
import { IGuideline } from "../interfaces/guideline.interface";
import { useChats } from "./useChats";
import { chatService } from "../services/chat.service";
import { IChat } from "../interfaces/chat.interface";
import { agencyService } from "../services/agency.service";
import { useQueryClient } from "@tanstack/react-query";
import { useChatMessages } from "./useChatMessages";
import { ISection } from "../interfaces/section.interface";
import { useSections } from "./useSections";

export const useTooltip = () => {
	const { openModal, setDocumentId, document_id } = useContext(ModalContext);
	const { refetchLoans, isLoansFetching } = useLoans();
	const { refetchMessages, isMessagesFetching } = useMessages();
	const { refetchDocuments, isDocumentsFetching, sortedDocuments } = useDocuments();
	const { refetchSuggestions } = useSuggestions();
	const { loanId, agencyId } = useParams();
	const { setActiveEntity, activeEntity } = useContext(ActiveListContext);
	const { isSuggestionFetching } = useSuggestions();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { createNewChatMutation, isChatsFetching } = useChats();
	const { isMessagesFetching: isChatMessagesFetching, refetchMessages: refetchChatMessages } = useChatMessages();
	const { isSectionsFetching } = useSections();
	const { setStep, step, closeModal } = useContext(ModalContext);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const isEntitiesRefreshing = isLoansFetching || isMessagesFetching || isDocumentsFetching || isSuggestionFetching;
	const isChatRefreshing = isChatsFetching || isChatMessagesFetching || isSectionsFetching;
	const isChatLoaded = createNewChatMutation.isSuccess;

	const compareEntitiesKeys = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
		let isTheSame = true;
		let i = 0;
		while (i < Object.keys(obj1).length) {
			if (Object.keys(obj1)[i] === Object.keys(obj2)[i]) {
				i++;
			} else {
				isTheSame = false;
				break;
			}
		}

		return isTheSame;
	};

	const viewSectionTooltipHandler = async (entity: ISection) => {
		const response = await agencyService.getGuidelineAgencyView(agencyId, entity.guideline_id, entity.id);
		const url = response.sas_url;
		window.open(url, "_blank");
		return;
	};

	const viewTooltipHandler = async (entity: IDocument | ILoan | IGuideline | ISetting): Promise<void> => {
		if ("agency_id" in entity && "id" in entity) {
			const response = await agencyService.getGuidelineView(entity.agency_id, entity.id);
			const url = response.sas_url;
			window.open(url, "_blank");
			return;
		}

		if (compareEntitiesKeys(entity, Object.values(sortedDocuments)[0][0])) {
			const doc = entity as IDocument;
			setActiveEntity({ entity, tooltipTitle: ToolTip.VIEW });

			try {
				const res = await documentService.docView({
					loanId: loanId,
					document_id: doc.id,
				});
				const contentType = res.headers["content-type"];
				const fileType = contentType === "application/octet-stream" ? "application/pdf" : contentType;
				const blob = new Blob([res.data], { type: fileType });
				let url = URL.createObjectURL(blob);

				if (contentType === "application/pdf") {
					localStorage.setItem("documentUrl", url);
					localStorage.setItem("documentName", doc.name);
					localStorage.setItem("themeMode", themeMode);
					window.open(`${window.location.origin}/loans/${loanId}/documents/${doc.id}`, "_blank");
				} else {
					const a = document.createElement("a");
					a.style.display = "none";
					document.body.append(a);
					a.href = url;
					const fileExt = setFileExtension(contentType);
					a.download = doc.name + fileExt;
					a.click();
					URL.revokeObjectURL(url);
					document.body.removeChild(a);
				}
			} catch (e) {
				console.error("Error during downloading file", e);
			} finally {
				setActiveEntity(null);
			}
		}
	};

	const refreshHandler = (entity: any): void => {
		if (!isEntitiesRefreshing) {
			setActiveEntity({ entity, tooltipTitle: ToolTip.REFRESH });
			refetchLoans();
			refetchDocuments();
			refetchMessages();
			refetchSuggestions();
		}
	};

	const refreshChatHandler = (entity: any): void => {
		if (!isChatRefreshing) {
			setActiveEntity({ entity, tooltipTitle: ToolTip.REFRESH_CHAT });
			queryClient.invalidateQueries({ queryKey: [QueryKey.CHATS] });
			queryClient.invalidateQueries({ queryKey: [QueryKey.SECTION] });
			queryClient.invalidateQueries({ queryKey: [QueryKey.MESSAGES] });
		}
	};

	useEffect(() => {
		if ((!isEntitiesRefreshing && activeEntity?.tooltipTitle === ToolTip.REFRESH) || (!isChatRefreshing && activeEntity?.tooltipTitle === ToolTip.REFRESH_CHAT)) {
			setActiveEntity(null);
		}
	}, [isEntitiesRefreshing, isChatRefreshing, activeEntity, setActiveEntity]);

	const openModalBySettingModaLKeyAndSetDocId = (modalKey: ModalKey, entity?: IDocument | ILoan | IChat | ISetting): void => {
		openModal(modalKey);
		setDocumentId((entity as IDocument).id.toString());
		// if (sortedDocuments && Array.isArray(sortedDocuments[0]) && compareEntitiesKeys(entity, Object.values(sortedDocuments)[0][0])) {
		// 	setDocumentId((entity as IDocument).id.toString());
		// }
	};

	const createChat = async (entity: IGuideline) => {
		createNewChatMutation.mutate({ agencyId: entity.agency_id, guidelineId: entity.id });
		closeModal();
	};

	const tooltipHandlers: Record<string, Function | null> = {
		[ToolTip.FRAUD_CHECK]: null,
		[ToolTip.SUMMARY]: null,
		[ToolTip.VIEW]: (entity: IDocument | ILoan | IGuideline | ISetting) => viewTooltipHandler(entity),
		[ToolTip.VIEW_SECTION]: (entity: ISection) => viewSectionTooltipHandler(entity),
		[ToolTip.DELETE]: (entity: IDocument | ILoan | ISetting) => openModalBySettingModaLKeyAndSetDocId("delete", entity),
		[ToolTip.DELETE_DOC]: (entity: IDocument | ILoan | ISetting) => openModalBySettingModaLKeyAndSetDocId("delete_doc", entity),
		[ToolTip.CREATE_CHAT]: (entity: IGuideline) => createChat(entity),
		[ToolTip.ARCHIVE]: (entity: IDocument | ILoan | ISetting) => openModalBySettingModaLKeyAndSetDocId("archived", entity),
		[ToolTip.REFRESH]: (entity: any) => refreshHandler(entity),
		[ToolTip.REFRESH_CHAT]: (entity: any) => refreshChatHandler(entity),
		[ToolTip.DELETE_CHAT]: (entity: any) => openModalBySettingModaLKeyAndSetDocId("delete_chat", entity),
		[ToolTip.ARCHIVE_CHAT]: (entity: any) => openModalBySettingModaLKeyAndSetDocId("archived_chat", entity),
	};

	const attachHandlers = (title: ToolTip | string, entity: IDocument | ILoan | IGuideline | ISetting | ISection): (() => void) => {
		return tooltipHandlers[title] ? () => tooltipHandlers[title](entity) : undefined;
	};

	const parseGuidelineTooltips = (tooltips: ITooltip[], document: IGuideline): ReactNode[] => {
		return tooltips.map((tooltip: ITooltip, index: number) => {
			const { title } = tooltip;
			if (!title) {
				tooltip = { ...tooltip, title: `**Summary**\n\n${document.description}` };
			}
			return <CustomToolTip key={index} tooltip={tooltip} marginLeft="0px" onClick={attachHandlers(tooltip.title, document)} id={document.id} />;
		});
	};

	const parseDocumentTooltips = (tooltips: ITooltip[], document: IDocument | ISection): ReactNode[] => {
		return tooltips.map((tooltip: ITooltip, index: number) => {
			const { title } = tooltip;
			if (!title) {
				tooltip = { ...tooltip, title: `**Summary**\n\n${document.summary}` };
			}
			return (
				<CustomToolTip
					key={index}
					tooltip={tooltip}
					marginLeft="0px"
					onClick={attachHandlers(tooltip.title, document)}
					isScanned={document.hasOwnProperty("scanned") ? (document as IDocument).scanned ?? false : false}
					id={document.id}
				/>
			);
		});
	};

	const parseDetailsTooltip = (details: string): string => {
		const { title, items, type }: IDetails = JSON.parse(details);

		switch (type as DetailsType) {
			case DetailsType.LIST: {
				return `**${title}**\n\n${items.map((item) => `* ${item}`).join("\n")}`;
			}
			default: {
				return null;
			}
		}
	};

	return {
		parseDocumentTooltips,
		tooltipHandlers,
		attachHandlers,
		parseDetailsTooltip,
		parseGuidelineTooltips,
	};
};
