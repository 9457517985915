import { useAuth0 } from "@auth0/auth0-react";
import { authInterceptor } from "../../services/auth.interceptor";
import { useEffect } from "react";
import { useLogout } from "../../hook/useLogout";

const AuthInject = () => {
	const { getAccessTokenSilently } = useAuth0();
	const { logoutHandler } = useLogout();

	useEffect(() => {
		authInterceptor.setAuthGetter(() => getAccessTokenSilently());
		authInterceptor.setLogoutUserMethod(logoutHandler);
		return () => {
			authInterceptor.setAuthGetter(undefined);
			authInterceptor.setLogoutUserMethod(undefined);
		};
	}, [getAccessTokenSilently, logoutHandler]);

	return <></>;
};

export { AuthInject };
