import { UnionToIntersection } from "react-hook-form/dist/types/path/common";
import { IBillingAccount, ICompany, IUser } from "../../interfaces";
import { OptionalEntity } from "../../hook/useConfig";

export type FormConfigData = {
	label?: string;
	currentValue: any;
	inputName: keyof UnionToIntersection<OptionalEntity>;
	type?: string;
	values?: string[];
	title?: string;
	subtitle?: string;
	disabled?: boolean;
};

export const accountConfig = (user: IUser): FormConfigData[] => {
	return [
		{
			label: "First Name",
			currentValue: `${user.first_name}`,
			inputName: "first_name",
			type: "text",
		},
		{
			label: "Last Name",
			currentValue: `${user.last_name}`,
			inputName: "last_name",
			type: "text",
		},
		{
			label: "Country",
			currentValue: `${user.country}`,
			inputName: "country",
			type: "text",
		},
		{
			label: "State",
			currentValue: `${user.state}`,
			inputName: "state",
			type: "text",
		},
		{
			label: "City",
			currentValue: `${user.city}`,
			inputName: "city",
			type: "text",
		},
		{
			label: "Address One",
			currentValue: `${user.address_one}`,
			inputName: "address_one",
			type: "text",
		},
		{
			label: "Address Two",
			currentValue: `${user.address_two}`,
			inputName: "address_two",
			type: "text",
		},
		{
			label: "Zip code",
			currentValue: `${user.zip_code}`,
			inputName: "zip_code",
			type: "number",
		},
		{
			label: "Phone number",
			currentValue: `${user.phone_number}`,
			inputName: "phone_number",
			type: "phone",
		},
		{
			label: "Time zone",
			currentValue: `${user.time_zone}`,
			inputName: "time_zone",
			type: "text",
		},
		{
			label: "Birthday",
			currentValue: `${user.date_of_birth}`,
			inputName: "date_of_birth",
			type: "date",
		},
		{
			label: "Language preference",
			currentValue: `${user.language_preference}`,
			inputName: "language_preference",
			type: "text",
		},
		{
			label: "Theme preference",
			currentValue: `${user.theme_preference}`,
			inputName: "theme_preference",
			type: "radio",
			values: ["light", "dark"],
		},
		{
			label: "Lending type",
			currentValue: `${user.lending_type}`,
			inputName: "lending_type",
			type: "radio",
			values: ["mortgage", "small_business"],
		},
	];
};

export const companyConfig = (company: ICompany): FormConfigData[] => {
	return [
		{
			label: "Name",
			currentValue: `${company.name}`,
			inputName: "name",
			type: "text",
		},
		{
			label: "Email",
			currentValue: `${company.email}`,
			inputName: "email",
			type: "email",
		},
		{
			label: "Country",
			currentValue: `${company.country}`,
			inputName: "country",
			type: "text",
		},
		{
			label: "State",
			currentValue: `${company.state}`,
			inputName: "state",
			type: "text",
		},
		{
			label: "City",
			currentValue: `${company.city}`,
			inputName: "city",
			type: "text",
		},
		{
			label: "Address One",
			currentValue: `${company.address_one}`,
			inputName: "address_one",
			type: "text",
		},
		{
			label: "Address Two",
			currentValue: `${company.address_two}`,
			inputName: "address_two",
			type: "text",
		},
		{
			label: "Zip code",
			currentValue: `${company.zip_code}`,
			inputName: "zip_code",
			type: "number",
		},
		{
			label: "Phone number",
			currentValue: `${company.phone_number}`,
			inputName: "phone_number",
			type: "phone",
		},
		{
			label: "Website",
			currentValue: `${company.website}`,
			inputName: "website",
			type: "text",
		},
		{
			label: "Description",
			currentValue: `${company.description}`,
			inputName: "description",
			type: "text",
		},
		{
			label: "Tech Admin name",
			currentValue: `${company.tech_admin_name}`,
			inputName: "tech_admin_name",
			type: "text",
		},
		{
			label: "Tech Admin email",
			currentValue: `${company.tech_admin_email}`,
			inputName: "tech_admin_email",
			type: "email",
		},
		{
			label: "Tech Admin Phone",
			currentValue: `${company.tech_admin_phone}`,
			inputName: "tech_admin_phone",
			type: "phone",
		},
		{
			label: "Billing Admin Name",
			currentValue: `${company.billing_admin_name}`,
			inputName: "billing_admin_name",
			type: "text",
		},
		{
			label: "Billing Admin Email",
			currentValue: `${company.billing_admin_email}`,
			inputName: "billing_admin_email",
			type: "email",
		},
		{
			label: "Billing Admin Phone",
			currentValue: `${company.billing_admin_phone}`,
			inputName: "billing_admin_phone",
			type: "phone",
		},
	];
};

export const billingConfig = (billing: IBillingAccount): FormConfigData[] => {
	return [
		{
			label: "Customer ID",
			currentValue: `${billing.stripe_customer_id}`,
			inputName: "stripe_customer_id",
			type: "text",
			disabled: true,
		},
		{
			label: "Account Status",
			currentValue: `${billing.account_status}`,
			inputName: "account_status",
			type: "text",
			disabled: true,
		},
		{
			label: "Subscription Status",
			currentValue: `${billing.subscription_status}`,
			inputName: "subscription_status",
			type: "text",
			disabled: true,
		},
		{
			label: "Has Payment Method",
			currentValue: `${billing.has_payment_method}`,
			inputName: "has_payment_method",
			values: ["true", "false"],
			type: "radio",
			disabled: true,
		},
		{
			label: "Is delinquent",
			currentValue: `${billing.is_delinquent}`,
			inputName: "is_delinquent",
			values: ["true", "false"],
			type: "radio",
			disabled: true,
		},
	];
};

export const powerUpFormConfig = (user: IUser): FormConfigData[] => {
	return [
		{
			inputName: "powerup_underwriting_report",
			title: "Underwriting Report",
			subtitle: "Generates a comprehensive underwriting report summarizing key findings from the loan file",
			currentValue: `${user.powerup_underwriting_report}`,
		},
		{
			inputName: "powerup_income_worksheet",
			title: "Income Worksheet",
			subtitle: "Automatically creates an income worksheet based on income and employment documents",
			currentValue: `${user.powerup_income_worksheet}`,
		},
		{
			inputName: "powerup_condition_list",
			title: "Condition List",
			subtitle: "Creates an initial condition (PTA & PTC) list based on the analyses of the entire loan file",
			currentValue: `${user.powerup_condition_list}`,
		},
		{
			inputName: "powerup_fraud_report",
			title: "Document Fraud Check",
			subtitle: "Scans all documents against a state of the art fraud model to detect fraudulent documents",
			currentValue: `${user.powerup_fraud_report}`,
		},
		{
			inputName: "powerup_property_analysis",
			title: "Property Analyses",
			subtitle: "Analyses the subject property against a proprietary data set and produces a report",
			currentValue: `${user.powerup_property_analysis}`,
		},
	];
};
