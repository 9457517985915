import { getThemeReducer, useAppSelector } from "../../../store";
import { ModalBodyWrapper, UploadIcon, UploadTextComplete, UploadWrapper } from "../allModals.style";

const ArchiveError = () => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<>
			{/* @ts-ignore */}
			<ModalBodyWrapper sx={{ themeMode }}>
				<UploadWrapper>
					<UploadIcon src="/images/svgs/uploadError.svg" />
				</UploadWrapper>
				<UploadTextComplete width="80%" marginTop="0px">
					Failed to archive file. Please try again later.
				</UploadTextComplete>
			</ModalBodyWrapper>
		</>
	);
};
export { ArchiveError };
