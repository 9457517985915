import { ToolTip } from "../enum/tooltip.enum";
import { ITooltip } from "../interfaces";

export const documentTooltips: ITooltip[] = [
	{
		title: ToolTip.FRAUD_CHECK,
		arrow: true,
		placement: "top-start",
		src: {
			light: "/images/svgs/verified.svg",
			dark: "/images/svgs/verified-dark.svg",
		},
	},
	{
		arrow: true,
		placement: "top-start",
		src: {
			light: "/images/svgs/star.svg",
			dark: "/images/svgs/star-dark.svg",
		},
	},
	{
		title: ToolTip.VIEW,
		arrow: true,
		placement: "top",
		src: {
			light: "/images/svgs/link.svg",
			dark: "/images/svgs/link-dark.svg",
		},
	},
	{
		title: ToolTip.DELETE_DOC,
		arrow: true,
		placement: "top",
		src: {
			light: "/images/svgs/trash.svg",
			dark: "/images/svgs/trash-dark.svg",
		},
	},
];
