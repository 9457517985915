import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { MainWrapper } from "../account/settings.style";

// const MiddlePage = () => {
// 	const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
// 	const navigate = useNavigate();

// 	useEffect(() => {
// 		const handleAuth = async () => {
// 			if (isLoading) return; // Wait until loading finishes

// 			if (!isAuthenticated) {
// 				await loginWithRedirect(); // Redirect back to Auth0 login if not authenticated
// 				return;
// 			}

// 			try {
// 				// Fetch access token and store it for API requests
// 				const token = await getAccessTokenSilently();
// 				localStorage.setItem("auth_token", token);

// 				const redirectTo = window.location.pathname || "/loans";
// 				navigate(redirectTo);
// 			} catch (error) {
// 				console.error("Error handling tokens:", error);
// 			}
// 		};

// 		handleAuth();
// 	}, [isAuthenticated, isLoading, getAccessTokenSilently, navigate]);

// 	return <MainWrapper></MainWrapper>;
// };

// export default MiddlePage;

const MiddlePage = () => {
	const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		const checkAuthentication = async () => {
			if (!isAuthenticated && !isLoading) {
				await loginWithRedirect();
			} else if (isAuthenticated) {
				const redirectTo = window.location.pathname || "/loans";
				navigate(redirectTo);
			}
		};
		checkAuthentication();
	}, [isAuthenticated, isLoading, loginWithRedirect, navigate]);

	return <MainWrapper></MainWrapper>;
};

export default MiddlePage;
