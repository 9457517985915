import ListItem from "@mui/material/ListItem";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const MainBox = styled(Box)(({ theme }) => ({
	padding: "10px",
}));

export const CustomListItem: any = styled(ListItem)(({ theme, active, themeMode }: any) => ({
	padding: "24px 0px",
	cursor: "pointer",
	flexDirection: "column",
	background: `${active ? (themeMode === "light" ? theme.palette.background.default : "#2E3D43") : "transparent"}`,
	borderBottom: `${active ? "3px solid #52BFF9" : "transparent"}`,
}));

export const ArrowIcon = styled("img")(({ theme }) => ({
	height: "24px",
	width: "24px",
}));

export const CustomListItemBox = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	width: "100%",
}));

export const ListHeadingWrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	marginLeft: "30px",
}));

export const ListHeading = styled(Typography)(({ theme }) => ({
	fontSize: "18px",
	fontWeight: 500,
	lineHeight: "24px",
	[theme.breakpoints.down("md")]: {
		fontSize: "14px",
	},
}));
export const ListSubHeading = styled(Typography)(({ theme, themeMode }: any) => ({
	fontSize: "14px",
	marginTop: "4px",
	fontFamily: "Bai Jamjuree",
	color: themeMode === "light" ? theme.palette.secondary.light : theme.palette.secondary.dark,
	[theme.breakpoints.down("md")]: {
		fontSize: "12px",
	},
}));

export const ArrowIconWrapper = styled(Box)(({ theme }) => ({
	width: "15%",
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	marginRight: "30px",
}));
