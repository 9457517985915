import { useContext, useEffect, useState } from "react";
import { useActiveChatList } from "./useActiveChatList";
import { ChatContext, ModalContext } from "../context";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../enum";
import { agencyService } from "../services/agency.service";
import { useChats } from "./useChats";
import { IGuideline } from "../interfaces/guideline.interface";
import { useParams } from "react-router-dom";

export const useGuideline = () => {
	const { getActiveChat } = useActiveChatList();
	const [activeGuideline, setActiveGuideline] = useState<IGuideline>(null);
	const { agencyId } = useParams();
	const { setSelectedOption } = useContext(ModalContext);
	const { isPathnameIsChats } = useChats();
	const activeChat = getActiveChat();
	const { setSelectedGuidelineTitle } = useContext(ChatContext);

	const {
		data: guidelines = [],
		refetch: refetchGuidelines,
		isLoading: isGuidelinesLoading,
		isPending: isGuidelinesPending,
		isFetching: isGuidelinesFetching,
	} = useQuery({
		queryKey: [QueryKey.GUIDELINE, agencyId],
		queryFn: () => agencyService.getGuidelines(agencyId),
		enabled: isPathnameIsChats && agencyId != null,
		staleTime: Infinity,
	});

	useEffect(() => {
		if (activeChat) {
			const activeGuideline = guidelines.find((guideline) => guideline.id === activeChat.guideline_id);
			setActiveGuideline(activeGuideline);
			setSelectedGuidelineTitle(activeGuideline?.display_name || '');
			setSelectedOption(activeGuideline?.id);
		}
	}, [guidelines, agencyId, activeChat]);

	return {
		guidelines,
		refetchGuidelines,
		isGuidelinesFetching,
		isGuidelinesPending,
		isGuidelinesLoading,
		activeGuideline,
	};
};
