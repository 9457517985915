import { urls } from "../constants";
import { ICompany } from "../interfaces";
import { apiService } from "./api.service";

export const companyService = {
	getInfo: async (signal: AbortSignal) => {
		try {
			const { data } = await apiService.get<ICompany>(urls.company, { signal });
			return data;
		} catch (error: any) {
			if (error.response?.status === 404) {
				return null;
			}
			throw error;
		}
	},
	updateCompany: async (updatedFields: Partial<ICompany>) => {
		const { data } = await apiService.put<ICompany>(urls.company, updatedFields);
		return data;
	},
};
