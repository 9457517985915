import { DeleteModalBody, ModalBodyWrapper, TrashIconModal, TrashIconModalWrapper, DeleteModalHeading, SubheadingWrapper, SubheadingInner, DeleteModalSubHeading } from "../allModals.style";
import { getThemeReducer, useAppSelector } from "../../../store";

const DeleteModal = () => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<ModalBodyWrapper sx={{ themeMode, paddingBlock: "42px 24px" }}>
			{/* @ts-ignore */}
			<TrashIconModalWrapper sx={{ themeMode }}>
				<TrashIconModal alt="Modal Trash" src="/images/svgs/trash-modal.svg" />
			</TrashIconModalWrapper>
			<DeleteModalBody>
				<DeleteModalHeading>Sure you want to delete this item?</DeleteModalHeading>
			</DeleteModalBody>
			<SubheadingWrapper>
				<SubheadingInner>
					<DeleteModalSubHeading>This action will delete the item and it cannot be retrieved later.</DeleteModalSubHeading>
				</SubheadingInner>
			</SubheadingWrapper>
		</ModalBodyWrapper>
	);
};

export { DeleteModal };
