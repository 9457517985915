import { useCallback } from "react";
import { useMessages } from "./useMessages";
import { useSuggestions } from "./useSuggestions";
import { useQueryClientInteraction } from "./useQueryClientInteraction";
import { QueryKey } from "../enum";
import { useActiveList } from "./useActiveList";
import { IChatTimeline } from "../interfaces";
import { useText } from "./useText";
import { useActiveChatList } from "./useActiveChatList";
import { useLoans } from "./useLoans";
import { useChatMessages } from "./useChatMessages";

export const useTypewriter = () => {
	const { refetchMessages } = useMessages();
	const { refetchMessages: refetchChatMessages } = useChatMessages();
	const { refetchSuggestions } = useSuggestions();
	const { updateLastItemInInfinitiveQueryCache } = useQueryClientInteraction();
	const { getActiveLoan } = useActiveList();
	const { getActiveChat } = useActiveChatList();
	const { isPathnameIsLoans } = useLoans();
	const { parseMarkdown } = useText();

	const refetchEntities = useCallback(() => {
		if (isPathnameIsLoans) {
			refetchMessages();
			refetchSuggestions();
		} else {
			refetchChatMessages();
		}
	}, []);

	const updateQueryCache = useCallback(() => {
		updateLastItemInInfinitiveQueryCache<IChatTimeline[]>([QueryKey.MESSAGES, isPathnameIsLoans ? getActiveLoan().id : getActiveChat().id], "isAnimationFinished", true);
	}, []);

	const parseMarkdownHandler = useCallback((text: string) => {
		return parseMarkdown(text);
	}, []);

	return {
		refetchEntities,
		updateQueryCache,
		parseMarkdownHandler,
	};
};
