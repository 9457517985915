import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { FC } from "react";
import { getThemeReducer, useAppSelector } from "../../../../../store";
import { FeedbackIconWrapperComponent, SourceBtnWrapperComponent } from "../../../../../pages/styles/home.style";

interface ISourceBtnWrapperProps {
	columnNumber: number;
	children: string;
	onClick: () => void;
}

const SourceBtnWrapper: FC<ISourceBtnWrapperProps> = ({ columnNumber, children, onClick }) => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<Grid2 xs={columnNumber} onClick={onClick}>
			<SourceBtnWrapperComponent sx={{ themeMode }}>{children}</SourceBtnWrapperComponent>
		</Grid2>
	);
};

export { SourceBtnWrapper };
