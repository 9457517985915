import React, { useRef, useEffect, useContext, MouseEventHandler, useState } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
	MainWrapper,
	InnerSideBarWrapper,
	HomePageBg,
	HomePageRightNav,
	PaperWrapper,
	InputBaseWrapper,
	VersionBox,
	VersionText,
	SendBtn,
	VersionContainer,
	DownArrow,
	Container,
	SuggestionBtnWrapper,
	SummaryBtn,
	MsgDownArrowBox,
	MainMessagesWrapper,
	ChatWrapper,
	SuggestionContainer,
} from "../styles/home.style";
import MobileNav from "../../layouts/innerSideBar/innerSideBarMobile";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CustomMobileHeader } from "../../components/common/MobileHeader/MobileHeader";
import { MobileHeaderNav } from "../../components/common/MobileHeaderNav/MobileHeaderNav";
import { useSuggestions } from "../../hook/useSuggestions";
import { useMessages } from "../../hook/useMessages";
import { ActiveListContext, ChatContext, ModalContext } from "../../context";
import { useChats } from "../../hook/useChats";
import { useNavigate, useParams } from "react-router-dom";
import { ModalWrapper } from "../../components/allModals/upload/ModalWrapper";
import { useWrapper } from "../../hook/useWrapper";
import { AudioRecorder } from "../../components/common/AudioRecorder/AudioRecorder";
import { Loader } from "../../components/common/Loader/Loader";
import { getCollapseReducer, getMobileReducer, getThemeReducer, mobileActions, useAppDispatch, useAppSelector } from "../../store";
import InnerSideChatNav from "../../layouts/innerSideBar/innerSideChatBar";
import { ChatMessages } from "../../components/common/ChatMessages/Messages";
import { useChatMessages } from "../../hook/useChatMessages";
import RightNavChatBar from "../../layouts/rightSideBar/rightSideChatBar";
import { ChatMessagesMobile } from "../../components/common/MessagesMobile/ChatMessagesMobile/ChatMessagesMobile";

const Chat = () => {
	const messageRefMobile = useRef<HTMLTextAreaElement>();
	const messageRef = useRef<HTMLTextAreaElement>();
	const { suggestions } = useSuggestions();
	const { sendMessageHandler, isSendingButtonDisabled, isSendingMessagePending } = useChatMessages();
	const { activeList } = useContext(ActiveListContext);
	const { isModalOpen, modalKey } = useContext(ModalContext);
	const { isPathnameIsChats, isChatsLoading } = useChats();
	const navigate = useNavigate();
	const { setTriggerNewMessage, speechTranslation, isTypingEffectInProgress } = useContext(ChatContext);
	const { scrollDown } = useWrapper();
	const [shouldAddBr, setShouldAddBr] = useState<boolean>(false);
	const [shouldMakeSendAnimationTextSmaller, setShouldMakeSendAnimationTextSmaller] = useState<boolean>(false);
	const [changeLoaderWidth, setChangeLoaderWidth] = useState<boolean>(false);
	const { themeMode } = useAppSelector(getThemeReducer);
	const { collapsed } = useAppSelector(getCollapseReducer);
	const { isMobile, showMessages } = useAppSelector(getMobileReducer);
	const { setIsMobile } = mobileActions;
	const dispatch = useAppDispatch();
	const { sortedMessages } = useChatMessages();
	const { agencyId } = useParams();

	useEffect(() => {
		const handleResize = () => {
			setShouldAddBr((window.innerWidth < 785 && window.innerWidth > 600) || window.innerWidth < 530);
			setShouldMakeSendAnimationTextSmaller(window.innerWidth < 1200);
			setChangeLoaderWidth(window.innerWidth < 900);
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth < 600));
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		const ref = window.innerWidth < 600 ? messageRefMobile : messageRef;

		const shouldSendMessageWithEnter = (e: KeyboardEvent): boolean =>
			e.key === "Enter" && !isSendingButtonDisabled && !isModalOpen && ref.current.value.trim() !== "" && !e.shiftKey && !isTypingEffectInProgress;

		const handleKeyUp = (e: KeyboardEvent) => {
			if (shouldSendMessageWithEnter(e)) {
				e.preventDefault();
				sendMessageHandler(ref);
			}
		};
		window.addEventListener("keydown", handleKeyUp);
		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("keydown", handleKeyUp);
		};
	}, [setIsMobile, sendMessageHandler]);

	useEffect(() => {
		if (activeList) {
			isPathnameIsChats && !isChatsLoading && navigate(`/chats/${agencyId + "/" + activeList}`);
		}
	}, [activeList, navigate, isPathnameIsChats, isChatsLoading]);

	useEffect(() => {
		const ref = isMobile ? messageRefMobile : messageRef;
		if (ref && ref?.current) {
			ref.current.value = speechTranslation;
		}
	}, [speechTranslation, isMobile, messageRef, messageRefMobile]);

	const handleResize = (): void => {
		if (messageRef.current.scrollHeight > messageRef.current.clientHeight) {
			messageRef.current.style.height = messageRef.current.scrollHeight + "px";
		} else {
			messageRef.current.style.height = 0 + "px";
			messageRef.current.style.height = messageRef.current.scrollHeight + "px";
		}
	};

	const clickSuggestionHandler: MouseEventHandler = (e): void => {
		const target = e.target as HTMLButtonElement;
		messageRef.current.value = target.innerText;
		setTriggerNewMessage((prev) => !prev);
	};

	return (
		<MainWrapper>
			{!isMobile ? (
				<InnerSideBarWrapper>
					<InnerSideChatNav />
				</InnerSideBarWrapper>
			) : (
				<>{!showMessages && <MobileNav />}</>
			)}

			{!isMobile && (
				<>
					{/* @ts-ignore */}
					<HomePageBg themeMode={themeMode} collapsed={collapsed}>
						<ChatWrapper>
							<MainMessagesWrapper>
								<ChatMessages />
							</MainMessagesWrapper>

							{/* Text Field with Icon and button */}
							<VersionContainer is_messages_exist={sortedMessages.length !== 0}>
								{suggestions && suggestions.length > 0 && (
									<SuggestionContainer>
										<SuggestionBtnWrapper sx={{ themeMode }}>
											{suggestions.map(({ suggestion }, index) => (
												<SummaryBtn onClick={clickSuggestionHandler} sx={{ themeMode }} index={index}>
													{suggestion}
												</SummaryBtn>
											))}
										</SuggestionBtnWrapper>
										<Box sx={{ width: "65px", heigh: "65px" }}></Box>
									</SuggestionContainer>
								)}
								<Container sx={{ marginTop: "10px" }}>
									<PaperWrapper sx={{ themeMode }}>
										<AudioRecorder />
										<InputBaseWrapper onResize={handleResize} ref={messageRef} placeholder="Write Something" />
										<IconButton color="primary" aria-label="directions" onClick={() => sendMessageHandler(messageRef)}>
											<SendBtn is_sending_message_pending={isSendingMessagePending} disabled={isSendingButtonDisabled || isTypingEffectInProgress} sx={{ themeMode }}>
												{isSendingMessagePending ? (
													<Loader
														color="sending"
														animatedTextWidth={changeLoaderWidth ? "45px" : shouldMakeSendAnimationTextSmaller ? "50px" : "60px"}
														loadingText="Sending"
													/>
												) : (
													"Send"
												)}
											</SendBtn>
										</IconButton>
									</PaperWrapper>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{/* @ts-ignore */}
										<MsgDownArrowBox onClick={() => scrollDown("smooth" as ScrollBehavior)} sx={{ themeMode }}>
											<DownArrow src={`${themeMode === "light" ? "/images/svgs/arrowDownLight.svg" : "/images/svgs/arrowDown.svg"}`} />
										</MsgDownArrowBox>
									</Box>
								</Container>

								{/* Version Text Box */}
								<VersionBox>
									<VersionText>Power Underwriter™ version 1.0. {shouldAddBr && <br />} Use of this system is governed by our privacy policy and terms of use.</VersionText>
								</VersionBox>
							</VersionContainer>
						</ChatWrapper>
					</HomePageBg>
					<HomePageRightNav>
						<RightNavChatBar />
					</HomePageRightNav>
				</>
			)}

			{isMobile && showMessages && (
				<>
					{/* New Header Started Here */}
					<CustomMobileHeader />
					<MobileHeaderNav />

					{/* Ended Here */}
					<HomePageBg themeMode={themeMode}>
						<ChatMessagesMobile />
						{/* Text Field with Icon and button */}
						<Box>
							<PaperWrapper sx={{ themeMode }}>
								<AudioRecorder />
								<InputBaseWrapper ref={messageRefMobile} placeholder="Write Something" />
								<IconButton color="primary" aria-label="directions" onClick={() => sendMessageHandler(messageRefMobile)}>
									<SendBtn disabled={isSendingButtonDisabled || isTypingEffectInProgress} sx={{ themeMode }} is_sending_message_pending={isSendingMessagePending}>
										{isSendingMessagePending ? <Loader color="sending" animatedTextWidth="45px" loadingText="Sending" /> : "Send"}
									</SendBtn>
								</IconButton>
							</PaperWrapper>
							{/* Version Text Box */}
							<VersionBox>
								<VersionText>Power Underwriter version 2.23. {shouldAddBr && <br />} Use of this system is governed by our privacy policy and terms of use.</VersionText>
							</VersionBox>
						</Box>
					</HomePageBg>
					<RightNavChatBar />
				</>
			)}
			<ModalWrapper width="552px" open={isModalOpen && ["like", "dislike"].includes(modalKey)} />
		</MainWrapper>
	);
};

export default withAuthenticationRequired(Chat);
