import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import { IDocument } from "../interfaces";
import { ISection } from "../interfaces/section.interface";

interface IRightNavBarContext {
	changedDocuments: IDocument[];
	setChangedDocuments: Dispatch<SetStateAction<IDocument[]>>;
	responseMessage: string;
	setResponseMessage: Dispatch<SetStateAction<string>>;
	changedSections: ISection[];
	setChangedSections: Dispatch<SetStateAction<ISection[]>>;
}

export const RightNavbarContext = createContext<IRightNavBarContext>(null);

export const RightNavBarProvider = ({ children }: PropsWithChildren) => {
	const [changedDocuments, setChangedDocuments] = useState<IDocument[]>([]);
	const [responseMessage, setResponseMessage] = useState<string>("");
	const [changedSections, setChangedSections] = useState<ISection[]>([]);

	const value = {
		changedDocuments,
		setChangedDocuments,
		responseMessage,
		setResponseMessage,
		changedSections,
		setChangedSections,
	};

	return <RightNavbarContext.Provider value={value}>{children}</RightNavbarContext.Provider>;
};
