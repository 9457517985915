import { useLocation, useNavigate, useParams } from "react-router-dom";
import { headerButtons as originalHeaderButtons } from "../../../constants/headerButtons";
import { ButtonComponent, ButtonInnerComponent, HeaderButton, PlusBtn, PlusBtnIcon } from "../../../layouts/header/header.style";
import { FC, memo, useEffect, useRef, useState } from "react";
import { getThemeReducer, useAppSelector } from "../../../store";
import { agencyService } from "../../../services/agency.service";
import { useAgency } from "../../../hook/useAgency";
import { IAgency } from "../../../interfaces/agency.interface";
import { IHeaderButton } from "../../../interfaces/headerButtons.interface";

interface IHeaderAgencyButton extends IAgency {
	url: string;
}

interface IHeaderButtonsProps {
	handleopenModal: () => void;
}

const HeaderButtons: FC<IHeaderButtonsProps> = memo(({ handleopenModal }) => {
	const { pathname } = useLocation();
	const { themeMode } = useAppSelector(getThemeReducer);
	const navigate = useNavigate();
	const loanRef = useRef<HTMLButtonElement>(null);
	const [headerButtons, setHeaderButtons] = useState<(IHeaderAgencyButton | IHeaderButton)[]>([]);
	const { topAgenciesData, isTopAgenciesLoading } = useAgency();
	const { agencyId, loanId } = useParams();

	useEffect(() => {
		const updatedButtons =
			topAgenciesData?.map((agency) => {
				return {
					...agency,
					url: `/chats/${agency.id}/`,
				};
			}) || [];

		setHeaderButtons([...originalHeaderButtons, ...updatedButtons]);
	}, [topAgenciesData]);

	function handleRedirect(button: IHeaderAgencyButton | IHeaderButton) {
		const id = button.url.split("/")[2];
		if (agencyId !== id) {
			navigate(button.url);
		}
	}

	return (
		<ButtonComponent>
			<ButtonInnerComponent>
				{headerButtons.map((button) => {
					return (
						<HeaderButton key={button.name} active={pathname.includes(button.url)} themeMode={themeMode} onClick={() => handleRedirect(button)} disabled={isTopAgenciesLoading}>
							{"abbreviation" in button ? button.abbreviation : button.name}
						</HeaderButton>
					);
				})}
				<PlusBtnIcon onClick={handleopenModal}>
					<PlusBtn src="/images/svgs/plus.svg" />
				</PlusBtnIcon>
			</ButtonInnerComponent>
		</ButtonComponent>
	);
});

export { HeaderButtons };
