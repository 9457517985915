import { Box } from "@mui/material";
import { LoaderWrapper } from "./Loader.styles";
import { FC, useEffect, useState } from "react";
import { getThemeReducer, useAppSelector } from "../../../store";

export interface ILoaderProps {
	justifyContent?: string;
	marginLeft?: string;
	color?: string;
	animatedTextWidth?: string;
	loadingText?: string;
	marginTop?: string;
}
const Loader: FC<ILoaderProps> = ({ justifyContent, marginLeft, color, animatedTextWidth, loadingText, marginTop }) => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const [dots, setDots] = useState<number>(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setDots((prev) => (prev === 3 ? 0 : prev + 1));
		}, 300);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<LoaderWrapper justifyContent={justifyContent}>
			<Box
				sx={{
					marginLeft,
					marginTop,
					fontFamily: "DM Sans",
					width: "100%",
					display: "flex",
					justifyContent: "center",
				}}
				color={
					color === "inherit" && themeMode === "dark"
						? "white"
						: color === "inherit" && themeMode === "light"
						? "white"
						: color === "sending"
						? "white"
						: themeMode === "dark"
						? "#ffffffb0"
						: "#03363da2"
				}
			>
				<Box sx={{ width: animatedTextWidth || "70px" }}>
					{loadingText || "Loading"}
					{".".repeat(dots)}
				</Box>
			</Box>
		</LoaderWrapper>
	);
};

export { Loader };
