import { createContext, Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, useRef, useState } from "react";
import { CurrentStep, ModalKey } from "../interfaces";

interface IModalContext {
	selectedFile: File;
	setSelectedFile: Dispatch<SetStateAction<File>>;
	selectedOption: string;
	setSelectedOption: Dispatch<SetStateAction<string>>;
	inputRef: MutableRefObject<HTMLInputElement>;
	setInputRef: Dispatch<SetStateAction<MutableRefObject<HTMLInputElement>>>;
	step: CurrentStep;
	setStep: Dispatch<SetStateAction<CurrentStep>>;
	isModalOpen: boolean;
	setIsModalOpen: Dispatch<SetStateAction<boolean>>;
	modalKey: ModalKey;
	setModalKey: Dispatch<SetStateAction<ModalKey>>;
	openModal: (modalKey: ModalKey) => void;
	closeModal: () => void;
	submitRef: MutableRefObject<HTMLButtonElement>;
	document_id: string;
	setDocumentId: Dispatch<SetStateAction<string>>;
	message: string;
	setMessage: Dispatch<SetStateAction<string>>;
}

export const ModalContext = createContext<IModalContext>(null);

export const ModalProvider = ({ children }: PropsWithChildren) => {
	const [selectedFile, setSelectedFile] = useState<File>(null);
	const [selectedOption, setSelectedOption] = useState<string>(null);
	const [inputRef, setInputRef] = useState<MutableRefObject<HTMLInputElement>>(null);
	const [step, setStep] = useState<CurrentStep>(CurrentStep.STEP1);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [modalKey, setModalKey] = useState<ModalKey>(null);
	const submitRef = useRef<HTMLButtonElement>(null);
	const [document_id, setDocumentId] = useState<string>(null);
	const [message, setMessage] = useState<string>("");

	const openModal = (modalKey: ModalKey): void => {
		setIsModalOpen(true);
		setModalKey(modalKey);
	};

	const closeModal = (): void => {
		setModalKey(null);
		setIsModalOpen(false);
		setSelectedFile(null);
		setDocumentId(null);
		setSelectedOption(null);
		setMessage("");
		setStep(CurrentStep.STEP1);
	};

	const value = {
		selectedOption,
		setSelectedOption,
		selectedFile,
		setSelectedFile,
		inputRef,
		setInputRef,
		step,
		setStep,
		isModalOpen,
		setIsModalOpen,
		modalKey,
		setModalKey,
		openModal,
		closeModal,
		submitRef,
		document_id,
		setDocumentId,
		message,
		setMessage,
	};

	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
