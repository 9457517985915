import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthInject } from "./components/Auth/AuthInject";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Auth0Provider
		domain={`${process.env.REACT_APP_PROD_AUTH0_DOMAIN}`}
		clientId={`${process.env.REACT_APP_PROD_AUTH0_CLIENT_ID}`}
		authorizationParams={{
			redirect_uri: `${window.location.origin}/middle`,
			audience: process.env.REACT_APP_AUDIENCE,
			scope: "openid offline_access profile email",
		}}
		useRefreshTokens={true}
	>
		<AuthInject />
		<App />
	</Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
