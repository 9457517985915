export const dark = {
	palette: {
		primary: {
			main: "#FE6A57",
			light: "#FE6A57",
			dark: "#03363D",
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#FFFFFF",
			disabled: "#FFFFFF",
			opposite: "#000",
			white: "#fff",
		},
		background: {
			default: "#12252D",
			paper: "#1C2C33",
		},
		secondary: {
			main: "#7D8A8F",
			dark: "#7D8A8F",
		},
	},
};
