import { useNavigate, useParams } from "react-router-dom";
import { settingsDummyData } from "../dummyData";
import { ISetting } from "../interfaces";
import { useContext, useState } from "react";
import { ActiveListContext } from "../context";
import { useLogout } from "./useLogout";
import { useBilling } from "./useBilling";
import { useChats } from "./useChats";
import { IChat } from "../interfaces/chat.interface";

export const useActiveChatList = () => {
	const [isHover, setIsHover] = useState<boolean>(null);
	const { activeList, setActiveList } = useContext(ActiveListContext);
	const { agencyId } = useParams();
	const { sortedChats, isChatsLoading, isPathnameIsChats } = useChats({ agency_id: agencyId });
	const navigate = useNavigate();
	const { logoutHandler } = useLogout();

	const activeListField = isPathnameIsChats ? "id" : "title";

	const listItems: IChat[] = sortedChats;

	const updateActiveList = (): void => {
		if (!isPathnameIsChats) {
			setActiveList(listItems[0]?.title);
			return;
		}
		if (!isChatsLoading) {
			if (!sortedChats.length) {
				setActiveList("");
				return;
			} else {
				const chatsList = (listItems[0] as IChat).id.toString();
				setActiveList(chatsList);
			}
		}
	};

	const getActiveChat = (): IChat => {
		return isPathnameIsChats && sortedChats ? sortedChats.find((chat) => String(chat.id) === activeList) : null;
	};

	const onHoverHandler = () => {
		setIsHover(true);
	};
	const onMouseLeave = () => {
		setIsHover(false);
	};

	const isActiveItem = (item: any) => activeList === String(item[activeListField]);

	const toggleActiveItemByClick = (item: any) => {
		if (item[activeListField] === "Logout") {
			logoutHandler();
			return;
		}

		setActiveList(String(item[activeListField]));
		if (item.url) {
			navigate(item.url);
		}
	};

	return {
		listItems,
		updateActiveList,
		getActiveChat,
		onHoverHandler,
		onMouseLeave,
		isHover,
		isActiveItem,
		toggleActiveItemByClick,
		activeList,
		setActiveList,
	};
};
