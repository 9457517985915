import { urls } from "../constants";
import { ISuggestion } from "../interfaces";
import { apiService } from "./api.service";

export const suggestionsService = {
	getByLoanId: async (loanId: string, signal: AbortSignal) => {
		const { data } = await apiService.get<ISuggestion[]>(urls.suggestions(loanId), { signal });
		return data;
	},
};
