import { urls } from "../constants";
import { IBulkUpdateResponse } from "../interfaces";
import { IChat, ICreateChat } from "../interfaces/chat.interface";
import { ISection, ISectionBulk } from "../interfaces/section.interface";
import { IChatParams } from "../interfaces/params/chatParams.interface";
import { apiService } from "./api.service";

export const chatService = {
	getPage: async (params?: IChatParams) => {
		const { data } = await apiService.get<IChat[]>(urls.chats, { params });
		return data;
	},

	getSections: async (chatId: string) => {
		const { data } = await apiService.get<ISection[]>(`${urls.chatById(chatId)}/sections`);
		return data;
	},

	createNewChat: async (createChatPayload: ICreateChat) => {
		const { data } = await apiService.post<IChat>(`${urls.chats}`, createChatPayload);
		return data;
	},

	updateChat: async (chatId: string, props: Partial<IChat>) => {
		const { data } = await apiService.put<IChat>(`${urls.chatById(chatId)}`, { ...props });
		return data;
	},

	bulkUpdate: async (chatId: string, guidelines: ISectionBulk[]) => {
		const { data } = await apiService.put<IBulkUpdateResponse>(`${urls.chatById(chatId)}/bulk_update`, guidelines);
		return data;
	},
};
