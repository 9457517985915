import { useContext, useEffect, useRef, useState } from "react";
import { useMessages } from "../../../../hook/useMessages";
import { MsgBoxWrapper } from "../../../../pages/styles/home.style";
import { useAuth0 } from "@auth0/auth0-react";
import { MainMessagesMobileWrapper } from "../messagesMobile.styles";
import { ActiveListContext, ChatContext, ModalContext } from "../../../../context";
import "../../Messages/Message/message.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../Loader/Loader";
import { useWrapper } from "../../../../hook/useWrapper";
import { useModal } from "../../../../hook/useModal";
import { useEdit } from "../../../../hook/useEdit";
import { ToolTip } from "../../../../enum";
import { getThemeReducer, useAppSelector } from "../../../../store";
import { useChatMessages } from "../../../../hook/useChatMessages";
import { ChatMessageMobile } from "./ChatMessageMobile";
import { useActiveChatList } from "../../../../hook/useActiveChatList";
import { useChats } from "../../../../hook/useChats";
import { useParams } from "react-router-dom";
import { SourceModal } from "../../../allModals/source/SourceModal";

const ChatMessagesMobile = () => {
	const { sortedMessages, isMessagesPending, shouldUseTypingEffect, fetchNextMessagesPage, hasMessagesNextPage, isMessagesFetchingNextPage } = useChatMessages();
	const { user } = useAuth0();
	const msgBoxWrapper = useRef<HTMLDivElement>();
	const { setMsgWrapperMobile } = useContext(ChatContext);
	const { scrollDown } = useWrapper();
	const { openModalFeedback } = useModal();
	const { handleIsTypingEffectInProgress } = useChatMessages();
	const { insertLink } = useEdit();
	const { activeEntity } = useContext(ActiveListContext);
	const { themeMode } = useAppSelector(getThemeReducer);
	const [shouldAutoFetch, setShouldAutoFetch] = useState<boolean>(false);
	const { listItems, updateActiveList, activeList } = useActiveChatList();
	const { chatId, agencyId } = useParams();
	const { sortedChats, isChatsLoading, hasChatsNextPage, fetchChatsNextPage, isPathnameIsChats, isChatsFetchingNextPage } = useChats({ agency_id: agencyId });
	const { isModalOpen, modalKey } = useContext(ModalContext);
	const { lastTriggeredCitation, lastTriggeredMessageId } = useContext(ChatContext);

	useEffect(() => {
		if (msgBoxWrapper) {
			if (msgBoxWrapper?.current?.scrollHeight === msgBoxWrapper?.current?.clientHeight && hasMessagesNextPage) {
				setShouldAutoFetch(true);
				fetchNextMessagesPage();
			} else {
				setShouldAutoFetch(false);
			}
		}
	}, [msgBoxWrapper, sortedMessages, hasMessagesNextPage, fetchNextMessagesPage]);

	useEffect(() => {
		setMsgWrapperMobile(msgBoxWrapper);
	}, [setMsgWrapperMobile]);

	useEffect(() => {
		if (!isChatsLoading && isPathnameIsChats) {
			const activeChatByPathname = sortedChats.find((chat) => chat.id === chatId);
			if (!activeChatByPathname) {
				updateActiveList();
			}
		}
	}, [isChatsLoading, isPathnameIsChats, sortedChats]);

	return (
		<>
			<MainMessagesMobileWrapper>
				{isMessagesPending ? (
					<Loader />
				) : (
					<MsgBoxWrapper sx={{ themeMode }} id="msgWrapperMobile" ref={msgBoxWrapper}>
						{activeEntity?.tooltipTitle === ToolTip.REFRESH_CHAT && <Loader />}
						<InfiniteScroll
							dataLength={sortedMessages.length}
							next={fetchNextMessagesPage}
							inverse={true}
							loader={<Loader />}
							style={{ display: "flex", flexDirection: "column-reverse" }}
							hasMore={hasMessagesNextPage}
							scrollableTarget="msgWrapperMobile"
						>
							{sortedMessages &&
								sortedMessages.map((item, index: number) => (
									<ChatMessageMobile
										key={item.id}
										item={item}
										user={user}
										scrollDown={scrollDown}
										shouldUseTypingEffect={shouldUseTypingEffect(index)}
										handleIsTypingEffectInProgress={handleIsTypingEffectInProgress}
										openModalFeedback={openModalFeedback}
										insertLink={insertLink}
									/>
								))}
						</InfiniteScroll>
						{shouldAutoFetch && isMessagesFetchingNextPage && <Loader />}
					</MsgBoxWrapper>
				)}
			</MainMessagesMobileWrapper>
			{isModalOpen && lastTriggeredCitation && (
				<SourceModal
					open={isModalOpen && modalKey === "source"}
					triggeredCitation={lastTriggeredCitation.citation}
					sourceIndex={lastTriggeredCitation.index}
					messageId={lastTriggeredMessageId}
				/>
			)}
		</>
	);
};

export { ChatMessagesMobile };
