import { useState } from "react";
import { getThemeReducer, themeActions, useAppDispatch, useAppSelector } from "../store";

export const useTheme = () => {
	const dispatch = useAppDispatch();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { setTheme, toggleTheme } = themeActions;
	const [changeThemeANdFavicon, setChangeThemeANdFavicon] = useState<boolean>(true);

	const changeFavicon = (themeMode: "light" | "dark") => {
		const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
		favicon.href = `${window.location.origin}/icons/${themeMode}-favicon.ico?v=${new Date().getTime()}`;
	};

	const onToggleMode = () => {
		dispatch(toggleTheme());
		changeFavicon(themeMode === "dark" ? "light" : "dark");
	};

	const changeThemeModeAndFavicon = (themeMode: "light" | "dark") => {
		dispatch(setTheme(themeMode));
		changeFavicon(themeMode);
	};

	return {
		onToggleMode,
		changeThemeModeAndFavicon,
		changeFavicon,
	};
};
