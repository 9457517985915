import { urls } from "../constants";
import { ILoanParams } from "../interfaces";
import { IAgency } from "../interfaces/agency.interface";
import { IGuideline, IGuidelineView } from "../interfaces/guideline.interface";
import { apiService } from "./api.service";

export const agencyService = {
	getGuidelines: async (agencyId: string, params?: ILoanParams) => {
		const { data } = await apiService.get<IGuideline[]>(`${urls.agencies}${agencyId}/guidelines/`, { params });

		return data.sort((a, b) => a.order_index - b.order_index);
	},

	getAgencies: async (): Promise<IAgency[]> => {
		const { data } = await apiService.get<IAgency[]>(`${urls.agencies}`);
		return data;
	},

	getGuidelineView: async (agencyId: string, guidelineId: string): Promise<IGuidelineView> => {
		const { data } = await apiService.get<IGuidelineView>(`${urls.agencies}${agencyId}/guidelines/${guidelineId}/view`);
		return data;
	},

	getGuidelineAgencyView: async (agencyId: string, guidelineId: string, sectionId: string) => {
		const { data } = await apiService.get<any>(`${urls.agencies}${agencyId}/guidelines/${guidelineId}/sections/${sectionId}/view`);
		return data;
	},

	setViewInteraction: async (agencyId: string) => {
		const { data } = await apiService.get<{ message: string }>(`${urls.agencies}view/${agencyId}`);
		return data;
	},
};
