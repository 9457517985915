import { Box, Typography } from "@mui/material";
import { useActiveList } from "../../../../hook/useActiveList";
import { useActiveChatList } from "../../../../hook/useActiveChatList";

const MobileHeaderInfo = () => {
	const { getActiveLoan } = useActiveList();
	const { getActiveChat } = useActiveChatList();

	const activeLoan = getActiveLoan();
	const activeChat = getActiveChat();

	if (!activeChat && !activeLoan) {
		return <></>;
	}

	return (
		<>
			{activeChat ? (
				<Box
					sx={{
						height: "84px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						sx={{
							fontSize: "16px",
							fontFamily: "Roboto Slab",
							fontWeight: 500,
							lineHeight: "22.4px",
						}}
					>
						{activeChat.title}
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							fontFamily: "Bai Jamjuree",
							fontWeight: 700,
							lineHeight: "14.4px",
							opacity: "0.8",
						}}
					>
						{activeChat.subtitle}
					</Typography>
				</Box>
			) : (
				<Box
					sx={{
						height: "84px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						sx={{
							fontSize: "16px",
							fontFamily: "Roboto Slab",
							fontWeight: 500,
							lineHeight: "22.4px",
						}}
					>
						{activeLoan.file_name}
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							fontFamily: "Bai Jamjuree",
							fontWeight: 700,
							lineHeight: "14.4px",
							opacity: "0.8",
						}}
					>
						{activeLoan.loan_number}
					</Typography>
				</Box>
			)}
		</>
	);
};

export { MobileHeaderInfo };
