import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { IUser } from "../../../../interfaces";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { PowerUpFormItemWrapper, PowerUpItemSubTitle, PowerUpItemTitle } from "./PowerUpFormItem.styles";
import { FormConfigData } from "../../../../pages/account/formConfigs";
import { OptionalEntity } from "../../../../hook/useConfig";

interface IPowerUpFormItemProps {
	item: FormConfigData;
	control: Control<Partial<OptionalEntity>, any>;
}

const PowerUpFormItem: FC<IPowerUpFormItemProps> = ({ item, control }) => {
	return (
		<PowerUpFormItemWrapper>
			<Controller
				name={item.inputName}
				control={control}
				render={({ field }) => (
					<FormControlLabel
						control={
							<Switch
								checked={field.value === "true"}
								onChange={(e) => {
									field.onChange(e.target.checked ? "true" : "false");
								}}
								sx={{
									marginTop: "-3px",
									"& .Mui-checked .MuiSwitch-thumb": {
										backgroundColor: "#FE6A57",
									},
									"& .Mui-checked .MuiSwitch-track": {
										backgroundColor: "#FE6A57",
									},
								}}
							/>
						}
						label=""
					/>
				)}
			/>
			<Box>
				<PowerUpItemTitle>{item.title}</PowerUpItemTitle>
				<PowerUpItemSubTitle>{item.subtitle}</PowerUpItemSubTitle>
			</Box>
		</PowerUpFormItemWrapper>
	);
};

export { PowerUpFormItem };
