import { styled, alpha } from "@mui/system";
import { Box, Typography, ListItem, Button, List } from "@mui/material";
import Divider from "@mui/material/Divider";
import { IStyle } from "../../interfaces";
import ReactMarkdown from "react-markdown";

type IStyleWithActive = { theme?: any; active: boolean };

export const InnerSideBarWrapper = styled(Box)<any>(({ theme, is_pathname_is_loans, sx: { themeMode } }: IStyle) => ({
	height: "calc(100vh - 132px)",
	overflow: "auto",
	width: "330px",
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "16px 0px",
	borderRight: `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
	[theme.breakpoints.down("lg")]: {
		width: `${is_pathname_is_loans ? "260px" : "185px"}`,
		height: "calc(100vh - 80px)",
	},
	[theme.breakpoints.down("md")]: {
		width: `${is_pathname_is_loans ? "185px" : "145px"}`,
	},
	[theme.breakpoints.down("sm")]: {
		width: `${is_pathname_is_loans ? "100%" : "0px"}`,
		height: "calc(100vh - 208px)",
	},
}));

export const ButtonWrapper = styled(Button)(({ theme }) => ({
	height: "56px",
	width: "292px",
	background: theme.palette.primary.light,
	borderRadius: "10px",
	fontSize: "20px",
	fontWeight: 500,
	fontFamily: "'Exo 2'",
	textTransform: "capitalize",
	lineHeight: "28px",
	display: "flex",
	gap: "6px",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "13px 15px",
	color: "#fff",
	"& .MuiButton-startIcon": {
		paddingTop: "2px",
		marginLeft: 0,
	},
	"&:hover": {
		background: theme.palette.primary.main,
	},
	[theme.breakpoints.down("lg")]: {
		height: "42px",
		fontSize: "18px",
		maxWidth: "90%",
		gap: "2px",
	},
	[theme.breakpoints.down("md")]: {
		height: "36px",
		fontSize: "16px",
		lineHeight: "18px",
		padding: "10px 12px",
		gap: 0,
	},
	[theme.breakpoints.down("sm")]: {
		height: "36px",
		width: "150px",
		fontSize: "16px",
		lineHeight: "18px",
		padding: "10px 12px",
		gap: 0,
	},
}));

export const PlusIcon = styled("img")(({ theme }) => ({
	height: "24px",
	width: "24px",
	[theme.breakpoints.down("md")]: {
		height: "16px",
		width: "16px",
	},
	[theme.breakpoints.down("sm")]: {
		height: "15px",
		width: "15px",
	},
}));

export const InnerSideBarButton = styled(ListItem)<any>(({ theme, is_pathname_is_loans }: any) => ({
	width: "292px",
	background: theme.palette.background.paper,
	cursor: "pointer",
	paddingInline: "10px",
	[theme.breakpoints.down("lg")]: {
		width: `${is_pathname_is_loans ? "250px" : "178px"}`,
		padding: "0px 10px",
	},
	[theme.breakpoints.down("md")]: {
		width: `${is_pathname_is_loans ? "180px" : "140px"}`,
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

export const Icons = styled("img")(({ theme }) => ({
	cursor: "pointer",
	height: "18px",
	width: "18px",
}));

export const TypographyBox: any = styled(Typography)(({ theme, active }: any) => ({
	padding: "0px 10px",
	[theme.breakpoints.down("md")]: {
		width: "100%",
		padding: "0px 5px",
	},
}));

export const TypographyHeading = styled(Typography)(({ theme, active }: IStyleWithActive) => ({
	color: `${active && "#FE6A57"}`,
	fontSize: "18px",
	lineHeight: "25.2px",
	fontWeight: 500,
	marginTop: "12px",
	fontFamily: "Roboto Slab",
	[theme.breakpoints.down("lg")]: {
		fontSize: "14px",
		lineHeight: "22.4px",
	},
	[theme.breakpoints.down("md")]: {
		fontSize: "12px",
		lineHeight: "20.4px",
	},
}));

export const TypographyHeadingSimple = styled(Typography)(({ theme, active }: IStyle) => ({
	color: `${active && "#FE6A57"}`,
	fontSize: "18px",
	lineHeight: "25.2px",
	fontWeight: 500,
	marginTop: "12px",
	fontFamily: "Roboto Slab",
	[theme.breakpoints.down("md")]: {
		fontSize: "14px",
		lineHeight: "22.4px",
	},
	[theme.breakpoints.down("sm")]: {
		fontSize: "18px",
		lineHeight: "25.2px",
	},
}));

export const Typographyid = styled(Typography)(({ theme }) => ({
	color: alpha(theme.palette.text.primary, 0.6),
	fontSize: "14px",
	lineHeight: "16.8px",
	fontWeight: 700,
	marginTop: "5px",
	fontFamily: "Bai Jamjuree",
	[theme.breakpoints.down("lg")]: {
		fontSize: "12px",
		lineHeight: "14.4px",
		fontWeight: 500,
	},
	[theme.breakpoints.down("sm")]: {
		fontSize: "14px",
		lineHeight: "16.8px",
		fontWeight: 700,
	},
}));

export const StyledMarkdown = styled(ReactMarkdown)(() => ({
	"& > :first-child": {
		marginTop: "9px",
	},
	"& > :last-child": {
		marginBottom: "9px",
	},
}));

export const IconWrapper = styled(Box)(({ theme, top, sx: { themeMode } }: IStyle) => ({
	display: "flex",
	height: "40%",
	top,
	alignItems: "center",
	gap: "12px",
	position: "absolute",
	right: "0px",
	background: `${themeMode === "light" ? "rgba(255, 255, 255, 1)" : "rgba(27, 44, 51, 1)"}`,
	padding: "20px 10px",
	"&::before": {
		content: '""',
		position: "absolute",
		height: "100%",
		width: "20px",
		top: 0,
		left: "-20px",
		right: 0,
		bottom: 0,
		background: `linear-gradient(to right, ${
			themeMode === "light"
				? "rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1)"
				: "rgba(27, 44, 51, 0), rgba(27, 44, 51, 0.2), rgba(27, 44, 51, 0.4), rgba(27, 44, 51, 0.6), rgba(27, 44, 51, 0.8), rgba(27, 44, 51, 1)"
		})`,
	},
	[theme.breakpoints.down("lg")]: {
		height: "30%",
	},
	[theme.breakpoints.down("md")]: {
		top: "10%",
	},
}));

export const DocumentIconWrapper = styled(IconWrapper)(({ theme, sx: { themeMode } }: IStyle) => ({
	gap: "15px",
	background: `${themeMode === "light" ? "rgba(247, 246, 244, 1)" : "rgba(46, 61, 66, 1)"}`,
	"&::before": {
		content: '""',
		background: `linear-gradient(to right, ${
			themeMode === "light"
				? "rgba(247, 246, 244, 0), rgba(247, 246, 244, 0.2), rgba(247, 246, 244, 0.4), rgba(247, 246, 244, 0.6), rgba(247, 246, 244, 0.8), rgba(247, 246, 244, 1)"
				: "rgba(46, 61, 66, 0), rgba(46, 61, 66, 0.2), rgba(46, 61, 66, 0.4), rgba(46, 61, 66, 0.6), rgba(46, 61, 66, 0.8), rgba(46, 61, 66, 1)"
		})`,
	},
}));

export const SimpleDivider = styled(Divider)(({ theme }) => ({
	marginTop: "15px",
	background: theme.palette.text.primary,
	opacity: 0.2,
}));

export const CustomDivider: any = styled(Divider)(({ theme, active }: IStyleWithActive) => ({
	marginTop: `${active ? "12px" : "15px"}`,
	height: `${active && "3px"}`,
	opacity: `${active ? 1 : 0.2}`,
	background: `${active ? theme.palette.primary.light : theme.palette.text.primary}`,
	[theme.breakpoints.down("lg")]: {
		height: `${active && "2px"}`,
	},
	[theme.breakpoints.down("md")]: {},
}));

export const CustomList = styled(List)(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		width: "93%",
	},
}));

export const NewLoanBtnWrapper = styled(Typography)(({ theme }) => ({
	display: "flex",
	justifyContent: "end",
	padding: "20px",
	background: theme.palette.background.paper,
	overflow: "hidden",
}));

export const NewLoanMobileText = styled(Typography)(({ theme }) => ({
	fontSize: "16px",
	fontFamily: "Exo 2",
	fontWeight: 500,
	lineHeight: "22.4",
	color: theme.palette.text.white,
}));
