import { FC } from "react";
import { FormBtnWrapper } from "../../../pages/styles/home.style";
import { FormBtn } from "../../../pages/account/settings.style";
import { Loader } from "../Loader/Loader";
import { getThemeReducer, useAppSelector } from "../../../store";

interface IFormButtonsProps {
	cancel: () => void;
	isDirty: boolean;
	status: "error" | "success" | "idle" | "pending";
}

const FormButtons: FC<IFormButtonsProps> = ({ cancel, isDirty, status }) => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<FormBtnWrapper>
			<FormBtn
				hoverColor={themeMode === "light" ? "#e0e0df" : "#607179"}
				sx={{
					background: themeMode === "light" ? "#F7F6F4" : "#465359",
				}}
				type="button"
				onClick={cancel}
			>
				Cancel
			</FormBtn>
			<FormBtn
				disabled={status === "pending" || !isDirty}
				hoverColor={`${!isDirty && themeMode === "light" ? "#F7F6F4" : !isDirty && themeMode === "dark" ? "#607179" : "#f97c6b"}`}
				sx={{
					color: `${!isDirty ? "black" : "white"}`,
					background: (!isDirty || status === "pending") && themeMode === "light" ? "#F7F6F4" : (!isDirty || status === "pending") && themeMode === "dark" ? "#465359" : "#FE6A57",
				}}
				type="submit"
			>
				{status === "pending" ? <Loader color="inherit" loadingText="Processing" animatedTextWidth="100px" /> : "Save"}
			</FormBtn>
		</FormBtnWrapper>
	);
};

export { FormButtons };
