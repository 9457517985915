import { ModalProvider, ActiveListProvider, RightNavBarProvider, ChatProvider } from "./context";
import Router from "./routes";
import { ThemeProvider } from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./store";

const queryClient = new QueryClient();

function App() {
	return (
		<Provider store={store}>
			<ThemeProvider>
				<ModalProvider>
					<ActiveListProvider>
						<RightNavBarProvider>
							<ChatProvider>
								<QueryClientProvider client={queryClient}>
									<Router />
								</QueryClientProvider>
							</ChatProvider>
						</RightNavBarProvider>
					</ActiveListProvider>
				</ModalProvider>
			</ThemeProvider>
		</Provider>
	);
}

export default App;
