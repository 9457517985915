import { getThemeReducer, useAppSelector } from "../../../../store";
import { useModal } from "../../../../hook/useModal";
import { BodySubTextError, ModalBodyWrapper, ModalText } from "../../allModals.style";
import GuidelineDropdown from "./GuidelineDropdown";

const CreateChatModal = () => {
	const { errorMessage } = useModal();
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<ModalBodyWrapper paddingBlock={"15px 0px"} header={true} sx={{ themeMode }}>
			<ModalText>Select Guideline</ModalText>
			<GuidelineDropdown />
			{errorMessage && <BodySubTextError>{errorMessage}</BodySubTextError>}
		</ModalBodyWrapper>
	);
};
export { CreateChatModal };
