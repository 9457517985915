import React from "react";
import { Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts";
import ArchivePage from "../pages/archive";
import CompanyPage from "../pages/company";
import NotificationPage from "../pages/notifications";
import IntegrationPage from "../pages/integrations";
import MiddlePage from "../pages/middle";
// pages
import SettingsPage from "../pages/account";
import { useTheme, useMediaQuery } from "@mui/material";
import { BillingPage } from "../pages/billing/BillingPage";
import InnerSideBarMobileForSettingsPage from "../pages/account/InnerSideBarMobile";
import { PdfPage } from "../pages/pdf/PdfPage";
import Chat from "../pages/chat";
import Home from "../pages/home";

const Router = () => {
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const router = createBrowserRouter([
		{
			element: <DashboardLayout />,
			children: [
				{ path: "/", element: <Navigate to="/loans" replace /> },
				{
					path: "/loans/:loanId",
					element: <Home />,
				},
				{
					path: "/chats/:agencyId/:chatId?",
					element: <Chat />,
				},
				{
					path: "/:header",
					element: <Home />,
				},
				{
					path: "account",
					element: <SettingsPage />,
				},
				{
					path: "archive",
					element: <ArchivePage />,
				},
				{
					path: "settings",
					element: smDown ? <InnerSideBarMobileForSettingsPage /> : <Navigate to="/account" replace />,
					index: true,
				},
				{
					path: "company",
					element: <CompanyPage />,
				},
				{
					path: "notification",
					element: <NotificationPage />,
				},
				{
					path: "integration",
					element: <IntegrationPage />,
				},
				{
					path: "billing",
					element: <BillingPage />,
				},
			],
		},
		{
			path: "middle",
			element: <MiddlePage />,
		},
		{
			path: "/loans/:loanId/documents/:document_id",
			element: <PdfPage />,
		},
	]);

	return <RouterProvider router={router} />;
};

export default Router;
