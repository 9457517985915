import { useEffect } from "react";
import { useLoans } from "./useLoans";
import { useActiveList } from "./useActiveList";
import { titles } from "./../constants/titles";
import { useLocation } from "react-router-dom";
import { useChats } from "./useChats";
import { useActiveChatList } from "./useActiveChatList";

export const useTitle = (title?: string) => {
	const { isPathnameIsLoans, isLoansLoading } = useLoans();
	const { isPathnameIsChats, isChatsLoading } = useChats();
	const { getActiveLoan } = useActiveList();
	const { getActiveChat } = useActiveChatList();
	const { pathname } = useLocation();

	const parsePathname = (): string => {
		const pathnameWithoutSlash = pathname.replace("/", "");
		if (pathnameWithoutSlash) {
			return pathnameWithoutSlash[0].toUpperCase() + pathnameWithoutSlash.slice(1);
		}
	};

	useEffect(() => {
		const activeLoan = getActiveLoan();
		const activeChat = getActiveChat();

		if (isPathnameIsLoans) {
			document.title = isLoansLoading || !activeLoan?.file_name ? "Loans" : `${activeLoan.file_name} - ${activeLoan.loan_number}`;
		} else if (isPathnameIsChats) {
			document.title = isChatsLoading || !activeChat?.title ? "Chats" : `${activeChat.title}`;
		} else {
			document.title = `${titles.commonSettings} - ${parsePathname()}`;
		}
	}, [title, isPathnameIsLoans, isLoansLoading, getActiveLoan, isPathnameIsChats, isChatsLoading, getActiveChat]);

	return { parsePathname };
};
