import { FC, useContext } from "react";
import { CustomDivider, Icons, IconWrapper, InnerSideBarButton, TypographyBox, TypographyHeadingSimple, Typographyid } from "../../../layouts/innerSideBar/innerSideBar.style";
import { ActiveListContext } from "../../../context";
import { useActiveList } from "../../../hook/useActiveList";
import { useLoans } from "../../../hook/useLoans";
import { tooltips } from "../../../constants";
import { ITooltip } from "../../../interfaces";
import { Box } from "@mui/material";
import { getThemeReducer, mobileActions, useAppDispatch, useAppSelector } from "../../../store";
import { useChats } from "../../../hook/useChats";

interface IInnerSideBarItemProps {
	item: any;
	isActive: boolean;
}

const InnerSideBarItem: FC<IInnerSideBarItemProps> = ({ item, isActive }) => {
	const { setActiveList } = useContext(ActiveListContext);
	const { showingField, onHoverHandler, onMouseLeave, isHover } = useActiveList();
	const { isPathnameIsLoans } = useLoans();
	const { isPathnameIsChats } = useChats();
	const { themeMode } = useAppSelector(getThemeReducer);
	const dispatch = useAppDispatch();
	const { toggleShowMessages } = mobileActions;

	const isPathname = isPathnameIsLoans || isPathnameIsChats;

	const onClickHandler = (item: any) => {
		setActiveList(String(item.id));
		dispatch(toggleShowMessages());
	};

	const needToShowTooltips = () => isPathname && isHover;

	return (
		<Box onMouseEnter={onHoverHandler} onMouseLeave={onMouseLeave}>
			<InnerSideBarButton onClick={() => onClickHandler(item)} is_pathname_is_loans={isPathnameIsLoans}>
				<TypographyBox>
					<TypographyHeadingSimple active={isActive}>{item[showingField]}</TypographyHeadingSimple>
					{isPathnameIsLoans && <Typographyid>{item.loan_number}</Typographyid>}
				</TypographyBox>
				{isPathname && (
					<IconWrapper top="10%" sx={{ themeMode }}>
						{needToShowTooltips() && tooltips.map(({ src }: ITooltip) => <Icons src={src[themeMode]} />)}
					</IconWrapper>
				)}
			</InnerSideBarButton>
			<CustomDivider active={isActive} />
		</Box>
	);
};

export { InnerSideBarItem };
