import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { chatService } from "../../../../services/chat.service";
import { useParams } from "react-router-dom";
import { IGuideline } from "../../../../interfaces/guideline.interface";
import { ActiveListContext, ModalContext } from "../../../../context";
import { agencyService } from "../../../../services/agency.service";
import { getThemeReducer, useAppSelector } from "../../../../store";
import { useAgency } from "../../../../hook/useAgency";

const GuidelineDropdown = () => {
	const [guidelines, setGuidelines] = useState<IGuideline[]>([]);
	const [selectedGuide, setSelectedGuide] = useState<string>(null);
	const { agencyId } = useParams();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { setSelectedOption } = useContext(ModalContext);
	const { activeList, activeEntity } = useContext(ActiveListContext);
	const { guidelinesData, isGuidelinesPending } = useAgency();

	useEffect(() => {
		async function getGuidelines() {
			setGuidelines(guidelinesData);
			setSelectedGuide(guidelinesData[0]?.display_name);
			setSelectedOption(guidelinesData[0]?.id);
		}

		getGuidelines();
	}, []);

	const handleChange = (gl: IGuideline) => {
		setSelectedGuide(gl.display_name);
		setSelectedOption(gl.id);
	};

	if (isGuidelinesPending) {
		return (
			<Box sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box sx={{ width: "100%", margin: "8px auto 0px auto" }}>
			<Select
				value={selectedGuide}
				fullWidth
				displayEmpty
				variant="outlined"
				IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
				sx={(theme) => ({
					fontSize: "1.1rem",
					padding: "2px",
					borderRadius: "16px",
					border: "2px",
					"& .MuiSelect-icon": {
						fontSize: "2rem",
						color: themeMode === "light" ? theme.palette.secondary.light : theme.palette.secondary.dark,
					},
				})}
			>
				{guidelines.map((guide, index) => (
					<MenuItem
						key={index}
						value={guide.display_name}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							padding: "8px 12px",
							borderRadius: "8px",
						}}
						onClick={() => handleChange(guide)}
					>
						<Typography
							variant="body1"
							sx={{
								width: "98%",
								fontSize: "1.25rem",
								fontWeight: 500,
								whiteSpace: "normal",
								wordBreak: "break-word",
								overflowWrap: "break-word",
								lineHeight: "1.4rem",
							}}
						>
							{guide.display_name}
						</Typography>
						<Typography variant="body2" sx={(theme) => ({ fontSize: "0.85rem", color: themeMode === "light" ? theme.palette.secondary.light : theme.palette.secondary.dark })}>
							{guide.version}
						</Typography>
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default GuidelineDropdown;
