import { DeleteModalBody, ModalBodyWrapper, DeleteModalHeading, SubheadingWrapper, SubheadingInner, ModalSubHeading, ModalBody, IconModalWrapper, IconModal } from "../allModals.style";
import { getThemeReducer, useAppSelector } from "../../../store";

const AnalyzeModal = () => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<ModalBodyWrapper sx={{ themeMode, paddingBlock: "42px 20px" }}>
			{/* @ts-ignore */}
			<IconModalWrapper sx={{ themeMode }}>
				<IconModal alt="Modal Warning" src="/images/svgs/search-red.svg" />
			</IconModalWrapper>
			<ModalBody>
				<DeleteModalHeading>Run New Loan Analysis</DeleteModalHeading>
			</ModalBody>
			<SubheadingWrapper>
				<SubheadingInner>
					<ModalSubHeading sx={{ themeMode }}>Are you sure you want to run a new loan analysis? This will take a few minutes.</ModalSubHeading>
				</SubheadingInner>
			</SubheadingWrapper>
		</ModalBodyWrapper>
	);
};

export { AnalyzeModal };
