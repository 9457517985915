import { Tooltip, TooltipProps } from "@mui/material";
import { FC, useContext } from "react";
import { Icon } from "../../../layouts/rightSideBar/rightNavBar.style";
import ReactMarkdown from "react-markdown";
import { ITooltip } from "../../../interfaces";
import { ToolTip } from "../../../enum/tooltip.enum";
import { Animation, IconWrapper } from "./Tooltip.styles";
import { ActiveListContext } from "../../../context";
import { getThemeReducer, useAppSelector } from "../../../store";

interface IToolTipProps extends Pick<TooltipProps, "arrow" | "placement"> {
	tooltip: ITooltip;
	marginLeft?: string;
	onClick?: any;
	isScanned?: boolean;
	id: number | string;
}

const CustomToolTip: FC<IToolTipProps> = ({ tooltip: { arrow, placement, src, title }, marginLeft, onClick, isScanned, id }) => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const { activeEntity } = useContext(ActiveListContext);

	if (!isScanned && title === ToolTip.FRAUD_CHECK) {
		return null;
	}

	return (
		<Tooltip title={<ReactMarkdown>{title}</ReactMarkdown>} placement={placement} arrow={arrow}>
			{activeEntity?.tooltipTitle === title && activeEntity?.entity?.id === id ? (
				<IconWrapper animation={Animation.ROTATE}>
					<Icon src={themeMode === "light" ? "/images/svgs/Sync.svg" : "/images/svgs/Sync-dark.svg"} onClick={onClick} sx={{ marginLeft }} />
				</IconWrapper>
			) : (
				<Icon src={src[themeMode]} onClick={onClick} sx={{ marginLeft }} />
			)}
		</Tooltip>
	);
};
export { CustomToolTip };
