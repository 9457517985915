import { DragEventHandler, useCallback, useContext, useEffect, useState } from "react";
import { ActiveListContext, ChatContext, ModalContext } from "../context";
import { CurrentStep, IMainModalConfig, IModalConfig, ModalKey } from "../interfaces";
import { useLoans } from "./useLoans";
import { useDocuments } from "./useDocuments";
import { UploadDocumentError, Step1 as UploadDocumentStep1, UploadDocumentSuccess } from "../components/allModals/upload/document/steps";
import { Step1, Step2, UploadError, UploadSuccess } from "../components/allModals/upload/loan/steps";
import { RefreshModal } from "../components/allModals/archive/ArchiveModal";
import { ArchiveError } from "../components/allModals/archive/ArchiveError";
import { DeleteModal } from "../components/allModals/delete/DeleteModal";
import { DisLikeModal } from "../components/allModals/feedback/dislike/DislikeModal";
import { LikeModal } from "../components/allModals/feedback/like/LikeModal";
import { useMessages } from "./useMessages";
import { FeedbackSuccess } from "../components/allModals/feedback/FeedbackSuccess";
import { OperationSuccess } from "../components/allModals/common/OperationSuccess";
import { PowerUpModal } from "../components/allModals/powerUp/PowerUpModal";
import { OperationFailed } from "../components/allModals/common/OperationFailed";
import { Loader } from "../components/common/Loader/Loader";
import { BillingModal } from "../components/allModals/billing/BillingModal";
import { getThemeReducer, useAppSelector } from "../store";
import { CreateChatModal } from "../components/allModals/upload/chat/CreateChatModal";
import { useChats } from "./useChats";
import { useParams } from "react-router-dom";
import { chatService } from "../services/chat.service";
import { IChat } from "../interfaces/chat.interface";
import GuidesModal from "../components/allModals/guides/guidesModal";
import { SourceModal } from "../components/allModals/source/SourceModal";
import { useActiveChatList } from "./useActiveChatList";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "../enum";
import { CreateChatDropdownModal } from "../components/allModals/create-chat/CreateChatModal";
import { AnalyzeModal } from "../components/allModals/analyze/AnalyzeModal";
import { useAnalyses } from "./useAnalyses";
import { DeleteDocModal } from "../components/allModals/delete-doc/DeleteDocModal";
import { useActiveList } from "./useActiveList";

export const useModal = () => {
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [isDragOver, setIsDragOver] = useState<boolean>(false);
	const { setSelectedFile, selectedFile, inputRef, setStep, modalKey, selectedOption, message } = useContext(ModalContext);
	const { uploadNewLoanMutation, updateLoanStatusClickHandler } = useLoans();
	const { createNewChatMutation, refetchChats } = useChats();
	const { uploadNewDocumentMutation, updateDocumentHandler } = useDocuments();
	const { openModal, submitRef, document_id } = useContext(ModalContext);
	const { setMessageId } = useContext(ChatContext);
	const { sendFeedbackHandler } = useMessages();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { agencyId } = useParams();
	const { setActiveList } = useContext(ActiveListContext);
	const { listItems } = useActiveChatList();
	const { updateActiveList } = useActiveList();
	const {
		askAnalysesMutation: { mutate: analyzeMutate, isPending: analyzeIsPending },
	} = useAnalyses();

	const loanModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: Step1,
			cancelButtonText: "",
			actionButtonText: "Upload File",
			headerText: "Upload New Loan File",
			onClick: () => onClickUploadFileHandler(),
		},
		[CurrentStep.STEP2]: {
			Component: Step2,
			cancelButtonText: "Cancel",
			actionButtonText: "",
			headerText: "Importing Loan File",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: UploadSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "Loan File Imported",
			onClick: null,
		},
		[CurrentStep.STEP4]: {
			Component: UploadError,
			cancelButtonText: "Cancel",
			actionButtonText: "Try Again",
			headerText: "Importing loan file",
			onClick: () => onClickUploadFileHandler(),
		},
	};

	const documentModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: UploadDocumentStep1,
			cancelButtonText: "",
			actionButtonText: "Upload File",
			headerText: "Upload New Document",
			onClick: () => onClickUploadFileHandler(),
		},
		[CurrentStep.STEP2]: {
			Component: UploadDocumentStep1,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "Upload New Document",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: UploadDocumentSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP4]: {
			Component: UploadDocumentError,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
	};

	const archivedModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: RefreshModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Archive",
			headerText: "",
			onClick: () => updateLoanStatusClickHandler({ archive: true }),
		},
		[CurrentStep.STEP2]: {
			Component: RefreshModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: ArchiveError,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP4]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "File has been successfully archived!",
		},
	};

	const deleteModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: DeleteModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Delete",
			headerText: "",
			onClick: () => updateLoanStatusClickHandler({ deleted: true }),
		},
		[CurrentStep.STEP2]: {
			Component: DeleteModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to delete file. Please, try again later.",
		},
		[CurrentStep.STEP4]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "File has been successfully deleted!",
		},
	};

	const deleteDocModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: DeleteDocModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Delete",
			headerText: "",
			onClick: () => updateDocumentHandler({ deleted: true }),
		},
		[CurrentStep.STEP2]: {
			Component: DeleteDocModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to delete file. Please, try again later.",
		},
		[CurrentStep.STEP4]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "File has been successfully deleted!",
		},
	};

	const likeConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: LikeModal,
			cancelButtonText: "",
			actionButtonText: "Send Feedback",
			headerText: "Provide Feedback Here",
			onClick: sendFeedbackHandler,
			showLikeIcon: {
				light: "/images/svgs/like-black.svg",
				dark: "/images/svgs/like-white.svg",
			},
			crossIcon: "1",
		},
		[CurrentStep.STEP2]: {
			Component: LikeModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "Provide Feedback Here",
			onClick: sendFeedbackHandler,
			showLikeIcon: {
				light: "/images/svgs/like-black.svg",
				dark: "/images/svgs/like-white.svg",
			},
			crossIcon: "1",
		},
		[CurrentStep.STEP3]: {
			Component: FeedbackSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP4]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to send feedback. Please try again later.",
		},
	};

	const dislikeConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: DisLikeModal,
			cancelButtonText: "",
			actionButtonText: "Send Feedback",
			headerText: "Provide Feedback Here",
			onClick: sendFeedbackHandler,
			showLikeIcon: {
				light: "/images/svgs/dislike-black.svg",
				dark: "/images/svgs/dislike-white.svg",
			},
			crossIcon: "1",
		},
		[CurrentStep.STEP2]: {
			Component: DisLikeModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "Provide Feedback Here",
			onClick: sendFeedbackHandler,
			showLikeIcon: {
				light: "/images/svgs/dislike-black.svg",
				dark: "/images/svgs/dislike-white.svg",
			},
			crossIcon: "1",
		},
		[CurrentStep.STEP3]: {
			Component: FeedbackSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP4]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to send feedback. Please try again later.",
		},
	};

	const powerUpConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: PowerUpModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Save",
			headerText: "Power Ups",
			onClick: () => submitRef?.current?.click(),
		},
		[CurrentStep.STEP2]: {
			Component: PowerUpModal,
			cancelButtonText: "Cancel",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "Power Ups",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "The error occurred during updating Power Up. Please try again.",
		},
		[CurrentStep.STEP4]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "Power Up has been successfully updated!",
		},
	};

	const billingConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: BillingModal,
			cancelButtonText: "",
			actionButtonText: "",
			headerText: "Pricing",
			onClick: () => {},
			preventClose: true,
			headerIconTooltipText: "Log Out",
			headerRightCornerIcon: themeMode === "light" ? "/images/svgs/logout.svg" : "/images/svgs/logout-dark.svg",
		},
	};

	const chatConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: CreateChatModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Start Now",
			headerText: "Start New Guideline Chat",
			onClick: () => onClickCreateChatHandler(),
		},
		[CurrentStep.STEP2]: {
			Component: CreateChatModal,
			cancelButtonText: "Cancel",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "Start New Guideline Chat",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "Chat has been successfully created!",
		},
		[CurrentStep.STEP4]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to create chat. Please, try again later.",
		},
	};

	const deleteModalChatConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: DeleteModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Delete",
			headerText: "",
			onClick: () => handleUpdateChat({ deleted: true }),
		},
		[CurrentStep.STEP2]: {
			Component: DeleteModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to delete chat. Please, try again later.",
		},
		[CurrentStep.STEP4]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "Chat has been successfully deleted!",
		},
	};

	const archiveModalChatConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: RefreshModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Archive",
			headerText: "",
			onClick: () => handleUpdateChat({ archive: true }),
		},
		[CurrentStep.STEP2]: {
			Component: RefreshModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to archive chat. Please, try again later.",
		},
		[CurrentStep.STEP4]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "Chat has been successfully archived!",
		},
	};

	const createChatModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: CreateChatDropdownModal,
			cancelButtonText: "Cancel",
			actionButtonText: "New Chat",
			headerText: "",
			onClick: () => onClickCreateChatHandler(),
		},
		[CurrentStep.STEP2]: {
			Component: CreateChatDropdownModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "Chat has been successfully created!",
		},
		[CurrentStep.STEP4]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: "Failed to create chat. Please, try again later.",
		},
	};

	const guidesModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: GuidesModal,
			cancelButtonText: "",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
	};

	const analyzeModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: AnalyzeModal,
			cancelButtonText: "Cancel",
			actionButtonText: "Run Now",
			headerText: "",
			onClick: () => reanalyzeHandler(),
		},
		[CurrentStep.STEP2]: {
			Component: AnalyzeModal,
			cancelButtonText: "",
			actionButtonText: <Loader color="inherit" animatedTextWidth="100px" loadingText="Processing" />,
			headerText: "",
			onClick: null,
		},
		[CurrentStep.STEP3]: {
			Component: OperationSuccess,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			successStatusText: "New Loan analysis Has started. Please check back soon.",
		},
		[CurrentStep.STEP4]: {
			Component: OperationFailed,
			cancelButtonText: "Ok",
			actionButtonText: "",
			headerText: "",
			onClick: null,
			failureStatusText: message,
		},
	};

	const sourceModalConfig: IModalConfig = {
		[CurrentStep.STEP1]: {
			Component: SourceModal,
			cancelButtonText: "",
			actionButtonText: "",
			headerText: "",
			onClick: null,
		},
	};

	const mainModalConfig: IMainModalConfig = {
		loan: {
			mutation: uploadNewLoanMutation,
			inputAccept: ["application/zip", "application/x-zip-compressed"],
			errorMessage: "Please drop a ZIP file.",
			config: loanModalConfig,
		},
		document: {
			mutation: uploadNewDocumentMutation,
			inputAccept: ["application/pdf"],
			errorMessage: "Please drop a PDF file.",
			config: documentModalConfig,
		},
		analyze: {
			config: analyzeModalConfig,
		},
		archived: {
			config: archivedModalConfig,
		},
		delete: {
			config: deleteModalConfig,
		},
		like: {
			config: likeConfig,
		},
		dislike: {
			config: dislikeConfig,
		},
		powerUp: {
			config: powerUpConfig,
		},
		billing: {
			config: billingConfig,
		},
		chat: {
			mutation: createNewChatMutation,
			config: chatConfig,
		},
		delete_chat: { config: deleteModalChatConfig },
		archived_chat: { config: archiveModalChatConfig },
		guides: { config: guidesModalConfig },
		source: { config: sourceModalConfig },
		create_chat: { config: createChatModalConfig },
		delete_doc: { config: deleteDocModalConfig },
	};

	const onClickUploadFileHandler = (): void => {
		if (inputRef && inputRef?.current) {
			if (!selectedFile) {
				setStep(CurrentStep.STEP1);
				inputRef.current.click();
			} else {
				setStep(CurrentStep.STEP2);
				handleFileUpload();
			}
			updateActiveList();
		}
	};

	const onClickCreateChatHandler = (): void => {
		if (!selectedOption) {
			setStep(CurrentStep.STEP1);
		} else {
			setStep(CurrentStep.STEP2);
			handleCreateChat();
		}
	};

	const reanalyzeHandler = (): void => {
		setStep(CurrentStep.STEP2);
		analyzeMutate();
	};

	const handleCreateChat = () => {
		mainModalConfig["chat"].mutation.mutate({ agencyId, guidelineId: selectedOption });
	};

	const queryClient = useQueryClient();

	const handleUpdateChat = async (props: Partial<IChat>) => {
		try {
			setStep(CurrentStep.STEP2);
			const response = await chatService.updateChat(document_id, props);
			if (response) {
				queryClient.invalidateQueries({ queryKey: [QueryKey.CHATS] });
				const chats = await refetchChats();
				setActiveList(chats.data.pages[0][0].id);
				setStep(CurrentStep.STEP4);
			} else {
				setStep(CurrentStep.STEP3);
			}
		} catch (error) {
			setStep(CurrentStep.STEP3);
		}
	};

	const handleFileUpload = () => {
		const formData = new FormData();
		formData.append("file", selectedFile);
		mainModalConfig[modalKey].mutation.mutate(formData);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			if (mainModalConfig[modalKey].inputAccept.includes(file.type)) {
				setSelectedFile(file);
				setErrorMessage("");
			} else {
				setSelectedFile(null);
				setErrorMessage(mainModalConfig[modalKey].errorMessage);
			}
		} else {
			setSelectedFile(null);
			setErrorMessage("");
		}
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const droppedFile = e.dataTransfer.files[0];
		setIsDragOver(false);
		if (droppedFile) {
			if (mainModalConfig[modalKey].inputAccept.includes(droppedFile.type)) {
				setSelectedFile(droppedFile);
				setErrorMessage("");
			} else {
				setSelectedFile(null);
				setErrorMessage(mainModalConfig[modalKey].errorMessage);
			}
		}
	};

	const dragOverHandler: DragEventHandler<HTMLDivElement> = (e): void => {
		e.preventDefault();
		setIsDragOver(true);
	};

	const dragLeaveHandler: DragEventHandler<HTMLDivElement> = (e): void => {
		setIsDragOver(false);
	};

	const openModalFeedback = useCallback((modalKey: ModalKey, messageId: string): void => {
		openModal(modalKey);
		setMessageId(messageId);
	}, []);

	return {
		errorMessage,
		isDragOver,
		onClickUploadFileHandler,
		handleFileChange,
		handleDrop,
		dragOverHandler,
		dragLeaveHandler,
		mainModalConfig,
		loanModalConfig,
		documentModalConfig,
		openModalFeedback,
	};
};
