import { styled } from "@mui/system";
import { Box, Button } from "@mui/material";
import { IStyle } from "../../../../interfaces";

export const ModalFooterWrapper: any = styled(Box)(({ theme }) => ({
	padding: "24px 28px",
	background: theme.palette.background.paper,
	borderRadius: "0px 0px 16px 16px",
}));

export const FooterButtonWrapper = styled(Box)(({ theme, actionButtonText, cancelButtonText }: { theme: any; actionButtonText: JSX.Element | string; cancelButtonText: string }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	justifyContent: `${actionButtonText !== "" && cancelButtonText !== "" ? "space-between" : "center"}`,
	width: "90%",
	margin: "0 auto",
	gap: "20px",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column-reverse",
		gap: "12px",
	},
}));

export const CancelBtn = styled(Button)(({ theme, sx: { themeMode } }: IStyle) => ({
	background: `${themeMode === "light" ? theme.palette.background.default : "#465359"}`,
	color: theme.palette.text.primary,
	fontSize: "20px",
	fontWeight: 500,
	lineHeight: "28px",
	height: "56px",
	width: "206px",
	borderRadius: "10px",
	textTransform: "capitalize",
	"&:hover": {
		background: `${themeMode === "light" ? theme.palette.background.default : "#465359"}`,
		boxShadow: `0px 8px 12px ${themeMode === "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.15)"}, 0px 2px 6px ${
			themeMode === "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.15)"
		}`,
	},
	[theme.breakpoints.down("sm")]: {
		width: "auto",
	},
}));

export const ImportBtn = styled(Button)(({ theme, sx: { themeMode } }: IStyle) => ({
	textTransform: "capitalize",
	color: theme.palette.text.white,
	background: theme.palette.primary.light,
	fontSize: "20px",
	fontWeight: 500,
	lineHeight: "28px",
	height: "56px",
	width: "206px",
	borderRadius: "10px",
	transition: "box-shadow 0.3s ease",
	"&:hover": {
		background: theme.palette.primary.light,
		boxShadow: `0px 8px 12px ${themeMode === "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.15)"}, 0px 2px 6px ${
			themeMode === "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.15)"
		}`,
	},
	"&:disabled": {
		background: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	[theme.breakpoints.down("sm")]: {
		width: "auto",
	},
}));
