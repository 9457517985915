import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKey } from "../enum";
import { userService } from "../services";
import { CurrentStep, IUser } from "../interfaces";
import { useContext } from "react";
import { ModalContext } from "../context";
import { useTheme } from "./useTheme";

export const useUsers = () => {
	const { setStep, modalKey } = useContext(ModalContext);
	const { changeThemeModeAndFavicon } = useTheme();

	const {
		data: user,
		isPending: isUserPending,
		refetch: refetchMe,
		...options
	} = useQuery({
		queryKey: [QueryKey.USER],
		queryFn: userService.getMe,
		staleTime: Infinity,
	});

	const isModalKeyIsPowerUp = modalKey === "powerUp";

	const updateMeMutation = useMutation({
		mutationFn: (user: Partial<IUser>) => userService.updateMe(user),
		onSuccess: (user) => {
			changeThemeModeAndFavicon(user.theme_preference);
			refetchMe();
			isModalKeyIsPowerUp && setStep(CurrentStep.STEP4);
		},
		onError: (e) => {
			console.error(e);
			isModalKeyIsPowerUp && setStep(CurrentStep.STEP3);
		},
	});

	const updateUserHandler = (updatedFields: Partial<IUser>): void => {
		updateMeMutation.mutate(updatedFields);
	};

	return {
		user,
		isUserPending,
		options,
		updateMeMutation,
		updateUserHandler,
	};
};
