import { FC, Fragment } from "react";
import { ArrowIconWrapper, CustomListItem, CustomListItemBox, ListHeading, ListHeadingWrapper, ListSubHeading } from "../listItem/listItems.style";
import { ListItem } from "../listItem/ListItem/ListItem";
import { ArrowIcon } from "../../../../../pages/account/settings.style";
import { CustomDivider } from "../../../../innerSideBar/innerSideBar.style";
import { getThemeReducer, useAppSelector } from "../../../../../store";
import { MainListWrapper } from "../../../rightNavBar.style";
import { ISection } from "../../../../../interfaces/section.interface";
import { useSections } from "../../../../../hook/useSections";

interface ICategoryProps {
	isActiveCategory: boolean;
	part: ISection[];
	toggleCategoryBox: (part: ISection) => void;
}

const Category: FC<ICategoryProps> = ({ isActiveCategory, part, toggleCategoryBox }) => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const { getActiveDocsNumberInCategory } = useSections();

	const subpart = part.slice(1);

	return (
		<Fragment>
			<CustomListItem active={isActiveCategory} themeMode={themeMode}>
				<CustomListItemBox onClick={() => toggleCategoryBox(part[0])}>
					<ListHeadingWrapper>
						<ListHeading>{part[0].display_name}</ListHeading>
						{/*@ts-ignore*/}
						<ListSubHeading themeMode={themeMode}>
							{getActiveDocsNumberInCategory(subpart)} of {subpart.length} Documents
						</ListSubHeading>
					</ListHeadingWrapper>
					<ArrowIconWrapper>
						<ArrowIcon
							src={`/images/svgs/arrow-right-${themeMode}.svg`}
							width="24px"
							height="24px"
							sx={{
								rotate: isActiveCategory && "rotate(90deg)",
							}}
						/>
					</ArrowIconWrapper>
				</CustomListItemBox>
				{isActiveCategory && (
					<MainListWrapper sx={{ themeMode }}>
						{subpart.map((document: ISection) => (
							<ListItem key={document.id} document={document} />
						))}
					</MainListWrapper>
				)}
			</CustomListItem>
			<CustomDivider sx={{ width: "100%", margin: "0 auto" }} />
		</Fragment>
	);
};

export { Category };
