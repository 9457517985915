import { marked } from "marked";
import { documentConstants } from "../constants";
import { useSections } from "./useSections";

export const useChatText = () => {
	const { sections, getAmountOfSelectedSectionsInGeneral } = useSections();

	const getActionWrapperText = () => {
		const selectedSections = getAmountOfSelectedSectionsInGeneral();
		if (!selectedSections) {
			return "No sections are selected";
		}

		if (selectedSections > documentConstants.selectedHighWaterMark) {
			return "Too many sections are selected. Please remove and try again";
		}

		return `Selected ${selectedSections} of ${sections.length} Sections`;
	};

	const parseMarkdown = (text: string) => {
		return marked.parse(text);
	};

	return {
		getActionWrapperText,
		parseMarkdown,
	};
};
