import { Box, List } from "@mui/material";
import { MainBox } from "./listItems.style";
import { useDocuments } from "../../../../../hook/useDocuments";
import { Category } from "../Category/Category";
import { Loader } from "../../../../../components/common/Loader/Loader";
import { getThemeReducer, useAppSelector } from "../../../../../store";

const ListItems = () => {
	const { isActiveCategory, toggleCategoryBox, documentCategories, isDocumentsFetching } = useDocuments();
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<MainBox>
			<List sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
				{isDocumentsFetching ? (
					<Loader />
				) : documentCategories?.length > 0 ? (
					documentCategories.map((category: string, index: number) => (
						<Category key={category} category={category} isActiveCategory={isActiveCategory(category)} toggleCategoryBox={toggleCategoryBox} />
					))
				) : (
					<Box sx={{ color: themeMode === "dark" ? "#ffffffb0" : "#03363da2", marginTop: "12px", fontSize: "16px", margin: "auto" }}>No Documents Found</Box>
				)}
			</List>
		</MainBox>
	);
};

export default ListItems;
