import React from "react";
import InnerSideLoanNav from "../../layouts/innerSideBar/innerSideLoanBar";
import { MainWrapper, InnerSideWrapper, CardWrapper, CardWrapperMain } from "../account/settings.style";
import { FormWrapper } from "../../components/common/FormWrapper/FormWrapper";
import { getCollapseReducer, getThemeReducer, useAppSelector } from "../../store";
import { SettingsPageBg } from "../archive/archive.style";

const CompanyPage = () => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const { collapsed } = useAppSelector(getCollapseReducer);

	return (
		<MainWrapper>
			<InnerSideWrapper>
				<InnerSideLoanNav />
			</InnerSideWrapper>
			<SettingsPageBg sx={{ themeMode }} collapsed={collapsed}>
				<CardWrapperMain>
					<CardWrapper sx={{ themeMode }}>
						<FormWrapper title="Company" formConfigKey="company" isFormFinished={true} noContentTitle="No Company Found" />
					</CardWrapper>
				</CardWrapperMain>
			</SettingsPageBg>
		</MainWrapper>
	);
};

export default CompanyPage;
