export enum QueryKey {
	LOANS = "loans",
	MESSAGES = "messages",
	DOCUMENTS = "documents",
	DOCUMENT_STREAM = "documentStream",
	SUGGESTIONS = "suggestions",
	USER = "user",
	COMPANY = "company",
	BILLING = "billing",
	CHATS = "chats",
	SECTION = "sections",
	GUIDELINE = "guidelines",
	AGENCY = "agency",
	TOP_AGENCIES = "topAgencies",
}
