import { SaveButton, SaveIcon, DiscardIcon, DiscardButton, ButtonWrapper, ActionWrapper } from "../../../actionButton.style";
import { useDocuments } from "../../../../../hook/useDocuments";
import { useContext, useEffect } from "react";
import { RightNavbarContext } from "../../../../../context";
import SaveStatus from "../../../../../components/common/SaveStatus/SaveStatus";
import { Loader } from "../../../../../components/common/Loader/Loader";
import { getThemeReducer, useAppSelector } from "../../../../../store";

const ActionButton = () => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const {
		documentsBulkUpdateMutation: { isSuccess, isError, isPending: bulkUpdatePending },
		onSaveClickHandler,
		onDiscardClickHandler,
		isDocumentsPending,
	} = useDocuments();
	const { changedDocuments, setResponseMessage } = useContext(RightNavbarContext);

	useEffect(() => {
		if (isSuccess || isError) {
			const timeoutId = setTimeout(() => {
				setResponseMessage("");
				clearTimeout(timeoutId);
			}, 5000);
		}
	}, [isSuccess, isError, setResponseMessage]);

	return (
		<>
			<ActionWrapper>
				{!isDocumentsPending && <SaveStatus isSuccess={isSuccess} isError={isError} />}
				<ButtonWrapper>
					<SaveButton
						onClick={onSaveClickHandler}
						disabled={!changedDocuments.length || bulkUpdatePending}
						sx={{ themeMode }}
						hasChanges={!!changedDocuments.length}
						startIcon={!bulkUpdatePending && <SaveIcon src={`${themeMode === "dark" || !!changedDocuments.length ? "/images/svgs/save-dark.svg" : "/images/svgs/save.svg"}`} />}
					>
						{bulkUpdatePending ? <Loader color="white" animatedTextWidth="100px" loadingText="Processing" /> : "Save Changes"}
					</SaveButton>
					<DiscardButton
						onClick={onDiscardClickHandler}
						sx={{ themeMode }}
						startIcon={<DiscardIcon src={`${themeMode === "light" ? "/images/svgs/trash.svg" : "/images/svgs/trash-dark.svg"}`} />}
					>
						Discard
					</DiscardButton>
				</ButtonWrapper>
			</ActionWrapper>
		</>
	);
};
export default ActionButton;
