import React, { useState, useEffect, useContext } from "react";
import { Box, Unstable_Grid2 as Grid2, Popover, useMediaQuery, useTheme } from "@mui/material";
import { HeaderComponent, LogoComponent, DownArrowIcon, OuterBox, UsernameBox, Username, ArrowIconWrapper, HeaderRightIcon, HeaderIconWrapper, CompanyName } from "./header.style";
import HeaderMobile from "./headerMobile";
import GuidesModal from "../../components/allModals/guides/guidesModal";
import { HeaderList } from "../../components/common/HeaderList/HeaderList";
import { HeaderButtons } from "../../components/common/HeaderButtons/HeaderButtons";
import { useUsers } from "../../hook/useUsers";
import { useHeader } from "../../hook/useHeader";
import { drawerActions, getThemeReducer, useAppDispatch, useAppSelector } from "../../store";
import { useModal } from "../../hook/useModal";
import { ModalContext } from "../../context";
import { CurrentStep } from "../../interfaces";

const Header = ({ props }: any) => {
	const [isSmallScreen, setisSmallScreen] = useState(false);
	const { user, isUserPending } = useUsers();
	const { toggleDropDown, handleClose, usernameBoxRef, anchorEl, navigateToAndCloseDropDown } = useHeader();
	const dispatch = useAppDispatch();
	const { toggleIsRightDrawerOpen: toggleIsDrawerOpen } = drawerActions;
	const { themeMode } = useAppSelector(getThemeReducer);
	const { setStep, step } = useContext(ModalContext);
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext);
	const theme = useTheme();
	const downLg1600 = useMediaQuery(theme.breakpoints.down("lg1600"));

	// useEffect(() => {
	// 	if (step === CurrentStep.STEP3) {
	// 		setOpenModal(false);
	// 	}
	// 	setStep(CurrentStep.STEP1);
	// }, [step]);
	// asd;as

	useEffect(() => {
		const handleResize = () => {
			setisSmallScreen(window.innerWidth <= 600);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			{isSmallScreen ? (
				<HeaderMobile />
			) : (
				<HeaderComponent>
					<Grid2 container columns={12}>
						<Grid2 md={2.8} xs={2.8}>
							<Box>
								<LogoComponent
									src={`${
										themeMode === "light"
											? downLg1600
												? "/images/svgs/logo-light.svg"
												: "/images/svgs/full-logo-light.svg"
											: downLg1600
											? "/images/svgs/logo-dark.svg"
											: "/images/svgs/full-logo-dark.svg"
									}`}
								/>
							</Box>
						</Grid2>
						<Grid2 md={6.5} xs={7.5}>
							<HeaderButtons handleopenModal={() => openModal("guides")} />
						</Grid2>
						<Grid2 md={2.7} xs={1.7}>
							{!isUserPending && (
								<OuterBox>
									<UsernameBox ref={usernameBoxRef} onClick={toggleDropDown}>
										<Username>
											{user.first_name} {user.last_name}
										</Username>
										<CompanyName>{user.company_display}</CompanyName>
									</UsernameBox>
									<ArrowIconWrapper onClick={toggleDropDown}>
										<DownArrowIcon active={!anchorEl} src={`${themeMode === "light" ? "/images/svgs/DownArrow.svg" : "/images/svgs/DownArrow-dark.svg"}`} />
									</ArrowIconWrapper>
								</OuterBox>
							)}
							<HeaderIconWrapper themeMode={themeMode}>
								<HeaderRightIcon
									src={`${themeMode === "light" ? "/images/svgs/rightNavIcon.svg" : "/images/svgs/rightNavIcon-dark.svg"}`}
									onClick={() => dispatch(toggleIsDrawerOpen())}
									themeMode={themeMode}
								/>
							</HeaderIconWrapper>
						</Grid2>
					</Grid2>
					<Popover
						open={!!anchorEl}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						sx={{
							marginTop: "14px",
						}}
					>
						<HeaderList navigateToAndCloseDropDown={navigateToAndCloseDropDown} />
					</Popover>
				</HeaderComponent>
			)}
			<GuidesModal open={isModalOpen && modalKey === "guides"} />
		</>
	);
};

export default Header;
