import { UseMutationResult } from "@tanstack/react-query";
import { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { CheckboxName } from "../components/allModals/feedback/dislike/checkboxLabels";

export type Checkboxes = Record<CheckboxName, boolean>;
export interface IModalWithProps {
	onChangefeedback_text?: ChangeEventHandler;
	checkboxes?: Checkboxes;
	setCheckboxes?: Dispatch<SetStateAction<Checkboxes>>;
	successStateText?: string;
	failureStateText?: string;
	setIsDisabled: Dispatch<SetStateAction<boolean>>;
}

interface IStep {
	Component: React.FC<IModalWithProps>;
	cancelButtonText: string;
	actionButtonText: string | JSX.Element;
	headerText: string;
	onClick: (arg?: any) => void | null;
	showLikeIcon?: {
		light?: string;
		dark?: string;
	};
	crossIcon?: string;
	successStatusText?: string;
	failureStatusText?: string;
	preventClose?: boolean;
	headerIconTooltipText?: string;
	headerRightCornerIcon?: string;
}

export interface IModalConfig {
	[key: string]: IStep;
}

export enum CurrentStep {
	STEP1 = "step1",
	STEP2 = "step2",
	STEP3 = "step3",
	STEP4 = "step4",
}

interface IConfig {
	mutation?: UseMutationResult<any, Error, any, unknown>;
	inputAccept?: string[];
	errorMessage?: string;
	config: IModalConfig;
}

export type ModalKey =
	| "loan"
	| "document"
	| "archived"
	| "delete"
	| "like"
	| "dislike"
	| "powerUp"
	| "billing"
	| "chat"
	| "delete_chat"
	| "archived_chat"
	| "guides"
	| "source"
	| "create_chat"
	| "analyze"
	| "delete_doc";

export type IMainModalConfig = {
	[key in ModalKey]: IConfig;
};
