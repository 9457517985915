import { FC, useContext, useEffect, useState } from "react";
import { useDocuments } from "../../../hook/useDocuments";
import { RightNavbarContext } from "../../../context";
import { useText } from "../../../hook/useText";
import { CustomActionWrapperStatus } from "../ActionWrapperStatus/CustomActionWrapperStatus";
import { useLoans } from "../../../hook/useLoans";
import { useChatText } from "../../../hook/useChatText";
import { useSections } from "../../../hook/useSections";

interface ISaceStatusProps {
	isSuccess: boolean;
	isError: boolean;
}

const SaveStatus: FC<ISaceStatusProps> = ({ isError, isSuccess }) => {
	const { isPathnameIsLoans } = useLoans();
	const { checkIsTooManyDocumentsSelected } = useDocuments();
	const { checkIsTooManySectionsSelected } = useSections();
	const { responseMessage } = useContext(RightNavbarContext);
	const { getActionWrapperText } = useText();
	const { getActionWrapperText: getChatActionWrapperText } = useChatText();
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (responseMessage && (isSuccess || isError)) {
			setMessage(responseMessage);
		} else {
			if (isPathnameIsLoans) {
				setMessage(checkIsTooManyDocumentsSelected() ? responseMessage : getActionWrapperText());
			} else {
				setMessage(checkIsTooManySectionsSelected() ? responseMessage : getChatActionWrapperText());
			}
		}
	}, [responseMessage, isSuccess, isError, checkIsTooManyDocumentsSelected, getActionWrapperText, checkIsTooManySectionsSelected]);

	return (
		<CustomActionWrapperStatus
			src={
				(responseMessage && isSuccess && "/images/svgs/success-dark.svg") ||
				(responseMessage && isError && "/images/svgs/danger-circle-dark.svg") ||
				(checkIsTooManyDocumentsSelected() && "/images/svgs/danger-circle-dark.svg")
			}
			isSuccess={responseMessage ? isSuccess : false}
			isError={responseMessage ? isError : false}
			message={message}
		/>
	);
};

export default SaveStatus;
