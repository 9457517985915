import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "../enum";
import { useContext, useMemo, useState } from "react";
import { documentConstants } from "../constants";
import { RightNavbarContext } from "../context";
import { useChats } from "./useChats";
import { useActiveChatList } from "./useActiveChatList";
import { chatService } from "../services/chat.service";
import { useChatMessages } from "./useChatMessages";
import { ISection, ISectionBulk } from "../interfaces/section.interface";

export const useSections = () => {
	const { getActiveChat } = useActiveChatList();
	const { isPathnameIsChats, refetchChats } = useChats();
	const { refetchMessages } = useChatMessages();
	const [activeCategory, setActiveCategory] = useState<ISection>(null);
	const queryClient = useQueryClient();
	const { setChangedSections, changedSections, setResponseMessage, responseMessage } = useContext(RightNavbarContext);

	const activeChatId = getActiveChat()?.id;

	const {
		data: sections,
		refetch: refetchSections,
		isLoading: isSectionsLoading,
		isPending: isSectionsPending,
		isFetching: isSectionsFetching,
	} = useQuery({
		queryKey: [QueryKey.SECTION, activeChatId],
		queryFn: async () => {
			const MAX_RETRIES = 5;
			let retryCount = 0;

			while (retryCount < MAX_RETRIES) {
				try {
					const sections = await chatService.getSections(activeChatId);
					return sections;
				} catch (error) {
					//@ts-ignore
					if (error.response?.status === 404) {
						retryCount++;
						if (retryCount >= MAX_RETRIES) {
							return [];
						}
					} else {
						throw error;
					}
				}
				await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** retryCount));
			}
		},
		enabled: isPathnameIsChats && activeChatId != null,
		staleTime: Infinity,
		retry: false,
	});

	const sectionsBulkUpdateMutation = useMutation({
		mutationFn: (changedDocs: ISectionBulk[]) => {
			return chatService.bulkUpdate(activeChatId, changedDocs);
		},
		onSuccess: async (data) => {
			setResponseMessage(data.message);
			setChangedSections([]);
			await Promise.all([refetchChats(), refetchMessages()]);
		},
		onError: () => {
			setResponseMessage(documentConstants.bulkUpdateErrorMessage);
		},
	});

	const sortedSections: Record<string, ISection[]> = useMemo(() => {
		if (!sections) {
			return null;
		}
		const groupedSections = sections.reduce((acc: Record<string, ISection[]>, section: ISection) => {
			const groupKey = section.index_num.split(".")[0];
			if (!acc[groupKey]) {
				acc[groupKey] = [];
			}
			acc[groupKey].push(section);
			return acc;
		}, {});

		for (let sections in groupedSections) {
			groupedSections[sections].sort((a: ISection, b: ISection) => Number(a.index_num) - Number(b.index_num));
		}
		return groupedSections;
	}, [sections]);

	const onActiveChange = (id: string, isActive: boolean): void => {
		queryClient.setQueryData([QueryKey.SECTION, activeChatId], (cachedDocs: ISection[]): ISection[] => {
			const updatedDocs = cachedDocs.map((doc) => {
				if (doc.id === id) {
					updateChangedSectionsArray(doc, isActive, id);
					return { ...doc, active: isActive };
				}
				return doc;
			});
			return updatedDocs;
		});
	};

	const updateChangedSectionsArray = (section: ISection, isActive: boolean, id: string): void => {
		let updatedDocumentsArray: ISection[];
		if (responseMessage) {
			setResponseMessage("");
		}
		const changedDoc = { ...section, active: isActive };
		const isDocumentAlreadyInChangedArray = !!changedSections.find((doc) => doc.id === changedDoc.id);
		if (isDocumentAlreadyInChangedArray) {
			updatedDocumentsArray = changedSections.filter((doc) => doc.id !== changedDoc.id);
		} else {
			updatedDocumentsArray = [...changedSections.filter((doc) => doc.id !== id), changedDoc];
		}
		setChangedSections(updatedDocumentsArray);
	};

	const isActiveCategory = (part: ISection) => activeCategory === part;

	const toggleCategoryBox = (part: ISection): void => {
		const activeCategory = !isActiveCategory(part) ? part : null;
		setActiveCategory(activeCategory);
	};

	const getAmountOfSelectedSectionsInGeneral = (): number => {
		if (!sections || !sections?.length) {
			return 0;
		}
		let sum = 0;
		for (let section of sections) {
			if (section.active) {
				sum++;
			}
		}

		return sum;
	};

	const checkIsTooManySectionsSelected = () => {
		return getAmountOfSelectedSectionsInGeneral() > documentConstants.selectedHighWaterMark;
	};

	const getSubpartOfPart = (part: ISection, key: string) => {
		return sortedSections[key].filter((section) => section.parent_section_id === part.id);
	};

	const onSaveClickHandler = () => {
		sectionsBulkUpdateMutation.mutate(changedSections.map((section) => ({ guideline_section_id: section.id, active: section.active })));
	};

	const onDiscardClickHandler = () => {
		changedSections.forEach((doc) => {
			onActiveChange(doc.id, !doc.active);
		});
		setChangedSections([]);
	};

	const getActiveDocsNumberInCategory = (documents: ISection[]): number => {
		return documents.filter((doc) => doc.active).length;
	};

	return {
		sections,
		sortedSections,
		refetchSections,
		isSectionsLoading,
		isSectionsFetching,
		toggleCategoryBox,
		isActiveCategory,
		onActiveChange,
		isSectionsPending,
		getAmountOfSelectedSectionsInGeneral,
		checkIsTooManySectionsSelected,
		updateChangedSectionsArray,
		getSubpartOfPart,
		sectionsBulkUpdateMutation,
		onSaveClickHandler,
		onDiscardClickHandler,
		getActiveDocsNumberInCategory,
	};
};
