import { Box } from "@mui/material";
import { FC } from "react";
import { CustomDivider, IconWrapper, InnerSideBarButton, TypographyBox, TypographyHeading, Typographyid } from "../../../layouts/innerSideBar/innerSideBar.style";
import { useActiveList } from "../../../hook/useActiveList";
import { useLoans } from "../../../hook/useLoans";
import { tooltips } from "../../../constants";
import { CustomToolTip } from "../ToolTip/ToolTip";
import { useTooltip } from "../../../hook/useTooltip";
import { ITooltip } from "../../../interfaces";
import { getThemeReducer, useAppSelector } from "../../../store";

interface IActiveListItemProps {
	item: any;
}

export const ActiveListItem: FC<IActiveListItemProps> = ({ item }) => {
	const { isPathnameIsLoans } = useLoans();
	const { showingField, onHoverHandler, onMouseLeave, isHover, isActiveItem, toggleActiveItemByClick } = useActiveList();
	const { attachHandlers } = useTooltip();
	const { themeMode } = useAppSelector(getThemeReducer);

	const needToShowTooltips = () => isPathnameIsLoans && isHover;

	return (
		<Box onMouseEnter={onHoverHandler} onMouseLeave={onMouseLeave} key={item.id}>
			<InnerSideBarButton onClick={() => toggleActiveItemByClick(item)} is_pathname_is_loans={isPathnameIsLoans}>
				<TypographyBox active={isActiveItem(item)}>
					<TypographyHeading active={isActiveItem(item)}>{item[showingField]}</TypographyHeading>
					{isPathnameIsLoans && <Typographyid>{item.loan_number}</Typographyid>}
				</TypographyBox>
				{needToShowTooltips() && (
					<IconWrapper top="15%" sx={{ themeMode }}>
						{tooltips.map((tooltip: ITooltip) => (
							<CustomToolTip tooltip={tooltip} key={tooltip.title} onClick={attachHandlers(tooltip.title, item)} id={item.id} />
						))}
					</IconWrapper>
				)}
			</InnerSideBarButton>
			<CustomDivider active={isActiveItem(item)} />
		</Box>
	);
};
