import { FC, PropsWithChildren } from "react";
import ModalHeader from "./header";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ModalFooter from "./footer/inder";
import { Breakpoint } from "@mui/material";

interface IModalBoxProps extends PropsWithChildren {
	isDisabled?: boolean;
	height?: string;
	width?: string;
	cancelButtonText?: string;
	actionButtonText?: JSX.Element | string;
	showLikeIcon?: string;
	crossIcon?: string;
	headerText?: string;
	handleClose?: () => void;
	open?: boolean;
	onClick?: () => void;
	headerRightCornerIcon?: string;
	headerIconTooltipText?: string;
	closingComponent?: React.ReactNode;
	sx?: any;
	breakpoint?: number | Breakpoint;
}

const ModalBox: FC<IModalBoxProps> = ({
	isDisabled,
	height = "100vh",
	width,
	cancelButtonText = "",
	actionButtonText = "",
	showLikeIcon = "",
	crossIcon = "",
	headerText = "",
	handleClose,
	open,
	children,
	headerRightCornerIcon,
	headerIconTooltipText,
	closingComponent,
	onClick,
	breakpoint = "md",
	sx,
}) => {
	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={(theme) => ({
					height: `${height}`,
					width: `${width}`,
					display: "flex",
					alignItems: "center",
					margin: "0 auto",
					[theme.breakpoints.down(breakpoint)]: {
						width: `${+width.replace(/px$/, "") * 0.6}px`,
					},
				})}
			>
				<Box
					sx={(theme) => ({
						minWidth: width,
						outline: 0,
						[theme.breakpoints.down(breakpoint)]: {
							minWidth: `${+width.replace(/px$/, "") * 0.6}px`,
						},
					})}
				>
					{headerText && (
						<ModalHeader
							showLikeIcon={showLikeIcon}
							crossIcon={crossIcon}
							headerText={headerText}
							handleClose={handleClose}
							headerRightCornerIcon={headerRightCornerIcon}
							headerIconTooltipText={headerIconTooltipText}
							closingComponent={closingComponent}
						/>
					)}
					{children}
					{(cancelButtonText || actionButtonText) && (
						<ModalFooter isDisabled={isDisabled} cancelButtonText={cancelButtonText} actionButtonText={actionButtonText} handleClose={handleClose} onActiveClick={onClick} />
					)}
				</Box>
			</Modal>
		</>
	);
};

export default ModalBox;
