import { urls } from "../constants";
import { IAnalysesResponse } from "../interfaces";
import { apiService } from "./api.service";

export const analysesService = {
	askAnalyses: async (loanId: string) => {
		const { data } = await apiService.post<IAnalysesResponse>(urls.analyses(loanId), {});
		return data;
	},
};
