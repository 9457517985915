import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import { IGuideline } from "../../../../../interfaces/guideline.interface";
import { ChatContext, ModalContext } from "../../../../../context";
import { getThemeReducer, useAppSelector } from "../../../../../store";
import { ArrowIcon, ArrowIconWrapper } from "../listItem/listItems.style";
import { useGuideline } from "../../../../../hook/useGuideline";

const GuidelineDropdown = () => {
	const { setSelectedOption, openModal } = useContext(ModalContext);
	const { themeMode } = useAppSelector(getThemeReducer);
	const [isActive, setIsActive] = useState(false);
	const { guidelines, isGuidelinesFetching } = useGuideline();
	const [selectedGuideline, setSelectedGuideline] = useState<string>(null);
	const { selectedGuidelineTitle, setSelectedGuidelineTitle } = useContext(ChatContext);

	useEffect(() => {
		setSelectedGuideline(selectedGuidelineTitle);
	}, [selectedGuidelineTitle]);

	const handleChange = (gl: IGuideline) => {
		setSelectedOption(gl.id);
		openModal("create_chat");
	};

	if (isGuidelinesFetching) {
		return (
			<Box sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box sx={(theme) => ({ width: "100%", margin: "8px auto 0px 8px", border: "none", position: "relative" })}>
			<Select
				value={selectedGuideline}
				fullWidth
				displayEmpty
				variant="outlined"
				IconComponent={(props) => (
					<ArrowIconWrapper>
						<ArrowIcon
							src={`/images/svgs/arrow-right-${themeMode}.svg`}
							width="24px"
							height="24px"
							sx={{
								transform: isActive && "rotate(90deg)",
							}}
						/>
					</ArrowIconWrapper>
				)}
				MenuProps={{
					PaperProps: {
						style: {
							width: "1%",
						},
					},
				}}
				onOpen={() => setIsActive(true)}
				onClose={() => setIsActive(false)}
				sx={(theme) => ({
					fontSize: "1rem",
					padding: "2px",
					borderRadius: "16px",
					backgroundColor: themeMode === "light" ? "#F7F6F4" : "#37454B",
				})}
			>
				{guidelines.map((guide, index) => (
					<MenuItem
						key={index}
						value={guide.display_name}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							padding: "8px 12px",
							borderRadius: "8px",
							width: "100%",
							backgroundColor: "inherit !important",
							"&:hover": {
								backgroundColor: themeMode === "light" ? "#F7F6F4 !important" : "#37454B !important",
							},
							"&:active": {
								backgroundColor: themeMode === "light" ? "#F7F6F4 !important" : "#37454B !important",
							},
						}}
						onClick={() => handleChange(guide)}
					>
						<Typography
							variant="body1"
							sx={{
								fontSize: "1.2rem",
								fontWeight: "400 !important",
								whiteSpace: "normal",
								wordBreak: "break-word",
								lineHeight: "24px",
								width: "100%",
							}}
						>
							{guide.display_name}
						</Typography>
						<Typography variant="body2" sx={(theme) => ({ fontSize: "0.85rem", color: themeMode === "light" ? "#03363D" : theme.palette.secondary.main })}>
							{guide.version}
						</Typography>
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default GuidelineDropdown;
