export const light = {
	palette: {
		primary: {
			main: "#FF3621",
			light: "#FE6A57",
			dark: "#03363D",
		},
		text: {
			primary: "#03363D",
			secondary: "#243038",
			disabled: "#FE6A57",
			opposite: "#fff",
			white: "#fff",
		},
		background: {
			default: "#F7F6F4",
		},
		secondary: {
			main: "rgba(3, 54, 61, 0.15)",
			light: "#03363D",
		},
	},
};
