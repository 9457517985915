import { urls } from "../constants";
import { IBulkUpdateResponse, IDocument, IDocumentParams, IUpdatedDocument, IUploadDocumentResponse } from "../interfaces";
import { apiService } from "./api.service";

export const documentService = {
	getByLoanId: async (loanId: string, signal: AbortSignal, params?: IDocumentParams) => {
		const { data } = await apiService.get<IDocument[]>(urls.documents(loanId), {
			params,
			signal,
		});
		return data;
	},
	bulkUpdate: (loanId: string, body: IUpdatedDocument[]) => apiService.put<IBulkUpdateResponse>(urls.bulkUpdate(loanId), body),
	uploadNewDocument: async (loanId: string, document: FormData) => {
		const { data } = await apiService.post<IUploadDocumentResponse>(urls.uploadNewDocument(loanId), document, { headers: { "Content-Type": "multipart/form-data" } });
		return data;
	},
	updateDocument: async (loanId: string, document_id: string, updatedFields: Partial<IDocument>) => {
		const { data } = await apiService.put<IDocument>(urls.updateDocument(loanId, document_id), updatedFields);
		return data;
	},
	docView: async (variables: { loanId: string; document_id: string }) => {
		const { loanId, document_id } = variables;
		return apiService.get<string>(urls.docView(loanId, document_id), { responseType: "blob" });
	},
};
