import { ButtonWrapperComponent, NewDocButton, ReSyncLoanButton, ResyncLoanText, TopButtonIcon } from "./topButton.style";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../../../context";
import { useAnalyses } from "../../../../../hook/useAnalyses";
import { ModalWrapper } from "../../../../../components/allModals/upload/ModalWrapper";
import { Loader } from "../../../../../components/common/Loader/Loader";
import { getThemeReducer, useAppSelector } from "../../../../../store";

const TopButton = () => {
	const { themeMode } = useAppSelector(getThemeReducer);
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext);

	return (
		<>
			<ButtonWrapperComponent>
				<NewDocButton className="button" onClick={() => openModal("document")} sx={{ themeMode }}>
					<ResyncLoanText sx={{ textTransform: "capitalize" }}>New Document</ResyncLoanText>
				</NewDocButton>
				<ReSyncLoanButton className="button" onClick={() => openModal("analyze")}>
					<ResyncLoanText sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", gap: "8px" }}>
						<>
							<TopButtonIcon src={themeMode === "light" ? "/images/svgs/search.svg" : "/images/svgs/search-dark.svg"} />
							Analyze
						</>
					</ResyncLoanText>
				</ReSyncLoanButton>
			</ButtonWrapperComponent>
			<ModalWrapper open={isModalOpen && modalKey === "document"} />
			<ModalWrapper open={isModalOpen && modalKey === "analyze"} />
		</>
	);
};
export default TopButton;
