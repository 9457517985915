import React, { useContext, useEffect, useState } from "react";
import { useModal } from "../../../hook/useModal";
import { getThemeReducer, useAppSelector } from "../../../store";
import ModalBox from "../../common/modal";
import { BodySubTextError, ModalBodyWrapper, ModalText } from "../allModals.style";
import { ChatContext, ModalContext } from "../../../context";
import { Box, CircularProgress, Pagination } from "@mui/material";
import { messageService } from "../../../services";

const SourceModal = ({ open, triggeredCitation, sourceIndex, messageId }: any) => {
	const { errorMessage } = useModal();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { closeModal } = useContext(ModalContext);
	const [isLoading, setIsLoading] = useState(false);
	const [image, setImage] = useState<any>(null);
	// const { lastTriggeredMessageId } = useContext(ChatContext);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const pageCount = triggeredCitation.end_page_index - triggeredCitation.start_page_index + 1;

	useEffect(() => {
		async function getCitationView() {
			setIsLoading(true);
			const data = await messageService.getCitationView(messageId, triggeredCitation.id, currentPage);
			setImage(data);
			setIsLoading(false);
		}
		if (currentPage) {
			getCitationView();
		}
	}, [currentPage, messageId, triggeredCitation]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === "ArrowRight" && currentPage < pageCount) {
				setCurrentPage((prev) => prev + 1);
			} else if (event.key === "ArrowLeft" && currentPage > 1) {
				setCurrentPage((prev) => prev - 1);
			}
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [currentPage, pageCount]);

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	return (
		<ModalBox
			width="800px"
			crossIcon="true"
			headerText={`Source ${sourceIndex} - Page Screenshot`}
			cancelButtonText=""
			actionButtonText=""
			open={open}
			handleClose={closeModal}
			closingComponent={
				pageCount - 1 && (
					<Pagination
						count={pageCount}
						page={currentPage}
						shape="rounded"
						onChange={handlePageChange}
						sx={(theme) => ({
							themeMode,
							"& .MuiPaginationItem-root": {
								borderRadius: "8px",
								backgroundColor: themeMode === "light" ? "#ededed" : "#37454B",
								color: themeMode === "light" ? "#03363D" : "#7D8A8F",
							},
							"& .Mui-selected": {
								backgroundColor: themeMode === "light" ? "#04373d !important" : "#7D8A8F !important",
								color: "#fff",
							},
							[theme.breakpoints.down("md")]: {
								"& .MuiPaginationItem-root": {
									fontSize: "0.7rem",
									padding: "2px 4px",
								},
							},
						})}
					/>
				)
			}
		>
			<ModalBodyWrapper header={true} footer={true} sx={{ themeMode }} height="75vh">
				{isLoading ? (
					<Box sx={{ display: "flex", justifyContent: "center", margin: "10px 0", alignItems: "center", width: "100%", height: "100%" }}>
						<CircularProgress size={48} />
					</Box>
				) : image ? (
					<img src={URL.createObjectURL(image)} alt={`Page ${currentPage}`} style={{ width: "100%", marginTop: "20px", borderRadius: "8px" }} />
				) : (
					<BodySubTextError>No image available for this page.</BodySubTextError>
				)}
			</ModalBodyWrapper>
		</ModalBox>
	);
};

export { SourceModal };
