import { FC } from "react";
import { getThemeReducer, useAppSelector } from "../../../store";
import { ActionFontStatus, ActionFontStatusWrapper, ActionIcon } from "../../../layouts/rightSideBar/actionButton.style";

interface ICustomActionWrapperStatus {
	src?: string;
	message: string;
	isSuccess?: boolean;
	isError?: boolean;
}
const CustomActionWrapperStatus: FC<ICustomActionWrapperStatus> = ({ src, message, isSuccess, isError }) => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<ActionFontStatusWrapper>
			{src && <ActionIcon src={src} />}
			<ActionFontStatus sx={{ themeMode }} isSuccess={isSuccess} isError={isError}>
				{message}
			</ActionFontStatus>
		</ActionFontStatusWrapper>
	);
};

export { CustomActionWrapperStatus };
