import React from "react";
import { styled, alpha } from "@mui/system";
import { Typography, Button, Box } from "@mui/material";
import { IStyle } from "../../interfaces";

interface ISaveButton extends IStyle {
	hasChanges: boolean;
}

interface IActionFontError extends IStyle {
	isSuccess?: boolean;
	isError?: boolean;
}

export const ActionWrapper = styled(Box)(({ theme }) => ({
	background: alpha(theme.palette.background.default, 0.3),
	padding: "30px 15px",
	height: "100%",
	position: "relative",
	maxWidth: "400px",
	boxSizing: "border-box",
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
	},
}));

export const ActionWrapperFont = styled(Typography)(({ theme }) => ({
	fontSize: "14px",
	fontWeight: "400",
	lineHeight: "19.6px",
	fontFamily: "Bai Jamjuree",
}));

export const ActionFontStatusWrapper = styled(Typography)(({ theme }) => ({
	display: "flex",
	gap: "5px",
}));

export const ActionFontStatus = styled(Typography)(({ isSuccess, isError, sx: { themeMode } }: IActionFontError) => ({
	fontSize: "14px",
	fontWeight: "400",
	lineHeight: "19.6px",
	fontFamily: "Bai Jamjuree",
	color: `${isSuccess ? "#248E9C" : isError ? "#FE6A57" : themeMode === "light" ? "#03363D" : "#FFFFFF"}`,
}));

export const ActionIcon = styled("img")(({ theme }) => ({
	height: "18px",
	width: "18px",
	marginTop: "2px",
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	gap: "10px",
	marginTop: "15px",
	boxSizing: "border-box",
	position: "absolute",
	top: 55,
}));

export const SaveIcon = styled("img")(({ theme }) => ({
	height: "18px",
	width: "18px",
}));

export const SaveButton = styled(Button)(({ theme, sx: { themeMode }, hasChanges }: ISaveButton) => ({
	marginRight: "10px",
	fontSize: "20px",
	fontWeight: "500",
	lineHeight: "28px",
	fontFamily: "'Exo 2'",
	height: "56px",
	width: "200px",
	borderRadius: "10px",
	color: `${hasChanges ? "#F7F6F4" : theme.palette.text.primary}`,
	backgroundColor: `${hasChanges ? "#FE6A57" : themeMode === "light" ? "#F7F6F4" : "#465359"}`,
	textTransform: "capitalize",
	padding: "12px 21px",
	"&:hover": {
		backgroundColor: `${hasChanges ? "#FE6A57" : themeMode === "light" ? "#F7F6F4" : "#465359"}`,
		boxShadow: "none",
	},
	"&:disabled": {
		backgroundColor: `${themeMode === "light" ? "#F7F6F4" : "#465359"}`,
		color: theme.palette.text.primary,
		boxShadow: "none",
	},
	[theme.breakpoints.down("lg1600")]: {
		width: "195px",
	},
	[theme.breakpoints.down("lg1400")]: {
		width: "200px",
	},
	[theme.breakpoints.down("sm")]: {
		fontSize: "14px",
		fontWeight: "500",
		lineHeight: "22.4px",
		fontFamily: "'Exo 2'",
		height: "50px",
		width: "171px",
	},
}));

export const SaveButtonActive = styled(Button)(({ theme }: IStyle) => ({
	fontSize: "20px",
	fontWeight: "500",
	lineHeight: "28px",
	fontFamily: "'Exo 2'",
	height: "56px",
	width: "206px",
	borderRadius: "10px",
	color: theme.palette.text.primary,
	backgroundColor: theme.palette.background.light,
	textTransform: "capitalize",
	padding: "12px 21px",
	"&:hover": {
		backgroundColor: theme.palette.background.light,
		boxShadow: "none",
	},
	[theme.breakpoints.down("sm")]: {
		fontSize: "14px",
		fontWeight: "500",
		lineHeight: "22.4px",
		fontFamily: "'Exo 2'",
		height: "50px",
		width: "171px",
	},
}));

export const DiscardIcon = styled("img")(({ theme }) => ({
	height: "18px",
	width: "18px",
}));

export const DiscardButton = styled(Button)(({ theme, sx: { themeMode } }: IStyle) => ({
	fontSize: "20px",
	fontWeight: "500",
	lineHeight: "28px",
	fontFamily: "'Exo 2'",
	height: "56px",
	width: "160px",
	borderRadius: "10px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
	backgroundColor: `${themeMode === "light" ? "#F7F6F4" : "#465359"}`,
	padding: "12px 21px",
	"&:hover": {
		backgroundColor: `${themeMode === "light" ? "#F7F6F4" : "#465359"}`,
		boxShadow: "none",
	},
	[theme.breakpoints.down("lg1600")]: {
		width: "115px",
	},
	[theme.breakpoints.down("lg1400")]: {
		width: "160px",
	},
	[theme.breakpoints.down("sm")]: {
		fontSize: "14px",
		fontWeight: "500",
		lineHeight: "22.4px",
		fontFamily: "'Exo 2'",
		height: "50px",
		width: "171px",
	},
}));
