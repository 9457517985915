import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { guidelineTooltips } from "../../../../constants/guidelineTooltip";
import { IGuideline } from "../../../../interfaces/guideline.interface";
import { useDocuments } from "../../../../hook/useDocuments";
import { useTooltip } from "../../../../hook/useTooltip";
import { getThemeReducer, useAppSelector } from "../../../../store";
import { Heading, SubHeading } from "../../../../layouts/rightSideBar/rightNavBar.style";

interface IListItemProps {
	guideline: IGuideline;
}

const ListItem: FC<IListItemProps> = ({ guideline }) => {
	const { onActiveChange } = useDocuments();
	const { parseGuidelineTooltips } = useTooltip();
	const { themeMode } = useAppSelector(getThemeReducer);
	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "flex-start",
				padding: "12px 12px",
				flexWrap: "wrap",
				gap: "16px",
				border: `1px solid ${theme.palette.secondary.main}`,
				borderRadius: "10px",
			})}
		>
			<Box>
				<Box
					sx={{
						flex: "1 1 100%",
						marginBottom: "3px",
					}}
				>
					<Heading>
						<Typography sx={(theme) => ({ marginRight: "5px", fontSize: "18px", [theme.breakpoints.down("md")]: { fontSize: "15px", lineHeight: "22px" } })}>
							{guideline.display_name}
						</Typography>
						{/* <span style={{ marginRight: "5px", fontSize: "18px" }}>{guideline.display_name}</span> */}
					</Heading>
				</Box>
				<Box
					sx={{
						flex: "1 1 100%",
					}}
				>
					<SubHeading>{guideline.version}</SubHeading>
				</Box>
			</Box>
			<Box
				sx={{
					flex: "1 1 100%",
					display: "flex",
					justifyContent: "flex-start",
					gap: "12px",
				}}
			>
				{parseGuidelineTooltips(guidelineTooltips, guideline)}
			</Box>
		</Box>
	);
};

export { ListItem };
