import { FC, Fragment, useContext, useEffect, useState } from "react";
import { AnswerInnerWrapper, AnswerWrapper, AvatarIcon, AvatarMain, AvatarWrapper, QuestionBody, QuestionInnerWrapper, QuestionWrapper } from "../../../../pages/styles/home.style";
import { IChatTimeline, ICitation } from "../../../../interfaces";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {} from "../../modal/header/modal.header.style";
import { useAuth0 } from "@auth0/auth0-react";
import { UserMessageAvatar } from "./message.style";
import { TypewriterComponent } from "../../Typewriter/TypewriterComponent";
import { chatConstants } from "../../../../constants";
import "./message.css";
import { StyledMarkdown } from "../../../../layouts/innerSideBar/innerSideBar.style";
import { FeedbackIconWrapper } from "../../FeedbackIconWrapper/FeedbackIconWrapper";
import { useModal } from "../../../../hook/useModal";
import { useMessages } from "../../../../hook/useMessages";
import { useWrapper } from "../../../../hook/useWrapper";
import { getThemeReducer, useAppSelector } from "../../../../store";
import { useTypewriter } from "../../../../hook/useTypewriter";
import { ChatContext, ModalContext } from "../../../../context";
import { useChatMessages } from "../../../../hook/useChatMessages";
import { Box, Typography } from "@mui/material";
import { SourceBtnWrapper } from "./SourceButton/SourceButton";
import { SourceModal } from "../../../allModals/source/SourceModal";

interface IMessageProps {
	message: IChatTimeline;
	shouldUseTypingEffect: boolean;
}

const ChatMessage: FC<IMessageProps> = ({ message, shouldUseTypingEffect }) => {
	const { user } = useAuth0();
	const { openModalFeedback } = useModal();
	const { handleIsTypingEffectInProgress, sortedMessages } = useChatMessages();
	const { scrollDown } = useWrapper();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { refetchEntities, updateQueryCache, parseMarkdownHandler } = useTypewriter();
	const { isTypingEffectInProgress } = useContext(ChatContext);
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext);
	const { setLastTriggeredMessageId, setLastTriggeredCitation } = useContext(ChatContext);

	function onClickSource(citation: ICitation, index: number) {
		setLastTriggeredCitation({ citation, index });
		openModal("source");
	}

	return (
		<>
			<div className="singleMessageWrapper" onClick={() => setLastTriggeredMessageId(message.id)}>
				<AvatarWrapper>
					<AvatarMain>
						{message.user === "system" ? (
							<AvatarIcon sx={{ background: "transparent" }} src="/images/svgs/profile-1.svg" />
						) : (
							<UserMessageAvatar themeMode={themeMode}>{user?.email.substring(0, 1).toUpperCase()}</UserMessageAvatar>
						)}
					</AvatarMain>
				</AvatarWrapper>
				<QuestionWrapper>
					<QuestionInnerWrapper sx={{ themeMode }} onResize={() => scrollDown("instant" as ScrollBehavior)}>
						<QuestionBody>
							{shouldUseTypingEffect ? (
								<TypewriterComponent
									textToType={message.message_text}
									interval={chatConstants.typingEffectInterval}
									handleIsTypingEffectInProgress={handleIsTypingEffectInProgress}
									refetchEntities={refetchEntities}
									updateQueryCache={updateQueryCache}
									parseMarkdownHandler={parseMarkdownHandler}
								/>
							) : (
								<>
									<StyledMarkdown>{message.message_text}</StyledMarkdown>
								</>
							)}
						</QuestionBody>
						{message.citations.length ? (
							<Fragment>
								<Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
									<Typography
										sx={(theme) => ({
											margin: "10px 0",
											fontSize: "16px",
											"@media (max-width:700px)": {
												fontSize: "12px",
											},
										})}
									>
										Sources
									</Typography>
									<Box sx={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center", justifyContent: "center" }}>
										{message.citations.map((citation, index) => {
											return (
												<>
													<SourceBtnWrapper columnNumber={index + 1} key={citation.id} onClick={() => onClickSource(citation, index + 1)}>
														{(index + 1).toString()}
													</SourceBtnWrapper>
												</>
											);
										})}
									</Box>
								</Box>
								<Box sx={{ height: "12px" }}></Box>
								{/*Upper box is for bottom margin*/}
							</Fragment>
						) : (
							""
						)}
					</QuestionInnerWrapper>
				</QuestionWrapper>
				{message.user === "system" && (
					<AnswerWrapper>
						<AnswerInnerWrapper>
							{isTypingEffectInProgress && message.id === sortedMessages[0].id ? (
								<></>
							) : (
								<Grid2 container columns={12}>
									<FeedbackIconWrapper
										columnNumber={6}
										src={`${message.thumbs_up ? "/images/svgs/like-highlighted.svg" : themeMode === "light" ? "/images/svgs/like.svg" : "/images/svgs/like-dark.svg"}`}
										onClickHandler={() => openModalFeedback("like", message.id)}
									/>
									<FeedbackIconWrapper
										columnNumber={6}
										src={`${message.thumbs_down ? "/images/svgs/dislike-highlighted.svg" : themeMode === "light" ? "/images/svgs/dislike.svg" : "/images/svgs/dislike-dark.svg"}`}
										onClickHandler={() => openModalFeedback("dislike", message.id)}
									/>
								</Grid2>
							)}
						</AnswerInnerWrapper>
					</AnswerWrapper>
				)}
			</div>
		</>
	);
};

export { ChatMessage };
