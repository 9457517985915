import { memo, useContext, useEffect } from "react";
import { CustomList, InnerSideBarWrapper } from "../../../../layouts/innerSideBar/innerSideBar.style";
import { useActiveList } from "../../../../hook/useActiveList";
import { useLoans } from "../../../../hook/useLoans";
import { InnerSideBarItem } from "../InnerSideBarItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../Loader/Loader";
import { useParams } from "react-router-dom";
import { getThemeReducer, useAppSelector } from "../../../../store";
import { ActiveListContext } from "../../../../context";
import { useChats } from "../../../../hook/useChats";
import { useActiveChatList } from "../../../../hook/useActiveChatList";
import { ToolTip } from "../../../../enum";

const InnerSideChatBar = memo(() => {
	const { listItems } = useActiveChatList();
	const { themeMode } = useAppSelector(getThemeReducer);
	const { chatId, agencyId } = useParams();
	const { isChatsLoading, isPathnameIsChats, hasChatsNextPage, fetchChatsNextPage, sortedChats } = useChats({ agency_id: agencyId });
	const { updateActiveList } = useActiveList();
	const { setActiveList, setActiveEntity } = useContext(ActiveListContext);

	useEffect(() => {
		if (!isChatsLoading && isPathnameIsChats) {
			const activeChatByPathname = sortedChats.find((chat) => chat.id === chatId);
			if (activeChatByPathname) {
				setActiveList(chatId);
			} else {
				updateActiveList();
			}
		}
	}, [isChatsLoading, chatId, sortedChats, isPathnameIsChats, setActiveList, updateActiveList]);

	useEffect(() => {
		const element = document.getElementById("innerSideBarWrapperMobile");
		if (element && element.scrollHeight <= element.clientHeight && hasChatsNextPage) {
			fetchChatsNextPage();
		}
	}, [listItems, hasChatsNextPage, fetchChatsNextPage]);

	const mappedContent = listItems.map((item: any) => <InnerSideBarItem item={item} isActive={String(item.id) === chatId} />);

	return (
		<InnerSideBarWrapper sx={{ themeMode }} id="innerSideBarWrapperMobile" is_pathname_is_loans={isPathnameIsChats}>
			<CustomList>
				{isChatsLoading ? (
					<Loader />
				) : !isPathnameIsChats ? (
					<>{mappedContent}</>
				) : (
					<InfiniteScroll dataLength={listItems.length} hasMore={hasChatsNextPage} next={fetchChatsNextPage} loader={<Loader />} scrollableTarget="innerSideBarWrapperMobile">
						{mappedContent}
					</InfiniteScroll>
				)}
			</CustomList>
		</InnerSideBarWrapper>
	);
});

export { InnerSideChatBar };
