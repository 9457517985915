import { Box, List } from "@mui/material";
import { MainBox } from "./listItems.style";
import { Category } from "../Category/Category";
import { Loader } from "../../../../../components/common/Loader/Loader";
import { getThemeReducer, useAppSelector } from "../../../../../store";
import { useSections } from "../../../../../hook/useSections";

const ListItems = () => {
	const { isActiveCategory, toggleCategoryBox, sortedSections, isSectionsFetching } = useSections();
	const { themeMode } = useAppSelector(getThemeReducer);

	console.log("---sortedSections", sortedSections);

	return (
		<MainBox>
			<List sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
				{isSectionsFetching ? (
					<Loader marginTop="42px" />
				) : sortedSections && Object.values(sortedSections)?.length > 0 ? (
					<div style={{ marginTop: "18px" }}>
						{Object.values(sortedSections).map((part, i) => {
							return <Category key={i} part={part} isActiveCategory={isActiveCategory(part[0])} toggleCategoryBox={toggleCategoryBox} />;
						})}
					</div>
				) : (
					<Box sx={{ color: themeMode === "dark" ? "#ffffffb0" : "#03363da2", fontSize: "16px", margin: "auto", marginTop: "42px" }}>No Sections Found</Box>
				)}
			</List>
		</MainBox>
	);
};

export default ListItems;
