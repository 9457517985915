import MicroPhone from "../../../assets/microphone";
import { useRecording } from "../../../hook/useRecording";
import { IconButtonWrapper, StopAudio } from "../../../pages/styles/home.style";
import VoiceWave from "./Waves/VoiceWaves";

const AudioRecorder = () => {
	const { start, stopAndSendAudio, isRecording } = useRecording();

	return (
		<IconButtonWrapper onClick={!isRecording ? start : stopAndSendAudio} aria-label="microphone">
			{!isRecording ? <MicroPhone /> : <VoiceWave />}
		</IconButtonWrapper>
	);
};

export { AudioRecorder };
