import { useMutation } from "@tanstack/react-query";
import { analysesService } from "../services";
import { useParams } from "react-router-dom";
import { useMessages } from "./useMessages";
import { CurrentStep } from "../interfaces";
import { useContext } from "react";
import { ModalContext } from "../context";

export const useAnalyses = () => {
	const { loanId } = useParams();
	const { refetchMessages } = useMessages();
	const { setStep, setMessage } = useContext(ModalContext);

	const askAnalysesMutation = useMutation({
		mutationFn: () => analysesService.askAnalyses(loanId),
		onSuccess: (data) => {
			setStep(CurrentStep.STEP3);
			refetchMessages();
		},
		onError: (e: any) => {
			setMessage(e.response.data.detail);
			setStep(CurrentStep.STEP4);
			console.error(e);
		},
	});

	return { askAnalysesMutation };
};
