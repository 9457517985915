import { useNavigate } from "react-router-dom";
import { useLoans } from "./useLoans";
import { settingsDummyData } from "../dummyData";
import { ILoan, ISetting } from "../interfaces";
import { useContext, useState } from "react";
import { ActiveListContext } from "../context";
import { useLogout } from "./useLogout";
import { useBilling } from "./useBilling";

export const useActiveList = () => {
	const [isHover, setIsHover] = useState<boolean>(null);
	const { activeList, setActiveList } = useContext(ActiveListContext);
	const { sortedLoans, isLoansLoading, isPathnameIsLoans } = useLoans();
	const navigate = useNavigate();
	const { handleClickBillingNavItem } = useBilling();
	const { logoutHandler } = useLogout();

	const listItems: ILoan[] | ISetting[] = isPathnameIsLoans ? sortedLoans : settingsDummyData.settings;

	const showingField = isPathnameIsLoans ? "file_name" : "title";
	const activeListField = isPathnameIsLoans ? "id" : "title";

	const updateActiveList = (): void => {
		if (!isPathnameIsLoans) {
			const settingsList = listItems as ISetting[];
			setActiveList(settingsList[0]?.title);
			return;
		}

		if (!isLoansLoading) {
			if (!sortedLoans.length) {
				setActiveList("");
				return;
			}

			const loansList = (listItems[0] as ILoan).id.toString();
			console.log("---loansList", loansList);
			setActiveList(loansList);
		}
	};

	const getActiveLoan = (): ILoan => {
		return isPathnameIsLoans && sortedLoans ? sortedLoans.find((loan) => String(loan.id) === activeList) : null;
	};

	const onHoverHandler = () => {
		setIsHover(true);
	};
	const onMouseLeave = () => {
		setIsHover(false);
	};

	const isActiveItem = (item: any) => activeList === String(item[activeListField]);

	const toggleActiveItemByClick = (item: any) => {
		if (item[activeListField] === "Logout") {
			logoutHandler();
			return;
		}

		setActiveList(String(item[activeListField]));
		if (item.title === "Billing") {
			handleClickBillingNavItem();
			return;
		}
		if (item.url) {
			navigate(item.url);
		}
	};

	return {
		listItems,
		showingField,
		updateActiveList,
		getActiveLoan,
		activeListField,
		onHoverHandler,
		onMouseLeave,
		isHover,
		isActiveItem,
		toggleActiveItemByClick,
		activeList,
		setActiveList,
	};
};
